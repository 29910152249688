import React, {useState, useEffect} from 'react';
import {requestPasswordResetEmail} from '../../api/profileApi';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import './Passwords.scss';
import Loading from '../../components/Loaders/Loading';
import {Helmet} from 'react-helmet';

export default function ResetPassword() {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [timeoutId, setTimeoutId] = useState(null);

	useEffect(() => {
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [timeoutId]);

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setMessage('');
		setError('');

		if (timeoutId) {
			clearTimeout(timeoutId);
			setTimeoutId(null);
		}

		const res = await requestPasswordResetEmail(email);
		console.log(res);
		setLoading(false);

		if (res?.success) {
			setMessage('Email sent successfully, please check your email for a link to reset your password or wait 30 seconds to try again.');
			setDisabled(true);
			const newTimeoutId = setTimeout(() => {
				setDisabled(false);
				setTimeoutId(null);
			}, 30000);
			setTimeoutId(newTimeoutId);
		} else if (res?.error) {
			setError(res.error);
		}
	};

	return (
		<div>
			<Helmet>
				<title>Reset Password | Permworks</title>
				<meta name="description" content="Reset your Permworks account password. If you've forgotten your password or having trouble logging in, you can reset it here." />
			</Helmet>
			<Navbar />
			<div className="emailRequest">
				<h1>Request Password Reset</h1>
				<h4>Enter your email to continue</h4>
				<form onSubmit={onSubmit}>
					<div>
						<input type="email" placeholder="Enter your email" name="email" onChange={(e) => setEmail(e.target.value)} required />
					</div>
					{message && <p className="success">{message}</p>}
					{error && <p className="error">{error}</p>}
					<button type="submit" disabled={disabled}>
						Send email
					</button>
				</form>
			</div>
			<Footer />
			{loading && <Loading />}
		</div>
	);
}
