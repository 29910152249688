import * as React from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DoneIcon from '@mui/icons-material/Done';
const StyledTableCell = styled(TableCell)(({theme}) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.custom.lightText,
		border: '2px solid',
		borderColor: theme.palette.primary.main,
	},
	[`&.${tableCellClasses.body}`]: {
		// fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#d8eefe',
		color: '#003366',
		border: '2px solid #d8eefe',
	},
	'&:nth-of-type(even)': {
		color: '#003366',
		border: '2px solid #d8eefe',
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: '2px solid #d8eefe',
	},
}));

function createData(name, permworks, traditional) {
	return {name, permworks, traditional};
}

const rows = [
	createData('No Salary Markup', true, false),
	createData('Fast Recruitment process', true, true),
	createData('Direct Hire Facilitation', true, false),
	createData('Compliance Facilitation', true, false),
	createData('Cost Transparency', true, true),
	createData('Thorough Testing', true, true),
	createData('Thorough Background Checks', true, true),
];

export default function ComparisonTable() {
	return (
		<TableContainer style={{borderRadius: '10px'}}>
			<Table sx={{minWidth: 300, maxWidth: '100%'}} aria-label="customized table">
				<TableHead>
					<TableRow>
						<StyledTableCell style={{fontWeight: '600', fontSize: '1em'}}>How does Permworks stack up?</StyledTableCell>
						<StyledTableCell style={{fontWeight: '600', fontSize: '1em'}} align="center">
							Permworks
						</StyledTableCell>
						<StyledTableCell style={{fontWeight: '600', fontSize: '1em'}} align="center">
							Traditional BPO's
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.name}>
							<StyledTableCell style={{fontWeight: '500', color: '#094067'}} component="th" scope="row">
								{row.name}
							</StyledTableCell>
							<StyledTableCell align="center">
								<DoneIcon className={row.permworks ? 'tick-true' : 'tick'} />
							</StyledTableCell>
							<StyledTableCell align="center">
								{' '}
								<DoneIcon className={row.traditional ? 'tick-true' : 'tick'} />
							</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
