import React, {useContext, useEffect, useState} from 'react';
import './inbox.scss';
import {getConversationsApi, getMessagesApi, sendMessageApi} from '../../api/messagingApi';
import LoadingPartial from '../Loaders/LoadingPartial';
import {UserContext} from '../../utils/UserContext';
import {formatDate2} from '../../utils/DateFunctions';
import {AiOutlineSend} from 'react-icons/ai';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Card from 'react-bootstrap/Card';
import {ArrowBack} from '@material-ui/icons';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hidden from '@mui/material/Hidden';
import Placeholder from 'react-bootstrap/Placeholder';
import {onMessage} from 'firebase/messaging';
import {messaging} from '../../firebase';
import {permColors} from '../../utils/colors';
import {Input, Spin} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import ChatBox from './ChatBox';
import {useAtom} from 'jotai';
import {conversationsAtom} from '../../utils/atoms';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function Inbox() {
	const [originalConversations, setOriginalConversations] = useAtom(conversationsAtom);
	const [displayedConversations, setDisplayedConversations] = useState([]);
	const [messagesContent, setMessagesContent] = useState([]);
	const [loading, setLoading] = useState(true);
	const [currentUser] = useContext(UserContext);
	const [selectedConversation, setSelectedConversation] = useState(null);
	const [message, setMessage] = useState('');
	const [selectedUser, setSelectedUser] = useState(null);
	const [open, setOpen] = React.useState(false);
	const [sendMessageLoading, setSendMessageLoading] = useState(false);
	const [fetchingConversations, setFetchingConversations] = useState(false);
	const [fetchingMessages, setFetchingMessages] = useState(false);

	useEffect(() => {
		onMessage(messaging, (payload) => {
			getMessagesApi(selectedConversation).then((res2) => {
				setMessagesContent(res2);
				setMessage('');
			});
		});
	}, [selectedConversation]);

	const handleClose = () => {
		setOpen(false);
	};

	const fetchConversations = async () => {
		if (fetchingConversations) return;
		setFetchingConversations(true);
		const res = await getConversationsApi();
		if (res === 'error') window.location.reload();
		setOriginalConversations(res);
		setDisplayedConversations(res);
		setFetchingConversations(false);
		setLoading(false);
	};

	const sendMessage = async () => {
		if (sendMessageLoading || !message) return;
		setSendMessageLoading(true);
		await sendMessageApi(selectedConversation, message);
		const res2 = await getMessagesApi(selectedConversation);
		setMessagesContent(res2);
		setMessage('');
		setSendMessageLoading(false);
	};

	const fetchMessages = async (id, user) => {
		if (id === selectedConversation) return;
		if (fetchingMessages) return;
		setFetchingMessages(true);
		setMessagesContent([]);
		setSelectedConversation(null);
		setOpen(true);
		const res = await getMessagesApi(id);
		setOpen(true);
		setSelectedUser(user);
		setMessagesContent(res);
		setSelectedConversation(id);
		setFetchingMessages(false);
	};

	const filter = (conversations, filterText) => {
		if (!filterText) return conversations;
		const lowerCaseFilterText = filterText.toLowerCase().trim();
		return conversations.filter((conversation) => {
			return conversation.users.some((user) => {
				if (user.email === currentUser.email) return false;
				const {first_name, last_name} = user.profile || {};
				const fullName = (first_name ? first_name.toLowerCase() : '') + ' ' + (last_name ? last_name.toLowerCase() : '');
				return fullName.includes(lowerCaseFilterText);
			});
		});
	};

	const runFilter = (e) => {
		const txt = e.target.value || '';
		const filteredConversations = filter(originalConversations, txt);
		setDisplayedConversations(filteredConversations);
	};

	useEffect(() => {
		fetchConversations();
	}, []);

	if (loading) {
		return <LoadingPartial />;
	}

	return (
		<div className="dashboardComponent">
			<div className="outerContainer">
				<div className="messagingContainer">
					<Hidden mdUp>
						<DialogPopup
							open={open}
							handleClose={handleClose}
							selectedUser={selectedUser}
							messagesContent={messagesContent}
							currentUser={currentUser}
							setMessage={setMessage}
							message={message}
							sendMessage={sendMessage}
							sendMessageLoading={sendMessageLoading}
						/>
					</Hidden>
					<Row>
						<Col className="leftSide">
							<Card className="cardDesign max-h-full overflow-y-auto">
								<Card.Header className="cardHeaderDesign pl-0 mb-3" style={{borderBottom: '1px solid #f5f5f5', color: permColors.darkCharcoal}}>
									<span className="text-base">Messages</span>
								</Card.Header>

								<Input type="text" placeholder="Filter Names" onChange={runFilter} className="mb-4" />
								<p className="mb-2 text-sm pl-1" style={{color: permColors.charcoal}}>
									{displayedConversations.length} Conversation{displayedConversations?.length !== 1 && 's'}
								</p>
								<div className="p-1 users-list">
									{displayedConversations.length > 0 ? (
										displayedConversations.map((conversation, index) => (
											<div key={conversation.id}>
												{conversation.users.map((user, index2) => {
													if (user?.email !== currentUser.email) {
														return (
															<div
																key={index2}
																className={'user-item mb-2' + (selectedConversation === conversation.id ? ' selected' : '')}
																onClick={() => {
																	fetchMessages(conversation.id, user);
																}}>
																<div style={{display: 'flex', alignItems: 'flex-start'}}>
																	<ListItemAvatar>
																		<AvatarWithLoading
																			alt={`${user?.profile?.first_name ?? 'Unknown'} ${user?.profile?.last_name ?? 'Applicant'}`}
																			src={user?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
																		/>
																	</ListItemAvatar>
																	<div>
																		<div className="heading-name text-sm font-semibold">{`${user?.profile?.first_name ?? 'Unknown'} ${
																			user?.profile?.last_name ?? 'Applicant'
																		}`}</div>
																		<div className="text-sm">
																			<span className="text-xs">{user?.email ?? 'No email provided'}</span>
																			<br />
																			<span className="text-xs">{'Created on ' + formatDate2(conversation.created_at) ?? 'No phone number provided'}</span>
																		</div>
																	</div>
																</div>
															</div>
														);
													}
													return null;
												})}
											</div>
										))
									) : (
										<p className="text-sm p-1">No conversations yet.</p>
									)}
								</div>
							</Card>
						</Col>
						<Hidden mdDown>
							<Col className="rightSide">
								<Card className="cardDesign flex h-full">
									<Card.Header className="cardHeaderDesign pl-0 mb-0" style={{borderBottom: '1px solid #f5f5f5', color: permColors.darkCharcoal}}>
										<div className="flex items-center">
											<ListItemAvatar>
												<AvatarWithLoading
													alt={`${selectedUser?.profile?.first_name ?? ''} ${selectedUser?.profile?.last_name ?? 'Applicant'}`}
													src={selectedUser?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
												/>
											</ListItemAvatar>
											<div className="flex row">
												<span className="text-sm">
													{selectedConversation && selectedUser
														? `${selectedUser?.profile?.first_name ?? 'Unknown'} ${selectedUser?.profile?.last_name ?? 'User'}`
														: 'Select a conversation'}
												</span>
												<span className="text-sm font-normal">{selectedConversation && selectedUser ? selectedUser?.email : ''}</span>
											</div>
										</div>
									</Card.Header>
									{selectedConversation && selectedUser ? (
										<>
											<Card.Body style={{overflowY: 'auto'}} className="rightColumnn flex-1 grow">
												<ChatBox messagesContent={messagesContent} currentUser={currentUser} />
											</Card.Body>
											<Card.Footer
												className="d-flex align-items-center justify-content-between cardHeaderDesign m-0 py-3 px-0"
												style={{borderTop: `1px solid ${permColors.backgroundGrey}`}}>
												<div className="textarea-container">
													<TextArea
														value={message}
														onChange={(e) => setMessage(e.target.value)}
														rows={4}
														className="message-textarea"
														autoSize
														disabled={sendMessageLoading}
													/>
													{!sendMessageLoading ? (
														<AiOutlineSend className="send-icon font-icon" onClick={sendMessage} />
													) : (
														<Spin size="small" className="send-icon bottom-2 cursor-wait mb-1 mr-1" />
													)}
												</div>
											</Card.Footer>
										</>
									) : (
										<>
											{!fetchingMessages ? (
												<Card.Body style={{maxHeight: '600px', overflowY: 'auto'}}>
													<Card.Text className="text-sm">
														Select a conversation to view messages.
														<br />
														<Placeholder xs={6} />
														<Placeholder className="w-75" /> <Placeholder style={{width: '25%'}} />
													</Card.Text>
												</Card.Body>
											) : (
												<Spin size="large" className="my-auto" />
											)}
										</>
									)}
								</Card>
							</Col>
						</Hidden>
					</Row>
				</div>
			</div>
		</div>
	);
}

export function AvatarWithLoading({src, ...otherProps}) {
	const [loadedSrc, setLoadedSrc] = useState('/static/images/avatar/default.jpg');

	useEffect(() => {
		const image = new Image();
		image.src = src;
		image.onload = () => {
			setLoadedSrc(src);
		};
	}, [src]);

	return <Avatar src={loadedSrc} {...otherProps} sx={{border: '1px solid ' + permColors.backgroundGrey}} />;
}

const DialogPopup = ({handleClose, open, selectedUser, messagesContent, currentUser, setMessage, message, sendMessage, sendMessageLoading}) => {
	return (
		<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar sx={{position: 'sticky', backgroundColor: permColors.white}} className="shadow-sm">
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="back">
						<ArrowBack style={{color: permColors.darkCharcoal}} />
					</IconButton>
					<div className="flex items-center pl-3">
						<ListItemAvatar>
							<AvatarWithLoading
								alt={`${selectedUser?.profile?.first_name ?? ''} ${selectedUser?.profile?.last_name ?? 'Applicant'}`}
								src={selectedUser?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
							/>
						</ListItemAvatar>
						<div className="flex row text-left" style={{color: permColors.charcoal}}>
							<span className="text-sm pl-2">
								{selectedUser ? `${selectedUser?.profile?.first_name ?? 'Unknown'} ${selectedUser?.profile?.last_name ?? 'User'}` : 'Select a conversation'}
							</span>
							<span className="text-sm font-normal pl-2">{selectedUser ? selectedUser?.email : ''}</span>
						</div>
					</div>
				</Toolbar>
			</AppBar>
			<Card.Body style={{backgroundColor: permColors.white}}>
				<ChatBox messagesContent={messagesContent} currentUser={currentUser} />
			</Card.Body>
			<Card.Footer className="d-flex align-items-center justify-content-between m-0 p-2" style={{position: 'sticky', bottom: '0', backgroundColor: '#fffffe'}}>
				<div className="textarea-container">
					<TextArea value={message} onChange={(e) => setMessage(e.target.value)} rows={4} className="message-textarea" autoSize disabled={sendMessageLoading} />
					{!sendMessageLoading ? <AiOutlineSend className="send-icon font-icon" onClick={sendMessage} /> : <Spin size="small" className="send-icon bottom-2 cursor-wait mb-1 mr-1" />}
				</div>
			</Card.Footer>
		</Dialog>
	);
};
