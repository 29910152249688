import React, {useContext, useRef, useEffect, useState} from 'react';
import Footer from '../../components/Footer/Footer';
import './EndToEnd.scss';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {OverPack} from 'rc-scroll-anim';
import QueueAnim from 'rc-queue-anim';
import 'rc-banner-anim/assets/index.css';
import {useMediaQuery} from '@mui/material';
import ppp from '../../assets/images/endToEndImages/ppp.jpeg';
import nomi from '../../assets/images/endToEndImages/nomi.jpeg';
import coinbits from '../../assets/images/endToEndImages/coinbits.png';
import oddit from '../../assets/images/endToEndImages/oddit.png';
import meat from '../../assets/images/endToEndImages/meat.png';
import acad from '../../assets/images/endToEndImages/acad.png';
import fohm from '../../assets/images/endToEndImages/fohm.png';
import humanity from '../../assets/images/endToEndImages/humanity.png';
import charbs from '../../assets/images/endToEndImages/charbs.png';
import recr from '../../assets/images/endToEndImages/recr.png';
import person1 from '../../assets/images/endToEndImages/person1.png';
import person2 from '../../assets/images/endToEndImages/person2.png';
import person3 from '../../assets/images/endToEndImages/person3.png';
import person4 from '../../assets/images/endToEndImages/person4.png';
import NavbarDark from '../../components/Navbars/NavbarDark';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddCardIcon from '@mui/icons-material/AddCard';
import HubSpotChat from '../../utils/HubSpotChat';
import ComparisonTable from './ComparisonTable';
import {AiFillStar} from 'react-icons/ai';
import EnquiryForm from '../../components/EnquiryForm/EnquiryForm';
import {Tooltip} from 'antd';
import {Accordion} from 'react-bootstrap';
import {endToEndFaqData} from '../../utils/arrayData/faqData';
import PsychologyIcon from '@mui/icons-material/Psychology';
const facilitateArray = [
	{icon: BusinessCenterIcon, text: 'Hire ASAP'},
	{icon: CheckCircleIcon, text: 'Screening & Background Checks'},
	{icon: GavelIcon, text: 'Compliance Facilitation'},
	{icon: AccountBalanceWalletIcon, text: 'Pay staff their real salaries'},
	{icon: AddCardIcon, text: 'One Flat fee'},
	{icon: AutorenewIcon, text: 'Unlimited replacements*'},
];
const steps = [
	{
		id: 1,
		title: 'Review of Job Description + Role',
		description: 'We post and advertise a comprehensive job description, ensuring maximum exposure to attract qualified candidates across diverse channels online.',
	},
	{
		id: 2,
		title: 'Permworks cherry picks the best for you',
		description:
			'Selection and Initial Assessment: We rigorously screen received applications, shortlist candidates based on specified criteria, and conduct initial interviews assessing their suitability, skills, and cultural fit.',
	},
	{
		id: 3,
		title: 'We Dual Interview the Finalists',
		description:
			'Finalist Selection and Joint Interviews: Post technical assessments, we present a curated list of top candidates for joint interviews, coordinating the process for efficient and seamless execution.',
	},
	{
		id: 4,
		title: 'Hired!',
		description:
			'After conducting thorough reference checks, we negotiate and finalise the offer with the chosen candidate. This step includes managing necessary paperwork and facilitating a smooth onboarding experience. Book a discovery call to start the process.',
	},
];

const EndToEnd = () => {
	const navigate = useNavigate();
	const isDesktop = useMediaQuery('(min-width: 1000px)');
	const [googleModal, setGoogleModal] = useState(false);

	return (
		<div>
			<Helmet>
				<title>End To End Recruitment | Permworks</title>
				<meta
					name="description"
					content="End-to-End recruitment service. Focus on what matters and leave the rest to a virtual assistant. We have helped find staff for 100’s of Startups and SME’s grow by allowing them to delegate their repetitive tasks to a Virtual Assistant."
				/>
			</Helmet>
			<HubSpotChat />
			<EnquiryForm openGoogle={googleModal} closeGoogle={() => setGoogleModal(false)} />

			<div className="homeContainer">
				<div className="section1">
					<NavbarDark />

					<div className="full-container">
						<div className="left">
							<h1>End To End recruitment service</h1>
							<h2>Focus on what matters and leave the rest to a virtual assistant. </h2>
							<p>We have helped find staff for 100’s of Startups and SME’s grow by allowing them to delegate their repetitive tasks to a Virtual Assistant. </p>
							<div className="buttons">
								<button className="buttonLightBlue" onClick={() => setGoogleModal(true)}>
									Get in touch
								</button>
								<a href="#section2">
									<button className="buttonSkeletonWhite">Find out more</button>
								</a>
							</div>
						</div>
						{isDesktop && (
							<div className="right">
								<img src={humanity} alt="Job seekers" />
							</div>
						)}
					</div>
				</div>
				<div className="section2" id="section2">
					<OverPack style={{overflow: 'hidden'}} playScale={0.1} className="carousel">
						<QueueAnim key="queue" leaveReverse className="inner-banner" interval={200}>
							<h3>Used by 150+ founders, agencies & enterprises</h3>
							<div className="inner-flex">
								<QueueAnim key="queue" className="inner-queue" leaveReverse interval={200}>
									<img key="b" src={ppp} alt="p" />
									<img key="e" src={nomi} alt="nomi" />
									<img key="c" src={coinbits} alt="coinbits" />
									<img key="d" src={oddit} alt="oddit" />
									<img key="f" src={meat} alt="meat" />
									<img key="g" src={acad} alt="acad" />
									<img key="h" src={fohm} alt="fohm" />
								</QueueAnim>
							</div>
						</QueueAnim>
					</OverPack>
				</div>
				<div className="section3">
					<div className="section3-container">
						<div className="left-side">
							<h2>We facilitate direct hires</h2>
							<h4>Why companies choose us</h4>
							<p>
								Boosting Your Growth with Seamless Hiring - We expedite your recruitment with rigorous screening and swift onboarding, all within legal compliance. With our transparent
								flat-fee structure and commitment to fair pay, we ensure value for your team and your business. Experience unlimited replacements and peace of mind as we commit to
								finding the perfect fit for your needs.
							</p>
						</div>
						<div className="right-side">
							{facilitateArray.map((item, index) =>
								index === 5 ? (
									<Tooltip placement="bottom" className="facilitate-card" title="* For up to 3 months from initial hire" key={index}>
										<div>
											<item.icon className="icon" />
											<p>{item.text}</p>
										</div>
									</Tooltip>
								) : (
									<div className="facilitate-card" key={index}>
										<item.icon className="icon" />
										<p>{item.text}</p>
									</div>
								)
							)}
						</div>
					</div>
				</div>
				<div className="section4">
					<div className="section4-container">
						<h2>With over 8000+ highly skilled candidates</h2>
						<p>Our team of recruiters will find you the perfect candidate for your role.</p>
						<div className="candidate-cards">
							<div className="candidate-card mobile-width-100">
								<img src={person1} alt="abigail" />
								<div className="content">
									<p className="name">Abigail Flores</p>
									<p className="role">
										<PsychologyIcon /> Marketing assistant
									</p>
									<p className="price"></p>
								</div>
							</div>
							<div className="candidate-card mobile-width-100">
								<img src={person2} alt="aries" />
								<div className="content">
									<p className="name">Aries</p>
									<p className="role">
										<PsychologyIcon /> E-commerce store manager
									</p>
									<p className="role">
										<PsychologyIcon /> Customer service representitive
									</p>
									<p className="price"></p>
								</div>
							</div>
							<div className="candidate-card mobile-width-100">
								<img src={person3} alt="julie" />
								<div className="content">
									<p className="name">Julie Ann</p>
									<p className="role">
										<PsychologyIcon /> Social media manager
									</p>
									<p className="price"></p>
								</div>
							</div>
							<div className="candidate-card mobile-width-100">
								<img src={person4} alt="mark" />
								<div className="content">
									<p className="name">Mark Anthony</p>
									<p className="role">
										<PsychologyIcon /> Customer service representitive
									</p>
									<p className="price"></p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="section5">
					<div className="section5-container">
						<h2>How does it work?</h2>
						<div className="steps">
							{steps.map((step) => (
								<div className="step mobile-width-100">
									<div className="step-header">
										<div className="step-icon">{step.id}</div>
										<h4>{step.title}</h4>
									</div>
									<hr className="dim-hr" />
									<div className="step-description">
										<p>{step.description}</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="section6">
					<div className="section6-container">
						<div className="header">
							<h2>What sets us apart?</h2>
							<button className="buttonLinkBlue" onClick={() => setGoogleModal(true)}>
								Book a demo
							</button>
						</div>
						<ComparisonTable />
					</div>
				</div>

				<div className="section7">
					<div className="section7-container">
						<h2>What our clients say</h2>
						<div className="client-cards">
							<div className="client-card mobile-width-100">
								<img src={charbs} alt="charbs" />
								<div className="content">
									<h4>Charbel A</h4>
									<h3>Founder @ Propertied</h3>
									<div className="stars">
										<AiFillStar />
										<AiFillStar />
										<AiFillStar />
										<AiFillStar />
										<AiFillStar />
									</div>
									<p>Engaging Permworks was a great success, with us receiving over 50 applications within the first two days.</p>
									<p className="italic">"Being our first time hiring a virtual staff member, we relied heavily on Permworks to guide us through the process."</p>
								</div>
							</div>
							<div className="client-card mobile-width-100">
								<img src={recr} alt="recr" />
								<div className="content">
									<h4>Emma H</h4>
									<h3>Founder @ Shoestring Services</h3>
									<div className="stars">
										<AiFillStar />
										<AiFillStar />
										<AiFillStar />
										<AiFillStar />
										<AiFillStar />
									</div>
									<p>It has made the process of growing our team with high-quality talent a breeze.</p>
									<p className="italic">"We have been extremely impressed with Permworks."</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section8">
					<div className="section8-container">
						<h2>Frequently asked questions</h2>
						<Accordion defaultActiveKey="" className="accordian">
							{endToEndFaqData.map(({question, answer}, index) => (
								<Accordion.Item eventKey={index.toString()} key={index} className="accordian-item">
									<Accordion.Header>{question}</Accordion.Header>
									<Accordion.Body>{answer}</Accordion.Body>
								</Accordion.Item>
							))}
						</Accordion>
					</div>
				</div>
				<div className="section9">
					<div className="section9-container">
						<h2>Need more help?</h2>
						<p style={{color: '#fffffe'}}>Get in touch and we will do our best to answer your queries</p>
						<button className="buttonLightBlue" onClick={() => setGoogleModal(true)}>
							Get in touch
						</button>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default EndToEnd;
