import React, {useEffect, useState} from 'react';
import './Applicants.scss';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import {Alert, Fade, Menu, MenuItem, capitalize, useMediaQuery} from '@mui/material';
import Hidden from '@mui/material/Hidden';
import {useParams} from 'react-router-dom';
import {deleteJobApi, getJobById, getJobOffersApi, markJobOfferAsSeenApi, updateJobOfferApi} from '../../api/jobsApi';
import {formatDate2} from '../../utils/DateFunctions';
import {useNavigate} from 'react-router-dom';
import {BadgeOutlined, Close, Edit, LocationOn, WorkHistory} from '@mui/icons-material';
import Loading from '../../components/Loaders/Loading';
import {Helmet} from 'react-helmet';
import {permColors} from '../../utils/colors';
import {Business, Tune} from '@material-ui/icons';
import {Button} from 'react-bootstrap';
import {Button as AntButton} from 'antd';
import {textFormatter} from '../../utils/TextAlterations';
import {filterByApplicantName} from '../../utils/filters';
import {returnApplicantFit, returnApplicantFitSmall, returnApplicantSelectThumbs} from './helper';
import {Input, Select} from 'antd';
import ApplicantComponent from './ApplicantComponent';
import TabSelector from './TabSelector';

const Applicants = () => {
	const {jobId} = useParams('jobId');
	const [applicants, setApplicants] = useState([]);
	const [selectedApplicant, setSelectedApplicant] = useState(null);
	const [show, setShow] = useState(false);
	const isSmallViewport = useMediaQuery('(max-width: 900px)');
	const [job, setJob] = useState(null);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [markedAsSeen, setMarkedAsSeen] = useState({});
	const [modalShow, setModalShow] = useState(false);
	const handleModalClose = () => setModalShow(false);
	const [value, setValue] = React.useState(0);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [searchText, setSearchText] = useState('');
	const [candidateFit, setCandidateFit] = useState('All');
	const [selecterLoader, setSelecterLoader] = useState(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleClose = () => {
		setShow(false);
	};

	const handleShow = async (applicant) => {
		if (isSmallViewport) {
			setShow(true);
		}
		setSelectedApplicant(applicant);

		if (!markedAsSeen[applicant.id] && applicant.seen === false) {
			await markJobOfferAsSeenApi(applicant.id);
			setMarkedAsSeen((prevState) => ({
				...prevState,
				[applicant.id]: true,
			}));
		}
	};

	async function getApplicants() {
		const res2 = await getJobById(jobId);
		const res = await getJobOffersApi(jobId);
		setApplicants(res);
		setJob(res2);
		setLoading(false);
	}

	useEffect(() => {
		getApplicants();
	}, [jobId]);

	async function submitCandidateFitChange(e) {
		setSelecterLoader(true);
		await updateJobOfferApi(selectedApplicant.id, e);
		const res = await getJobOffersApi(jobId);
		setApplicants(res);
		setSelectedApplicant(res.filter((applicant) => applicant.id === selectedApplicant.id)[0]);
		setSelecterLoader(false);
	}

	if (loading) {
		return <Loading />;
	}

	const deleteJob = async (id) => {
		handleModalClose();
		const res = await deleteJobApi(id);
		if (res === true) {
			navigate('/dashboard');
		} else {
		}
	};

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloser = () => {
		setAnchorEl(null);
	};

	const filteredApplicants = applicants.filter(filterByApplicantName(searchText, candidateFit));

	// const menuItems = filteredApplicants?.map((applicant) => ({
	// 	text: applicant.freelancer?.profile?.first_name ?? 'Unknown',
	// 	icon: <img src={applicant.freelancer?.profile?.profile_image ?? '/userIcon.png'} alt="profile" style={{width: '30px', height: '30px', borderRadius: '50%'}} />,
	// 	key: applicant.id, // Using a unique identifier as the key
	// }));

	return (
		<div className="applicants" style={{backgroundColor: permColors.backgroundLightCream}}>
			<Helmet>
				<title>Applicants | Permworks</title>
				<meta name="description" content="View all the applicants to your job posting on this page. You can also manage your job posting here." />
			</Helmet>
			<Navbar />
			{/* <ResponsiveDrawer menuItems={menuItems} contentComponents={[<div>Test Content for Selected Applicant: {selectedApplicant?.freelancer?.profile?.first_name}</div>]} /> */}
			<Container className="applicantsContainer mb-4">
				<Row md={1}>
					<Col className="mb-2 mt-2 pr-2 pl-3">
						<Card className="text-left md cardDesign pb-0 shadow-sm">
							<Card.Header className="d-flex align-items-top justify-content-between cardHeaderDesign mb-0 pb-0">
								<Card.Title className="flex items-center gap-2 tag-app-background-blue mb-1" style={{color: permColors.darkCharcoal}}>
									<span className="text-sm font-semibold">{job?.title && capitalize(job?.title)}</span>
								</Card.Title>
								{!job?.completed && (
									<div>
										<AntButton onClick={handleClick} className="flex items-center gap-2">
											<Tune style={{fontSize: '1.3em'}} />
											<span className="whitespace-nowrap text-sm">Manage Job</span>
										</AntButton>
										<Menu anchorEl={anchorEl} open={open} onClose={handleCloser} TransitionComponent={Fade} className="mt-1">
											<MenuItem
												sx={{minWidth: '150px', color: permColors.darkCharcoal, fontSize: '0.9em', margin: '4px', borderRadius: ' 5px'}}
												onClick={() => navigate(`/edit-job/${job.id}`)}
												className="flex items-center gap-2">
												<Edit style={{fontSize: '1.1em'}} />
												Edit job
											</MenuItem>
											<MenuItem
												sx={{minWidth: '150px', color: permColors.darkCharcoal, fontSize: '0.9em', margin: '4px', borderRadius: ' 5px'}}
												onClick={() => setModalShow(true)}
												className="flex items-center gap-2">
												<Close style={{fontSize: '1.1em'}} />
												Close job
											</MenuItem>
										</Menu>
									</div>
								)}
							</Card.Header>
							<div className="flex flex-wrap items-start justify-start pt-0 mt-0">
								<Card.Body className="pt-0 grow">
									<Card.Text style={{color: permColors.darkCharcoal}} className=" mb-1 flex items-center gap-2 font-semibold text-sm tag-app-cream">
										<Business style={{fontSize: '1.3em'}} />
										{job?.company_name && capitalize(job?.company_name)}
									</Card.Text>
									<Card.Text className="mb-1 flex items-center gap-2 text-sm tag-app-cream" style={{color: permColors.charcoal}}>
										<BadgeOutlined style={{fontSize: '1.3em'}} />
										{job?.category}
									</Card.Text>
									<Card.Text className="mb-1 flex items-center gap-2 text-sm tag-app-cream" style={{color: permColors.charcoal}}>
										<LocationOn style={{fontSize: '1.3em'}} />
										{job?.country}
									</Card.Text>
									<Card.Text className="mb-2 flex items-center gap-2 text-sm tag-app-cream" style={{color: permColors.charcoal}}>
										<WorkHistory style={{fontSize: '1.3em'}} /> {job?.job_type}
									</Card.Text>
									<AntButton className=" w-full " onClick={() => navigate(`/jobpage/${job.id}`)}>
										View job
									</AntButton>
								</Card.Body>
								<Card.Body className="pt-0 w-[70%] grow">
									<Card.Text className="mb-2">
										<strong style={{color: permColors.darkCharcoal}} className="text-sm tag-app-cream ">
											Job description
										</strong>
										<div
											style={{color: permColors.charcoal}}
											className="description max-h-[250px] overflow-y-auto pr-2 text-sm font-normal  mt-2 tag-app-background-grey"
											dangerouslySetInnerHTML={{
												__html: textFormatter(job.description),
											}}
										/>
									</Card.Text>
								</Card.Body>

								<Card.Footer className="flex flex-wrap items-center cardFooterDesign w-full py-3 m-0">
									<div className="flex flex-wrap gap-2 w-[550px]">
										<Input type="text" placeholder="Search by name..." value={searchText} onChange={(e) => setSearchText(e.target.value)} className="w-[250px] grow p-2" />
										<Select
											aria-label="Default select example"
											placeholder="Candidate fit"
											defaultValue="All"
											onChange={(e) => setCandidateFit(e)}
											className="w-[250px] grow"
											options={[
												{label: 'All candidates', value: 'All'},
												{label: 'Undecided', value: 'Undecided'},
												{label: 'Good fit', value: 'Good_fit'},
												{label: 'Not a fit', value: 'Not_a_fit'},
											]}
										/>
									</div>
								</Card.Footer>
							</div>
						</Card>
					</Col>
				</Row>
				<Row lg={3}>
					<Col style={{flex: 1}} className="pr-2 md:pr-0">
						<Card className="text-left cardDesign p-0 shadow-sm">
							<Card.Header className="cardHeaderDesign p-2 px-3 flex flex-column" style={{color: permColors.darkCharcoal}}>
								<span className="text-base font-semibold">{filteredApplicants?.length} Applicants</span>
							</Card.Header>
							<Card.Body style={{maxHeight: '500px', overflowY: 'auto', padding: '6px'}}>
								{filteredApplicants.length > 0 ? (
									filteredApplicants?.map((applicant, index) => (
										<React.Fragment key={index}>
											<ListItem
												alignItems="flex-start"
												style={{listStyle: 'none', cursor: 'pointer'}}
												onClick={() => {
													handleShow(applicant);
												}}
												className={'list-group-item-custom pb-0 ' + (selectedApplicant?.id === applicant.id ? 'list-group-item-custom-selected' : '')}>
												<ListItemAvatar>
													<Avatar
														alt={`${applicant.freelancer?.profile?.first_name ?? 'Unknown'} ${applicant.freelancer?.profile?.last_name ?? 'Applicant'}`}
														src={applicant.freelancer?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
														className="shadow-md"
													/>
												</ListItemAvatar>
												<ListItemText
													primary={
														<p className="m-0 font-semibold text-sm">
															{applicant.freelancer?.profile?.first_name ?? 'Unknown'} {applicant.freelancer?.profile?.last_name ?? 'Applicant'}
														</p>
													}
													sx={{color: permColors.darkCharcoal}}
													className="mb-0"
													secondary={
														<React.Fragment>
															<Typography sx={{display: 'inline'}} component="span" variant="body2" color={permColors.charcoal}>
																{applicant.freelancer?.email ?? 'No email provided'}
															</Typography>
															<Typography sx={{display: 'inline'}} component="span" variant="body2" color={permColors.charcoal}></Typography>
															<Typography sx={{display: 'block', fontSize: '0.9em', fontWeight: '300'}} component="span" variant="body2" color={permColors.charcoal}>
																{'Applied on ' + formatDate2(applicant.created_at) ?? 'No phone number provided'}
															</Typography>
															<Typography sx={{display: 'block'}} component="span" variant="body2" color={permColors.charcoal}>
																<p className="flex items-center gap-2">
																	{applicant?.candidate_fit && returnApplicantFitSmall(applicant.candidate_fit)}
																	{applicant?.seen === false && <span className="dot" />}
																</p>
															</Typography>
														</React.Fragment>
													}
												/>
											</ListItem>
										</React.Fragment>
									))
								) : (
									<Alert severity="info" className="m-3 mt-0 rounded-lg">
										You have no applicants for this job yet.
									</Alert>
								)}
							</Card.Body>
						</Card>
					</Col>
					<Hidden mdDown>
						<Col style={{flex: 2}} className="pr-2">
							<Card className="text-left cardDesign p-0 shadow-sm">
								<Card.Header className="text-left cardHeaderDesign mt-2 items-start" style={{color: permColors.darkCharcoal, padding: '10px 20px'}}>
									<div className="flex gap-2 items-center text-base">
										{selectedApplicant
											? `${selectedApplicant.freelancer?.profile?.first_name ?? 'Unknown'} ${selectedApplicant.freelancer?.profile?.last_name ?? 'Applicant'}`
											: 'Select an applicant'}
										{selectedApplicant?.candidate_fit && <div className="flex flex-column">{returnApplicantFit(selectedApplicant.candidate_fit)} </div>}
									</div>
									{selectedApplicant && returnApplicantSelectThumbs(submitCandidateFitChange, selectedApplicant, selecterLoader)}
								</Card.Header>
								{selectedApplicant ? (
									<div>
										<TabSelector value={value} handleChange={handleChange} />
										<ApplicantComponent value={value} selectedApplicant={selectedApplicant} />
										<Card.Footer className="d-flex align-items-center cardFooterDesign p-4">
											<button onClick={() => navigate(`/userprofile/${selectedApplicant.freelancer.id}`)} className="buttonLinkBlue">
												View Profile
											</button>
											<Typography sx={{marginLeft: 'auto'}}>
												<strong>Applied on </strong>
												{formatDate2(selectedApplicant.created_at)}
											</Typography>
										</Card.Footer>
									</div>
								) : (
									<Alert severity="info" className="m-3 mt-1 rounded-lg">
										Select an applicant to view their application details.
									</Alert>
								)}
							</Card>
						</Col>
					</Hidden>
				</Row>
			</Container>
			<Modal show={show} onHide={handleClose} style={{textAlign: 'left'}} size="xl">
				<Modal.Header closeButton style={{color: permColors.charcoal}}>
					{selectedApplicant ? `${selectedApplicant.freelancer?.profile?.first_name ?? 'Unknown'} ${selectedApplicant.freelancer?.profile?.last_name ?? 'Applicant'}` : 'Select an applicant'}
				</Modal.Header>
				{selectedApplicant ? (
					<div>
						{selectedApplicant && <div className="p-3">{returnApplicantSelectThumbs(submitCandidateFitChange, selectedApplicant, selecterLoader)} </div>}
						{selectedApplicant?.candidate_fit && (
							<div className="flex p-3 py-2 items-center w-full gap-2">
								<span style={{color: permColors.charcoal}}>Candidate fit </span>
								{returnApplicantFit(selectedApplicant.candidate_fit)}
							</div>
						)}
						<TabSelector value={value} handleChange={handleChange} />
						<ApplicantComponent value={value} selectedApplicant={selectedApplicant} />
						<Card.Footer className="flex flex-column gap-2 align-items-center cardFooterDesign p-4">
							<button onClick={() => navigate(`/userprofile/${selectedApplicant.freelancer.id}`)} className="buttonLinkBlue w-full">
								View Profile
							</button>
							<Typography sx={{flex: '1'}}>
								<strong>Applied on </strong>
								{formatDate2(selectedApplicant.created_at)}
							</Typography>
						</Card.Footer>
					</div>
				) : (
					<Card.Body>
						<Card.Text>Select an applicant to view their application details.</Card.Text>
					</Card.Body>
				)}
			</Modal>
			<Modal show={modalShow} onHide={handleModalClose}>
				<Modal.Header closeButton>
					<Modal.Title>Close job</Modal.Title>
				</Modal.Header>
				<Modal.Body>Do you wish to continue to close this job?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleModalClose}>
						No
					</Button>
					<Button variant="error" onClick={() => deleteJob(job.id)}>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
			<Footer backgroundColor={permColors.lightBlueless} />
		</div>
	);
};

export default Applicants;
