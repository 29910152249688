import React from 'react';
import {Link} from 'react-router-dom';
import './Footer.scss';
import {FullLogo} from '../Logos/Logo';
import {otherPageLinks, socialMediaLinks} from './footer_data';
import {permColors} from '../../utils/colors';
import {OpenInNew} from '@mui/icons-material';

const ExternalLink = ({href, children}) => (
	<a href={href} target="_blank" rel="noopener noreferrer">
		{children}
	</a>
);

export default function Footer({backgroundColor = permColors.lightBlueless}) {
	const year = new Date().getFullYear();
	return (
		<div className="footerContainer" style={{backgroundColor}}>
			<div className="text-center">
				<FullLogo />
			</div>
			<div className="socialMediaLinks">
				{socialMediaLinks.map(({href, icon}, index) => (
					<ExternalLink href={href} key={index}>
						{icon}
					</ExternalLink>
				))}
			</div>
			<div className="otherPagesLinks">
				{otherPageLinks.map((column, index) => (
					<div className="column" key={index}>
						{column.map((link, linkIndex) =>
							link.external ? (
								<ExternalLink href={link.to} key={linkIndex}>
									<span className="flex gap-2 items-center text-sm">
										{link.label} <OpenInNew style={{fontSize: '1.2em'}} />
									</span>
								</ExternalLink>
							) : (
								<Link to={link.to} key={linkIndex} className="text-sm">
									{link.label}
								</Link>
							)
						)}
					</div>
				))}
			</div>
			<hr />
			<div className="copyright text-center" style={{backgroundColor}}>
				<p>&copy; Permworks {year}</p>
			</div>
		</div>
	);
}
