import {mainUrl} from './Urls';
import axios from 'axios';
import api from './interceptor';

export const stripeAddPaymentMethod = async (paymentMethodId) => {
	try {
		const response = await api.post(
			mainUrl + '/auth/add-payment-method/',
			{
				paymentMethodID: paymentMethodId,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);
		console.log(response);
	} catch (error) {
		console.error('[api error]', error);
	}
};

export const getCurrentUserPaymentMethodApi = async () => {
	try {
		const response = await api.get(mainUrl + '/auth/get-current-user-payment-method/', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		console.log('Current user payment method:', response.data);
		return response.data.card;
	} catch (error) {
		if (error.response) {
			console.error('[api error]', error.response.data, error.response.status);
		} else {
			console.error('[api error]', error.message);
		}
		return false;
	}
};

export const createJobCheckoutSession = async (jobId, subscriptionType) => {
	try {
		const response = await api.post(
			mainUrl + `/auth/create-job-checkout-session/`,
			{
				job_id: jobId,
				subscription_type: subscriptionType,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);
		return 'success';
	} catch (error) {
		return error;
	}
};

export const cancelJobSubscription = async (jobId) => {
	try {
		const response = await api.post(
			mainUrl + '/auth/cancel-job/',
			{job_id: jobId},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error('Error cancelling job:', error);
		return false;
	}
};

export const stripeBillingHistory = async (date_order) => {
	try {
		const response = await api.get(
			mainUrl + '/auth/stripe-billing-history/',

			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
				params: {
					date_order: date_order,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error('[axios error]', error);
		return false;
	}
};
