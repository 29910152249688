import React from 'react';
import {useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {GoogleLogin} from '@react-oauth/google';
import Loading from '../Loaders/Loading';
import {mainUrl} from '../../api/Urls';
import {UserContext} from '../../utils/UserContext';
import {getUser, googleAuthApi, signUpApi} from '../../api/authApi';
import {useMediaQuery} from '@mui/material';
import {FullLogo, FullLogoSmall} from '../Logos/Logo';
import {ROUTES} from '../../utils/ROUTES';
import {Alert, Button, Checkbox, Input} from 'antd';
import {Email} from '@mui/icons-material';
import {LockOutlined, MailOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';

export default function Signup() {
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [rePassword, setRePassword] = useState('');
	const [error, setError] = useState('');
	const [passwordError, setPasswordError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [googleEmailError, setGoogleEmailError] = useState('');
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const smallMatches = useMediaQuery('(max-width: 525px)');

	function clearErrors() {
		setError(false);
		setGoogleEmailError('');
	}

	async function googleLogin(response) {
		try {
			console.log(response);
			setLoading(true);

			const googleAuthApiResponse = await googleAuthApi(response);
			const {data} = googleAuthApiResponse;

			console.log(data);
			clearErrors();
			localStorage.setItem('token', data.tokens.refresh);
			localStorage.setItem('accessToken', data.tokens.access);

			const userResponse = await getUser();
			setCurrentUser(userResponse.data);
			setLoading(false);
			navigate('/');
		} catch (error) {
			if (error.response.data) {
				clearErrors();
				setGoogleEmailError(error.response.data.detail);
			}
		}
	}

	async function signUp(e) {
		e.preventDefault();
		if (password === rePassword) {
			setLoading(true);

			if (password === rePassword) {
				setLoading(true);
				const result = await signUpApi(email, password);
				if (result.success) {
					setError('');
					setLoading(false);
					navigate('/confirmemail', {state: {email: result.email}});
				} else {
					setError(result.error);
					setLoading(false);
				}
			}
		} else {
			setPasswordError(true);
			setLoading(false);
		}
	}

	return (
		<div>
			<form onSubmit={signUp}>
				{/* <div className="w-full flex justify-center">
					<FullLogoSmall fontWeight={600} />
				</div> */}

				<div className="text">
					<h1>Create your account</h1>
					<h2 className="text-lg">Join our community.</h2>
				</div>

				<div>
					<p className="mb-1 font-semibold text-sm" style={{color: permColors.darkCharcoal}}>
						Email
					</p>
					<Input
						type="email"
						placeholder="Enter your email"
						required
						onChange={(e) => setEmail(e.target.value)}
						className="mb-3"
						allowClear
						prefix={<MailOutlined className="site-form-item-icon mr-1 opacity-20" />}
					/>
					{error && <Alert message={error} type="error" showIcon className="mb-2" />}
					<p className="mb-1 font-semibold text-sm" style={{color: permColors.darkCharcoal}}>
						Password
					</p>
					<Input
						type="password"
						placeholder="Enter your password"
						required
						onChange={(e) => setPassword(e.target.value)}
						className="mb-3"
						allowClear
						prefix={<LockOutlined className="site-form-item-icon mr-1 opacity-20" />}
					/>
					<p className="mb-1 font-semibold text-sm" style={{color: permColors.darkCharcoal}}>
						Confirm Password
					</p>
					<Input
						type="password"
						placeholder="Confirm your password"
						required
						onChange={(e) => setRePassword(e.target.value)}
						className="mb-3"
						allowClear
						prefix={<LockOutlined className="site-form-item-icon mr-1 opacity-20" />}
					/>
					{passwordError && <Alert message="Passwords do not match" type="error" showIcon className="mt-3 mb-2" />}
					<Checkbox required className="mb-2 mt-2">
						I agree to and understand the{' '}
						<Link target="_blank" to={ROUTES.TERMS_AND_CONDITIONS}>
							Terms and Service.
						</Link>
					</Checkbox>
				</div>

				<Button
					htmlType="submit" // This specifies that the button is a submit button
					className="ButtonLinkBlue min-h-fit w-full m-0">
					Sign up
				</Button>

				<div className="divider-container">
					<div className="divider">
						<hr />
						<span className="text-xs">OR</span>
						<hr />
					</div>
				</div>
				<div className="google-button">
					<GoogleLogin
						onSuccess={(credentialResponse) => {
							googleLogin(credentialResponse);
						}}
						onError={() => {
							console.log('Login Failed');
						}}
						size="large"
						theme="filled"
						shape="square"
						width={smallMatches ? '310px' : '900px'}
					/>
				</div>
				{loading && <Loading />}
			</form>
		</div>
	);
}
