import {mainUrl} from '../Urls';
import api from '../interceptor';

export async function fetchEmployees() {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};
		const response = await api.get(`${mainUrl}/eor/employee/`, config);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function createEmployee(employeeDetails) {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};
		const response = await api.post(
			`${mainUrl}/eor/employee/`,
			{
				first_name: employeeDetails.firstName,
				last_name: employeeDetails.lastName,
				email: employeeDetails.email,
				gender: employeeDetails.gender,
			},
			config
		);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function updateEmployeer(id, employeeDetails) {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};
		const response = await api.put(
			`${mainUrl}/eor/employee/${id}/`,
			{
				first_name: employeeDetails.firstName,
				last_name: employeeDetails.lastName,
				email: employeeDetails.email,
				gender: employeeDetails.gender,
				team: '8151ae62-aef0-4cbf-bdab-8688c13ed3a9',
			},
			config
		);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function createTimeOffRequest(timeOffDetails) {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};
		const response = await api.post(
			`${mainUrl}/eor/timeoff/`,
			{
				start_date: timeOffDetails.startDate,
				end_date: timeOffDetails.endDate,
				reason: timeOffDetails.reason,
				leave_type: timeOffDetails.leaveType,
				status: 'pending',
				employee: timeOffDetails.employee,
			},
			config
		);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}
