import React, {useEffect, useState, useContext} from 'react';
import {getPageUserApi} from '../../api/authApi';
import {useParams} from 'react-router-dom';
import uploadCircle from '../../assets/images/uploadCircle.png';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import {UserContext} from '../../utils/UserContext';
import Loading from '../../components/Loaders/Loading';
import {capitalizeFirstLetter, textFormatter} from '../../utils/TextAlterations';
import {formatDate} from '../../utils/DateFunctions';
import MessagePopup from '../../components/Popups/MessagePopup';
import './OtherProfile.scss';
import {Helmet} from 'react-helmet';
import {Email, Place, Send, WorkRounded} from '@material-ui/icons';
import {capitalize} from '@mui/material';

export default function OtherProfile() {
	const [user, setUser] = useState({});
	const {userId} = useParams();
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [popup, setPopup] = useState(false);

	function closePopup() {
		setPopup(false);
	}

	useEffect(() => {
		async function fetchPageUser() {
			const res = await getPageUserApi(userId);
			setUser(res);
			console.log(res);
			setLoading(false);
		}
		fetchPageUser();
	}, []);

	if (loading) {
		return <Loading />;
	}

	return (
		<div style={{backgroundColor: '#f5f5f5', minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
			<Helmet>
				<title>{`${user?.profile?.first_name}'s Profile | Permworks`}</title>
				<meta name="description" content={`View ${user?.profile?.first_name}'s profile on Permworks. Explore their skills, experience, and more.`} />
			</Helmet>
			<Navbar />
			<div className="other-profile-wrap">
				<h1 className="md:text-left py-1 text-2xl font-bold">Profile</h1>
				<div className="otherProfileContainer">
					<div className="left-side">
						<div className="editProfileSubContainer2">
							<img src={user?.profile?.profile_image ? user?.profile?.profile_image : uploadCircle} alt="Circle Image" className="circle-image" />
							<h4>
								{capitalizeFirstLetter(user?.profile?.first_name)} {capitalizeFirstLetter(user?.profile?.last_name)}
							</h4>
							<h5 className="flex gap-2 items-center">
								<Place style={{fontSize: '1.2em'}} /> {user?.profile?.country ?? 'Unknown'}
							</h5>
							<h5 className="flex gap-2 items-center">
								<WorkRounded style={{fontSize: '1.2em'}} /> {user?.profile?.freelancer_profile_title ?? 'No title'}
							</h5>
							<h5>{user?.profile?.freelancer_profile_description ?? 'No description'}</h5>
							{user?.email !== currentUser.email && (
								<button className="buttonLinkBlue w-full flex items-center justify-center gap-2" onClick={() => setPopup(true)}>
									<Email /> Message
								</button>
							)}
						</div>
						{/*  Skills Tags =====================================================================================  */}

						<div className="editProfileSubContainer2">
							<h3>Skills </h3>

							<div>
								{user?.tag?.map((skill, index) => {
									return (
										<div className="skill" key={index}>
											<p>{skill?.name} </p>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					<div className="right-side">
						<h2 className="mb-3">General Information</h2>
						<div className="editProfileSubContainer">
							<div className="column">
								<h3>Work Experience</h3>
								<div>
									{user?.work_experience?.map((work, index) => {
										return (
											<div className="workExperience" key={index}>
												<h4>
													<strong>{capitalizeFirstLetter(work?.company_name)}</strong>
												</h4>
												<h5>
													<strong>{capitalizeFirstLetter(work?.position_title)}</strong>
												</h5>
												<h5>{work?.country}</h5>
												<h5>
													{work?.from_year} {work?.from_month} -{work?.to_year} {work?.to_month}
												</h5>
												<p
													className="description"
													dangerouslySetInnerHTML={{
														__html: textFormatter(work?.description),
													}}
												/>
											</div>
										);
									})}
								</div>
							</div>
							<div className="column">
								<h3>Education </h3>
								<div>
									{user?.education?.map((item, index) => {
										return (
											<div className="workExperience" key={index}>
												<h4>
													<strong>{item?.area_of_study && capitalizeFirstLetter(item?.area_of_study)}</strong>
												</h4>
												<h5>
													{' '}
													<strong>{item?.school_name && capitalizeFirstLetter(item?.school_name)}</strong>
												</h5>
												<h5>{item?.education_qualification}</h5>
												<h5>
													{item?.year_from} - {item?.year_to}
												</h5>
												<p
													className="description"
													dangerouslySetInnerHTML={{
														__html: textFormatter(item?.description),
													}}
												/>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						<div className="editProfileSubContainer3">
							<h3>Portfolio</h3>
							<div>
								{user?.portfolio?.map((work, index) => {
									return (
										<div className="workExperience-2" key={index}>
											<h4>{work?.title}</h4>
											<h5>{work?.description}</h5>
											<h5>{work?.service_type}</h5>
											<h5>{work?.category}</h5>
											<h5>{work && `Completed on the ${formatDate(work?.completion_date)}`}</h5>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<MessagePopup
				showPopup={popup}
				closePopup={closePopup}
				title={'Send a message to ' + user?.profile?.first_name}
				textArea={true}
				buttonText={'Send'}
				user={user.id}
				disabledButtonText={'Sent'}
			/>

			{loading && <Loading />}
			<Footer />
		</div>
	);
}
