import React, {useState, useEffect, createContext} from 'react';
import Loading from '../../components/Loaders/Loading';
import {getAllBlogs} from '../../api/firebaseBlogs';
export const BlogDataContext = createContext(); // Create a new context

export function BlogDataProvider({children}) {
	const [blogInfo, setBlogInfo] = useState([]);
	const [blogLoader, setBlogLoader] = useState(true);

	useEffect(() => {
		if (blogInfo.length === 0) {
			getAllBlogs().then((res) => {
				console.log(res);
				setBlogInfo(res);
				setBlogLoader(false);
			});
		} else {
			setBlogLoader(false);
		}
	}, []); // Remove blogInfo from dependency array

	if (blogLoader) {
		return <Loading />;
	}

	return <BlogDataContext.Provider value={[blogInfo, setBlogInfo]}>{children}</BlogDataContext.Provider>;
}
