import React, {useContext, useState, useRef, useEffect} from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import {UserContext} from '../../utils/UserContext';
import './EditProfile.scss';
import uploadCircle from '../../assets/images/uploadCircle.png';
import {editProfileApi, editProfileImage} from '../../api/profileApi';
import Loading from '../../components/Loaders/Loading';
import {countries} from '../../utils/arrayData/CountryArray';
import NewPasswordPopup from '../../components/AuthComponents/NewPasswordPopup';
import {Helmet} from 'react-helmet';
import {Check, Close, Edit, Place} from '@material-ui/icons';
import {capitalize} from '@mui/material';
import {Form} from 'react-bootstrap';

export default function EditProfile() {
	const countryList = countries;
	const [currentUser] = useContext(UserContext);
	const [edit, setEdit] = useState(false);
	const fileInputRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [country, setCountry] = useState('');
	const [popup, setPopup] = useState(false);

	const resetPasswordButton = () => {
		openPopup();
	};
	function openPopup() {
		setPopup(true);
	}
	function closePopup() {
		setPopup(false);
	}

	useEffect(() => {
		if (currentUser?.profile) {
			setFirstName(currentUser?.profile?.first_name);
			setLastName(currentUser?.profile?.last_name);
			setCountry(currentUser?.profile?.country);
		}
	}, [currentUser]);

	const handleImageChange = async (e) => {
		if (e.target.files && e.target.files[0]) {
			setLoading(true);
			const res = await editProfileImage(e.target.files[0]);
			console.log(res);
			window.location.reload();
		}
	};
	async function updateProfile() {
		try {
			const res = await editProfileApi(firstName, lastName, country);
			setEdit(false);
			console.log(res);
			window.location.reload();
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<div className="edit-profile-container">
			<Helmet>
				<title>Edit Profile | Permworks</title>
				<meta name="description" content="Update your Permworks profile. Keep your skills, experience, and other details up to date." />
			</Helmet>
			<Navbar />
			<div className="edit-profile-outer">
				<h1 className="md:text-left py-1 text-2xl font-bold">Profile</h1>

				<div className="editProfileContainer">
					<div className="left-side">
						<div className="container">
							<input type="file" ref={fileInputRef} style={{display: 'none'}} onChange={(e) => handleImageChange(e)} />
							<img
								src={currentUser?.profile?.profile_image ? currentUser?.profile?.profile_image : uploadCircle}
								alt="Circle profile"
								className="circle-image"
								onClick={() => fileInputRef.current.click()}
							/>
							{!edit && (
								<div>
									<h4>
										{capitalize(currentUser?.profile?.first_name)} {capitalize(currentUser?.profile?.last_name)}
									</h4>
									<h5 className="flex gap-2 items-center">
										<Place style={{fontSize: '1.2em'}} /> {currentUser?.profile?.country ?? 'Unknown'}
									</h5>
									<div className="flex gap-2">
										<button className="buttonLinkBlue flex items-center gap-2 justify-center" onClick={() => setEdit(true)}>
											<Edit /> Edit
										</button>
										{currentUser?.auth_provider === 'email' && (
											<button className="buttonRedSmall" onClick={resetPasswordButton}>
												Change Password
											</button>
										)}
									</div>
								</div>
							)}
							{edit && (
								<div className="edit-container">
									<Form.Control type="text" placeholder="first name" defaultValue={currentUser?.profile?.first_name} onChange={(e) => setFirstName(e.target.value)} />

									<Form.Control type="text" placeholder="last name" defaultValue={currentUser?.profile?.last_name} onChange={(e) => setLastName(e.target.value)} />

									<Form.Select name="countries" id="countries" placeholder="Select country" defaultValue={currentUser?.profile?.country} onChange={(e) => setCountry(e.target.value)}>
										{countryList.map((country, index) => {
											return (
												<option key={index} value={country}>
													{country}
												</option>
											);
										})}
									</Form.Select>

									<div className="buttons">
										<div onClick={updateProfile}>
											<Check className="edit-icon" />
										</div>
										<div onClick={() => setEdit(false)}>
											<Close className="edit-icon" />
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<NewPasswordPopup showPopup={popup} closePopup={closePopup} />

			{loading && <Loading />}
			<Footer />
		</div>
	);
}
