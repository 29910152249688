import './loaders.scss';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
export default function Loading() {
	return (
		<div className="loader">
			<div className="loaderInner">
				<Spin
					indicator={
						<LoadingOutlined
							style={{
								fontSize: 56,
							}}
							spin
						/>
					}
				/>
			</div>
		</div>
	);
}
