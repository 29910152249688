import React from 'react';
import App from './App';
import './customBootstrap.scss';
import './index.scss';
import './permworksStyles.scss';
import './colorVariables.scss';
import './antDesignCustom.scss';

import ReactGA from 'react-ga4';
import {render} from 'react-snapshot';
import {AuthProvider} from './firebase/userProviders';

// const root = ReactDOM.createRoot(document.getElementById('root'));

// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

// root.render(
// 	// <React.StrictMode>
// 	<App />
// 	// </React.StrictMode>
// );

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

render(
	<AuthProvider>
		<App />
	</AuthProvider>,
	document.getElementById('root')
);
