import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {Button} from 'antd';

const customStyle = {
	width: '450px',
	maxWidth: '95vw',
	margin: '0 auto',
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function GoogleModal({openGoogle, closeGoogle, dialogTitle, dialogContent, button1, button2, button1function, button2function, link, linkText, link2, linkText2}) {
	const handleClose = () => {
		closeGoogle();
	};

	return (
		<Dialog
			open={openGoogle}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby="alert-dialog-slide-description"
			fullWidth={true}
			// maxWidth={'sm'}
			style={customStyle}>
			<DialogTitle>{dialogTitle}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">{dialogContent}</DialogContentText>
			</DialogContent>
			<DialogActions>
				{link2 && <Button onClick={handleClose}>{linkText2}</Button>}
				{button1 && (
					<Button className="" onClick={button1function}>
						{button1}
					</Button>
				)}
				{link && (
					<Button className=" ml-1" href={link} type="primary">
						{linkText}
					</Button>
				)}
				{button2 && (
					<Button className=" ml-1" onClick={button2function}>
						{button2}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}
