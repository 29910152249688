import {useContext, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import './BlogPost.scss';
import {BlogContext} from '../../utils/arrayData/blogData';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {NavigateNext} from '@material-ui/icons';
import {textFormatter} from '../../utils/TextAlterations';
import {formatDate2} from '../../utils/DateFunctions';
import {BlogDataContext} from '../../utils/blog/BlogDataProvider';

// This is your blog post component
function BlogPost() {
	const [blogInfo] = useContext(BlogDataContext);
	const {slug} = useParams();
	const blogPost = blogInfo.find((post) => post.url === `${slug}`);
	const navigate = useNavigate();

	useEffect(() => {
		if (!blogPost) {
			console.log(blogPost);
			console.log('not found');
			navigate('/not-found');
		}
	}, [blogPost, navigate]);

	if (!blogPost) {
		return null;
	}

	return (
		<div>
			<Helmet>
				<title>{blogPost?.title} | Permworks</title>
				<meta name="description" content={blogPost?.description} />
			</Helmet>
			<Navbar />
			<div className="blog-page">
				<div className="blog-post">
					<Breadcrumbs separator={<NavigateNext fontSize="small" style={{color: '#5f6c7b90', verticalAlign: 'middle', marginTop: '3px'}} />} aria-label="breadcrumb" className="bread-crumbs">
						<Link underline="hover" fontWeight="600" color="textPrimary" href="/">
							Home
						</Link>
						<Link underline="hover" fontWeight="600" color="textPrimary" href="/blog">
							Blog
						</Link>
						<Typography color="textPrimary">{blogPost?.title}</Typography>
					</Breadcrumbs>
					<h1 className="blog-post-title">{blogPost?.title}</h1>
					<h2 className="blog-post-description">{blogPost?.description}</h2>
					<p className="blog-post-author">
						By {blogPost?.created_by} on {formatDate2(blogPost?.created_at)}
					</p>

					<img className="blog-post-image" src={blogPost?.image} alt={blogPost?.alt} />
					<p className="blog-post-content" dangerouslySetInnerHTML={{__html: textFormatter(blogPost?.content)}}></p>
					<button onClick={() => navigate('/postjob')} className="buttonRed blog-post-button">
						Post a job
					</button>
					<p className="blog-post-disclaimer">Information contained in this article may have changed since publication.</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default BlogPost;
