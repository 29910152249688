import React, {createContext, useEffect, useState, ReactNode} from 'react';
import {getAuth, onAuthStateChanged, User} from 'firebase/auth';

interface AuthContextType {
	firebaseCurrentUser: User | null;
	loading: boolean;
}

export const AuthContext = createContext<AuthContextType>({
	firebaseCurrentUser: null,
	loading: true,
});

interface AuthProviderProps {
	children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
	const [firebaseCurrentUser, setFirebaseCurrentUser] = useState<User | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const auth = getAuth();
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setFirebaseCurrentUser(user);
			setLoading(false);
		});

		return unsubscribe; // Unsubscribe on unmount
	}, []);

	return <AuthContext.Provider value={{firebaseCurrentUser, loading}}>{children}</AuthContext.Provider>;
};
