export const capitalizeFirstLetter = (string: string = 'unknown'): string => {
	return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const textFormatter = (text: string): string => {
	const paragraphs = text?.split('\n\n');
	let formattedText = '';

	paragraphs?.forEach((paragraph, index) => {
		formattedText += `${paragraph.replace(/\n/g, '<br/>')}`;
		if (index < paragraphs.length - 1) {
			formattedText += '<br/>';
		}
	});

	return formattedText;
};

export const formatStripeAmount = (amount: number): string => {
	return `$${(amount / 100).toFixed(2)}`; // Convert cents to dollars and format as USD
};
