export const categoryOptions = [
	'Admin (Virtual Assistant)',
	'Customer Service & Support',
	'Data Science & Analytics',
	'Design & Creative',
	'Engineering & Architecture',
	'Finance & Accounting',
	'Human Resources',
	'IT & Networking',
	'Legal',
	'Marketing',
	'Professional Services',
	'Project Management',
	'Sales',
	'Translation',
	'Web, Mobile & Software Dev',
	'Writing',
	'Other',
];

export const serviceType = [
	'Accounting',
	'Financial Planning',
	'Human Resources',
	'Management Consulting',
	'Other - Accounting & Consulting',
	'Data Entry',
	'Data Mining',
	'Data Extraction',
	'Data Analysis',
	'Data Visualization',
	'Other - Data Science & Analytics',
	'3D Modeling & CAD',
	'Architecture',
	'Building & Home Improvement',
	'Interior Design',
	'Product Design',
	'Other - Design & Creative',
	'Engineering',
	'Other - Engineering & Architecture',
	'Legal',
	'Other - Legal',
	'Administrative Support',
	'Customer Service',
	'Personal Assistant',
	'Other - Admin Support',
	'Business',
	'Career Advice',
	'Market Research',
	'Other - Business',
	'Copywriting',
	'Editing & Proofreading',
	'Legal Writing',
	'Resumes & Cover Letters',
	'Sales Copy',
	'Translation',
	'Other - Writing',
	'Other',
];

export const jobSkills = [
	'Data Mining',
	'Public Relations',
	'Email Marketing',
	'Project Management',
	'Web Development',
	'Graphic Design',
	'Social Media Management',
	'Search Engine Optimization',
	'Content Creation',
	'Copywriting',
	'User Experience Design',
	'Customer Relationship Management',
	'Data Analysis',
	'Market Research',
	'Sales',
	'Networking',
	'Presentation Skills',
	'Technical Support',
	'Software Development',
	'Database Management',
	'Video Editing',
	'Photography',
	'Team Building',
	'Leadership',
	'Budgeting',
	'Time Management',
	'Problem Solving',
	'Conflict Resolution',
	'Analytical Thinking',
	'Strategic Planning',
	'Risk Management',
	'Change Management',
	'Human Resources',
	'Training and Development',
	'Recruiting',
	'Performance Management',
	'Event Planning',
	'Product Management',
	'Quality Assurance',
	'IT Security',
	'Business Development',
	'Account Management',
	'Vendor Management',
	'Contract Negotiation',
	'Brand Management',
	'Advertising',
	'Media Relations',
	'Digital Marketing',
	'E-commerce',
	'Mobile App Development',
	'Artificial Intelligence',
	'Machine Learning',
	'Cloud Computing',
	'Virtualization',
	'Information Architecture',
	'Data Visualization',
	'Business Intelligence',
	'Cybersecurity',
	'Automation',
	'Agile Methodologies',
	'Scrum',
	'Software Testing',
	'Programming Languages (e.g., JavaScript, Python, Java)',
	'Technical Writing',
	'System Administration',
	'Computer Networking',
	'Enterprise Resource Planning',
	'Customer Service',
	'Multitasking',
	'Organizational Skills',
	'Emotional Intelligence',
	'Critical Thinking',
	'Decision Making',
	'Interpersonal Skills',
	'Adaptability',
	'Negotiation',
	'Work Ethic',
	'Cross-cultural Communication',
	'Attention to Detail',
];
