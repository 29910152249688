import React from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar2 from '../../components/Navbars/Navbar2';
import './Faq.scss';
import {Helmet} from 'react-helmet';
import {employersQuestions, jobSeekerQuestions} from '../../utils/arrayData/faqData';
import {Accordion} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default function Faq() {
	return (
		<div>
			<Helmet>
				<title>FAQ | Permworks</title>
				<meta name="description" content="Frequently Asked Questions. Find answers to common questions about our platform, hiring process, and more." />
			</Helmet>
			<Navbar2 />
			<div className="faq">
				<h1>Frequently asked questions</h1>
				<p>Our Frequently Asked Questions section is designed to provide comprehensive responses to the queries we encounter most often from our users.</p>
				<h3 className="title">Job Seeker Questions</h3>
				<Accordion defaultActiveKey="" className="accordian">
					{jobSeekerQuestions.map(({question, answer}, index) => (
						<Accordion.Item eventKey={index.toString()} key={index} className="accordian-item">
							<Accordion.Header className="accordian-header">{question}</Accordion.Header>
							<Accordion.Body>{answer}</Accordion.Body>
						</Accordion.Item>
					))}
				</Accordion>
				<br />
				<h3 className="title">Employer Questions</h3>
				<Accordion defaultActiveKey="" className="accordian">
					{employersQuestions.map(({question, answer}, index) => (
						<Accordion.Item eventKey={index.toString()} key={index} className="accordian-item">
							<Accordion.Header>{question}</Accordion.Header>
							<Accordion.Body>{answer}</Accordion.Body>
						</Accordion.Item>
					))}
				</Accordion>
				<div className="card-container">
					<div className="card">
						<h3>Need more help?</h3>
						<p>Get in touch and we will do our best to answer your queries</p>
						<Link to="/contactus">
							<button className="buttonGrey">Get in touch</button>
						</Link>
					</div>
					<div className="card">
						<h3>What we offer</h3>
						<p>Learn more about our services and how we can help you</p>
						<Link to="/pricing">
							<button className="buttonGrey">View our services</button>
						</Link>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
