import React, {useContext, useState, useRef, useEffect} from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import {UserContext} from '../../utils/UserContext';
import './EditProfile.scss';
import uploadCircle from '../../assets/images/uploadCircle.png';
import {Helmet} from 'react-helmet';

import {
	addEducationApi,
	addPortfolioApi,
	addSkillsApi,
	addWorkExperienceApi,
	deleteEducationApi,
	deletePortfolioApi,
	deleteSkillsApi,
	deleteWorkExperienceApi,
	editProfileApi,
	editProfileImage,
	getEducationApi,
	getPortfolioApi,
	getSkillsApi,
	getWorkExperienceApi,
	patchEducationApi,
	patchPortfolioApi,
	patchWorkExperienceApi,
} from '../../api/profileApi';
import Loading from '../../components/Loaders/Loading';
import {capitalizeFirstLetter, textFormatter} from '../../utils/TextAlterations';
import {allMonths, allYears} from '../../utils/arrayData/DateArrays';
import {countries} from '../../utils/arrayData/CountryArray';
import {categoryOptions, jobSkills, serviceType} from '../../utils/arrayData/PortfolioArrays';
import {formatDate} from '../../utils/DateFunctions';
import NewPasswordPopup from '../../components/AuthComponents/NewPasswordPopup';
import {Add, Cancel, Check, Close, Delete, Edit, Place, WorkRounded} from '@material-ui/icons';
import {capitalize} from '@mui/material';
import {Form} from 'react-bootstrap';
import {permColors} from '../../utils/colors';

export default function EditProfile() {
	const countryList = countries;
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [edit, setEdit] = useState(false);

	const fileInputRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [edit2, setEdit2] = useState(false);
	const [workExperience, setWorkExperience] = useState([]);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [country, setCountry] = useState('');
	const [freelancerProfileTitle, setFreelancerProfileTitle] = useState('');
	const [freelancerProfileDescription, setFreelancerProfileDescription] = useState('');

	const [companyName, setCompanyName] = useState();
	const [position, setPosition] = useState('');
	const [startMonth, setStartMonth] = useState('');
	const [startYear, setStartYear] = useState('');
	const [endMonth, setEndMonth] = useState('');
	const [endYear, setEndYear] = useState('');
	const [workDescription, setWorkDescription] = useState('');

	const [selectedWorkExperienceIndex, setSelectedWorkExperienceIndex] = useState(null);
	const months = allMonths;
	const years = allYears;
	const categories = categoryOptions;
	const services = serviceType;
	const skillsList = jobSkills;

	const [newJobForm, setNewJobForm] = useState(false);
	const [newCompanyName, setNewCompanyName] = useState('');
	const [newPosition, setNewPosition] = useState('');
	const [newStartMonth, setNewStartMonth] = useState('January');
	const [newStartYear, setNewStartYear] = useState('2023');
	const [newEndMonth, setNewEndMonth] = useState('January');
	const [newEndYear, setNewEndYear] = useState('2023');
	const [newWorkDescription, setNewWorkDescription] = useState('');
	const [newCountry, setNewCountry] = useState('Afghanistan');

	const [areaOfStudy, setAreaOfStudy] = useState('');
	const [educationQualification, setEducationQualification] = useState('');
	const [schoolName, setSchoolName] = useState('');
	const [educationYearFrom, setEducationYearFrom] = useState('');
	const [educationYearTo, setEducationYearTo] = useState('');
	const [educationDescription, setEducationDescription] = useState('');

	const [education, setEducation] = useState([]);
	const [newEducationForm, setNewEducationForm] = useState(false);
	const [newAreaOfStudy, setNewAreaOfStudy] = useState('');
	const [newEducationQualification, setNewEducationQualification] = useState('');
	const [newSchoolName, setNewSchoolName] = useState('');
	const [newEducationYearFrom, setNewEducationYearFrom] = useState('2023');
	const [newEducationYearTo, setNewEducationYearTo] = useState('2023');
	const [newEducationDescription, setNewEducationDescription] = useState('');
	const [edit3, setEdit3] = useState(false);
	const [selectedEducationIndex, setSelectedEducationIndex] = useState(null);

	const [editPortfolio, setEditPortfolio] = useState(false);
	const [portfolio, setPortfolio] = useState([]);
	const [newPortfolioForm, setNewPortfolioForm] = useState(false);
	const [selectedPortfolioIndex, setSelectedPortfolioIndex] = useState(null);

	const [portfolioTitle, setPortfolioTitle] = useState('');
	const [portfolioDescription, setPortfolioDescription] = useState('');
	const [portfolioCompletetionDate, setPortfolioCompletionDate] = useState('');
	const [portfolioCategory, setPortfolioCategory] = useState('');
	const [portfolioServiceType, setPortfolioServiceType] = useState('');

	const [newPortfolioTitle, setNewPortfolioTitle] = useState('');
	const [newPortfolioDescription, setNewPortfolioDescription] = useState('');
	const [newPortfolioCompletetionDate, setNewPortfolioCompletionDate] = useState('');
	const [newPortfolioCategory, setNewPortfolioCategory] = useState('');
	const [newPortfolioServiceType, setNewPortfolioServiceType] = useState('');

	const [skills, setSkills] = useState([]);
	const [newSkillName, setNewSkillName] = useState('');
	const [skillsEdit, setSkillsEdit] = useState(false);
	const [skillsError, setSkillsError] = useState(false);

	const [popup, setPopup] = useState(false);
	const resetPasswordButton = () => {
		openPopup();
	};
	function openPopup() {
		setPopup(true);
	}
	function closePopup() {
		setPopup(false);
	}

	useEffect(() => {
		if (currentUser?.profile) {
			setFirstName(currentUser?.profile?.first_name);
			setLastName(currentUser?.profile?.last_name);
			setCountry(currentUser?.profile?.country);
			setFreelancerProfileTitle(currentUser?.profile?.freelancer_profile_title);
			setFreelancerProfileDescription(currentUser?.profile?.freelancer_profile_description);
		}
	}, [currentUser]);

	useEffect(() => {
		if (currentUser?.profile) {
			async function getProfile() {
				setLoading(true);
				const res = await getWorkExperienceApi();
				const res2 = await getEducationApi();
				const res3 = await getPortfolioApi();
				const res4 = await getSkillsApi();
				setSkills(res4);
				setWorkExperience(res);
				setEducation(res2);
				setPortfolio(res3);
				setLoading(false);
				console.log(res);
				console.log(res2);
				console.log(res3);
			}
			getProfile();
		}
	}, [currentUser]);

	useEffect(() => {
		if (selectedEducationIndex !== null && education) {
			setAreaOfStudy(education[selectedEducationIndex]?.area_of_study || '');
			setEducationQualification(education[selectedEducationIndex]?.education_qualification || 'High school');
			setSchoolName(education[selectedEducationIndex]?.school_name || '');
			setEducationYearFrom(education[selectedEducationIndex]?.year_from || '');
			setEducationYearTo(education[selectedEducationIndex]?.year_to || '');
			setEducationDescription(education[selectedEducationIndex]?.description || '');
		}
	}, [selectedEducationIndex, education]);

	useEffect(() => {
		if (selectedWorkExperienceIndex !== null && workExperience) {
			setCompanyName(workExperience[selectedWorkExperienceIndex]?.company_name || '');
			setPosition(workExperience[selectedWorkExperienceIndex]?.position_title || '');
			setStartMonth(workExperience[selectedWorkExperienceIndex]?.from_month || '');
			setStartYear(workExperience[selectedWorkExperienceIndex]?.from_year || '');
			setEndMonth(workExperience[selectedWorkExperienceIndex]?.to_month || '');
			setEndYear(workExperience[selectedWorkExperienceIndex]?.to_year || '');
			setWorkDescription(workExperience[selectedWorkExperienceIndex]?.description || '');
		}
	}, [selectedWorkExperienceIndex, workExperience]);

	useEffect(() => {
		if (selectedPortfolioIndex !== null && portfolio) {
			setPortfolioTitle(portfolio[selectedPortfolioIndex]?.title || '');
			setPortfolioDescription(portfolio[selectedPortfolioIndex]?.description || '');
			setPortfolioCompletionDate(portfolio[selectedPortfolioIndex]?.completion_date || '');
			setPortfolioCategory(portfolio[selectedPortfolioIndex]?.category || '');
			setPortfolioServiceType(portfolio[selectedPortfolioIndex]?.service_type || '');
		}
	}, [selectedPortfolioIndex, portfolio]);

	async function addWorkExperience(e) {
		e.preventDefault();
		const res = await addWorkExperienceApi(newCompanyName, newPosition, newStartMonth, newStartYear, newEndMonth, newEndYear, newCountry, newWorkDescription, currentUser?.profile?.id);
		setNewJobForm(false);
		console.log(res);
		getWorkExperience();
	}

	async function addEducation(e) {
		e.preventDefault();
		const res = await addEducationApi(newAreaOfStudy, newEducationQualification, newSchoolName, newEducationYearFrom, newEducationYearTo, newEducationDescription);
		setNewEducationForm(false);
		console.log(res);
		getEducation();
	}

	async function addPortfolio(e) {
		e.preventDefault();
		const res = await addPortfolioApi(newPortfolioTitle, newPortfolioDescription, newPortfolioServiceType, newPortfolioCompletetionDate, newPortfolioCategory);
		setNewPortfolioForm(false);
		console.log(res);
		getPortfolio();
	}

	async function addSkills() {
		if (skills.some((skill) => skill.name === newSkillName)) {
			console.log('Skill already exists in the array.');
			setSkillsError(true);
		} else {
			setSkillsError(false);
			const res = await addSkillsApi(newSkillName);
			console.log(res);
			getSkills();
		}
	}

	async function getEducation() {
		const res = await getEducationApi();
		setEducation(res);
	}

	async function getWorkExperience() {
		const res = await getWorkExperienceApi();
		setWorkExperience(res);
	}

	async function getPortfolio() {
		const res = await getPortfolioApi();
		setPortfolio(res);
	}

	async function getSkills() {
		const res = await getSkillsApi();
		console.log(res);
		setSkills(res);
	}

	const handleImageChange = async (e) => {
		if (e.target.files && e.target.files[0]) {
			setLoading(true);
			const res = await editProfileImage(e.target.files[0]);
			console.log(res);
			window.location.reload();
		}
	};
	async function updateProfile(e) {
		e.preventDefault();
		try {
			const res = await editProfileApi(firstName, lastName, country, freelancerProfileTitle, freelancerProfileDescription);
			setEdit(false);
			console.log(res);
			window.location.reload();
		} catch (err) {
			console.log(err);
		}
	}

	async function patchWorkExperience(e) {
		e.preventDefault();
		try {
			const res = await patchWorkExperienceApi(workExperience[selectedWorkExperienceIndex].id, companyName, position, startMonth, startYear, endMonth, endYear, workDescription);
			setEdit2(false);
			console.log(res);
			setSelectedWorkExperienceIndex(null);
			getWorkExperience();
		} catch (err) {
			console.log(err);
		}
	}

	async function patchEducation(e) {
		e.preventDefault();
		try {
			const res = await patchEducationApi(education[selectedEducationIndex].id, areaOfStudy, educationQualification, schoolName, educationYearFrom, educationYearTo, educationDescription);
			setEdit3(false);
			console.log(res);
			setSelectedEducationIndex(null);
			getEducation();
		} catch (err) {
			console.log(err);
		}
	}

	async function patchPortfolio(e) {
		e.preventDefault();
		try {
			const res = await patchPortfolioApi(portfolio[selectedPortfolioIndex].id, portfolioTitle, portfolioDescription, portfolioServiceType, portfolioCompletetionDate, portfolioCategory);
			setEditPortfolio(false);
			console.log(res);
			setSelectedPortfolioIndex(null);
			getPortfolio();
		} catch (err) {
			console.log(err);
		}
	}

	async function deleteWorkExperience(id) {
		try {
			const res = await deleteWorkExperienceApi(id);
			console.log(res);
			getWorkExperience();
		} catch (err) {
			console.log(err);
		}
	}

	async function deleteEducation(id) {
		try {
			const res = await deleteEducationApi(id);
			console.log(res);
			getEducation();
		} catch (err) {
			console.log(err);
		}
	}

	async function deletePortfolio(id) {
		try {
			const res = await deletePortfolioApi(id);
			console.log(res);
			getPortfolio();
		} catch (err) {
			console.log(err);
		}
	}

	async function deleteSkills(id) {
		try {
			const res = await deleteSkillsApi(id);
			console.log(res);
			getSkills();
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<div className="edit-profile-container">
			<Helmet>
				<title>Edit Profile | Permworks</title>
				<meta name="description" content="Update your Permworks profile. Keep your skills, experience, and other details up to date." />
			</Helmet>
			<Navbar />
			<div className="edit-profile-outer">
				<h1 className="md:text-left py-1 text-2xl font-bold">Profile</h1>

				<div className="editProfileContainer">
					<div className="left-side">
						<div className="container">
							<Form.Control type="file" ref={fileInputRef} style={{display: 'none'}} onChange={(e) => handleImageChange(e)} />
							<img
								src={currentUser?.profile?.profile_image ? currentUser?.profile?.profile_image : uploadCircle}
								alt="Circle profile"
								className="circle-image"
								onClick={() => fileInputRef.current.click()}
							/>
							{!edit && (
								<div>
									<h4>
										{capitalize(currentUser?.profile?.first_name)} {capitalize(currentUser?.profile?.last_name)}
									</h4>
									<h5 className="flex gap-2 items-center">
										<Place style={{fontSize: '1.2em'}} /> {currentUser?.profile?.country ?? 'Unknown'}
									</h5>{' '}
									<h5 className="flex gap-2 items-center">
										<WorkRounded style={{fontSize: '1.2em'}} /> {currentUser?.profile?.freelancer_profile_title ?? 'No title'}
									</h5>{' '}
									<h5>{currentUser?.profile?.freelancer_profile_description ?? 'No description'}</h5>
									<button className="buttonLinkBlue w-full flex items-center gap-2 justify-center" onClick={() => setEdit(true)}>
										<Edit /> Edit
									</button>
									{'  '}
									{currentUser?.auth_provider === 'email' && (
										<button className="buttonRedSmall" onClick={resetPasswordButton}>
											Change Password
										</button>
									)}
								</div>
							)}
							{edit && (
								<form onSubmit={updateProfile}>
									<div className="edit-container">
										<Form.Group className="mb-2">
											<Form.Label>First Name</Form.Label>
											<Form.Control
												type="text"
												placeholder="First Name"
												defaultValue={currentUser?.profile?.first_name}
												onChange={(e) => setFirstName(e.target.value)}
												required
											/>
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Last Name</Form.Label>
											<Form.Control type="text" placeholder="Last Name" defaultValue={currentUser?.profile?.last_name} onChange={(e) => setLastName(e.target.value)} required />
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Country</Form.Label>
											<Form.Select name="countries" id="countries" defaultValue={currentUser?.profile?.country} onChange={(e) => setCountry(e.target.value)} required>
												<option value="">Select country</option>
												{countryList.map((country, index) => (
													<option key={index} value={country}>
														{country}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Freelancer Profile Title</Form.Label>
											<Form.Control
												type="text"
												placeholder="Freelancer Profile Title"
												defaultValue={currentUser?.profile?.freelancer_profile_title}
												onChange={(e) => setFreelancerProfileTitle(e.target.value)}
												required
											/>
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Freelancer Profile Description</Form.Label>
											<Form.Control
												as="textarea"
												rows={6}
												type="text"
												placeholder="Freelancer Profile Description"
												defaultValue={currentUser?.profile?.freelancer_profile_description}
												onChange={(e) => setFreelancerProfileDescription(e.target.value)}
												required
											/>
										</Form.Group>

										<div className="buttons">
											<button type="submit" className="fake-button" style={{color: 'grey'}}>
												<Check className="edit-icon" />
											</button>
											<div onClick={() => setEdit(false)}>
												<Close className="edit-icon" />
											</div>
										</div>
									</div>
								</form>
							)}
						</div>

						{/*  Skills Tags =====================================================================================  */}

						<div className="container">
							<h3 className="flex items-center gap-1">
								Skills <Add className="plusIcon" onClick={() => setSkillsEdit(true)} />
							</h3>

							<div>
								{skills?.map((skill, index) => {
									return (
										<div className="skill" key={index}>
											<p>
												{skill?.name} <Cancel onClick={() => deleteSkills(skill.id)} className="icon" />
											</p>
										</div>
									);
								})}
								{skillsError && <p className="error">Skill tag already exist</p>}
							</div>
							{skillsEdit && (
								<div className="edit-container">
									<Form.Select name="skills" id="skills" placeholder="Select skill" onChange={(e) => setNewSkillName(e.target.value)}>
										<option value="" disabled selected>
											Select skill
										</option>
										{skillsList.map((skill, index) => {
											return (
												<option key={index} value={skill}>
													{skill}
												</option>
											);
										})}
									</Form.Select>
									<div className="buttons">
										<div onClick={addSkills}>
											<Check className="edit-icon" />
										</div>{' '}
										<div
											onClick={() => {
												setSkillsEdit(false);
												setSkillsError(false);
											}}>
											<Close className="edit-icon" />
										</div>
									</div>
								</div>
							)}
						</div>
					</div>

					{/*  Portfolio =====================================================================================  */}

					<div className="right-side">
						<div className="container">
							<h3 className="flex items-center gap-1">
								Portfolio
								<Add className="plusIcon" onClick={() => setNewPortfolioForm(true)} />
							</h3>
							{newPortfolioForm && (
								<div className="edit-container">
									<form onSubmit={addPortfolio}>
										<Form.Group className="mb-2">
											<Form.Label>Portfolio Title</Form.Label>
											<Form.Control type="text" placeholder="Portfolio title" onChange={(e) => setNewPortfolioTitle(e.target.value)} required />
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Portfolio Description</Form.Label>
											<Form.Control type="text" placeholder="Portfolio description" onChange={(e) => setNewPortfolioDescription(e.target.value)} required />
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Service Type</Form.Label>
											<Form.Select name="serviceType" id="serviceType" onChange={(e) => setNewPortfolioServiceType(e.target.value)} required>
												<option value="">Select service type</option>
												{services.map((serviceType, index) => (
													<option key={index} value={serviceType}>
														{serviceType}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Portfolio Category</Form.Label>
											<Form.Select name="portfolioCategory" id="portfolioCategory" onChange={(e) => setNewPortfolioCategory(e.target.value)} required>
												<option value="">Select portfolio category</option>
												{categories.map((portfolioCategory, index) => (
													<option key={index} value={portfolioCategory}>
														{portfolioCategory}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Completion Date</Form.Label>
											<Form.Control type="date" placeholder="Completion date" onChange={(e) => setNewPortfolioCompletionDate(e.target.value)} required />
										</Form.Group>

										<div className="buttons">
											<button type="submit">Save</button>
											<button className="buttonRedSmall" onClick={() => setNewPortfolioForm(false)}>
												Cancel
											</button>
										</div>
									</form>
								</div>
							)}
							{!editPortfolio &&
								portfolio?.map((work, index) => {
									return (
										<div className="workExperience" key={index}>
											<h4>{work?.title}</h4>
											<h5>{work?.description}</h5>
											<h5>{work?.service_type}</h5>
											<h5>{work?.category}</h5>
											<h5>{work && `Completed on the ${formatDate(work?.completion_date)}`}</h5>
											<div className="buttons">
												<Edit
													className="edit-icon"
													onClick={() => {
														setSelectedPortfolioIndex(index);
														setEditPortfolio(true);
													}}
												/>
												<Delete className="edit-icon" onClick={() => deletePortfolio(work?.id)} />
											</div>
										</div>
									);
								})}
							{editPortfolio && (
								<div>
									{selectedPortfolioIndex !== null && (
										<form onSubmit={patchPortfolio}>
											<div className="edit-container" key={selectedPortfolioIndex}>
												<Form.Group className="mb-2">
													<Form.Label>Portfolio Title</Form.Label>
													<Form.Control
														type="text"
														placeholder="Portfolio title"
														defaultValue={portfolio[selectedPortfolioIndex]?.title}
														onChange={(e) => setPortfolioTitle(e.target.value)}
														required
													/>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Portfolio Description</Form.Label>
													<Form.Control
														type="text"
														placeholder="Portfolio description"
														defaultValue={portfolio[selectedPortfolioIndex]?.description}
														onChange={(e) => setPortfolioDescription(e.target.value)}
														required
													/>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Service Type</Form.Label>
													<Form.Select
														name="serviceType"
														id="serviceType"
														defaultValue={portfolio[selectedPortfolioIndex]?.service_type}
														onChange={(e) => setPortfolioServiceType(e.target.value)}
														required>
														<option value="">Select service type</option>
														{services.map((serviceType, index) => (
															<option key={index} value={serviceType}>
																{serviceType}
															</option>
														))}
													</Form.Select>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Portfolio Category</Form.Label>
													<Form.Select
														name="portfolioCategory"
														id="portfolioCategory"
														defaultValue={portfolio[selectedPortfolioIndex]?.category}
														onChange={(e) => setPortfolioCategory(e.target.value)}
														required>
														<option value="">Select portfolio category</option>
														{categories.map((portfolioCategory, index) => (
															<option key={index} value={portfolioCategory}>
																{portfolioCategory}
															</option>
														))}
													</Form.Select>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Completion Date</Form.Label>
													<Form.Control
														type="date"
														placeholder="Completion date"
														defaultValue={portfolio[selectedPortfolioIndex]?.completion_date.split('T')[0]}
														onChange={(e) => setPortfolioCompletionDate(e.target.value)}
														required
													/>
												</Form.Group>

												<div className="buttons">
													<button type="submit">Save</button>
													<button
														className="buttonRedSmall"
														onClick={() => {
															setEditPortfolio(false);
															setSelectedWorkExperienceIndex(null);
														}}>
														Cancel
													</button>
												</div>
											</div>
										</form>
									)}
								</div>
							)}
						</div>

						{/*  Work experience ========================================================================== */}

						<div className="container">
							<h3 className="flex items-center gap-1">
								Work Experience
								<Add className="plusIcon" onClick={() => setNewJobForm(true)} />
							</h3>
							{newJobForm && (
								<div className="edit-container">
									<form onSubmit={addWorkExperience}>
										<Form.Group className="mb-2">
											<Form.Label>Company name</Form.Label>
											<Form.Control type="text" placeholder="Company name" onChange={(e) => setNewCompanyName(e.target.value)} required />
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Position</Form.Label>
											<Form.Control type="text" placeholder="Position" onChange={(e) => setNewPosition(e.target.value)} required />
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Start date</Form.Label>
											<Form.Select name="startMonth" id="startMonth" onChange={(e) => setNewStartMonth(e.target.value)} required>
												<option value="">Select month</option>
												{months.map((month, index) => (
													<option key={index} value={month}>
														{month}
													</option>
												))}
											</Form.Select>
											<Form.Select name="startYear" id="startYear" onChange={(e) => setNewStartYear(e.target.value)} className="mt-2">
												<option value="">Select year</option>
												{years.map((year, index) => (
													<option key={index} value={year}>
														{year}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>End date</Form.Label>
											<Form.Select name="endMonth" id="endMonth" onChange={(e) => setNewEndMonth(e.target.value)}>
												<option value="">Select month</option>
												{months.map((month, index) => (
													<option key={index} value={month}>
														{month}
													</option>
												))}
											</Form.Select>
											<Form.Select name="endYear" id="endYear" onChange={(e) => setNewEndYear(e.target.value)} className="mt-2">
												<option value="">Select year</option>
												{years.map((year, index) => (
													<option key={index} value={year}>
														{year}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Country</Form.Label>
											<Form.Select name="countries" id="countries" onChange={(e) => setNewCountry(e.target.value)}>
												<option value="">Select country</option>
												{countryList.map((country, index) => (
													<option key={index} value={country}>
														{country}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Description</Form.Label>
											<Form.Control as="textarea" rows={6} name="description" id="description" onChange={(e) => setNewWorkDescription(e.target.value)} />
										</Form.Group>

										<div className="buttons">
											<button type="submit">Save</button>
											<button className="buttonRedSmall" onClick={() => setNewJobForm(false)}>
												Cancel
											</button>
										</div>
									</form>
								</div>
							)}

							{!edit2 &&
								workExperience?.map((work, index) => {
									return (
										<div className="workExperience" key={index}>
											<h4>
												{' '}
												<strong>Company name - </strong>
												{capitalizeFirstLetter(work?.company_name)}
											</h4>
											<h5>
												{' '}
												<strong>Position - </strong>
												{capitalizeFirstLetter(work?.position_title)}
											</h5>
											<h5>{work?.country}</h5>
											<h5>
												{work?.from_year} {work?.from_month} -{work?.to_year} {work?.to_month}
											</h5>
											<p
												className="description"
												dangerouslySetInnerHTML={{
													__html: textFormatter(work?.description),
												}}
											/>
											<div className="buttons">
												<Edit
													className="edit-icon"
													onClick={() => {
														setSelectedWorkExperienceIndex(index);
														setEdit2(true);
													}}
												/>
												<Delete className="edit-icon" onClick={() => deleteWorkExperience(work?.id)} />
											</div>
										</div>
									);
								})}
							{edit2 && (
								<div>
									{selectedWorkExperienceIndex !== null && (
										<div className="edit-container" key={selectedWorkExperienceIndex}>
											{/* Create inputs for all the fields */}
											<form onSubmit={patchWorkExperience}>
												<Form.Group className="mb-2">
													<Form.Label>Company name</Form.Label>
													<Form.Control
														type="text"
														placeholder="Company name"
														defaultValue={workExperience[selectedWorkExperienceIndex]?.company_name}
														onChange={(e) => setCompanyName(e.target.value)}
														required
													/>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Position title</Form.Label>
													<Form.Control
														type="text"
														placeholder="Position title"
														defaultValue={workExperience[selectedWorkExperienceIndex]?.position_title}
														onChange={(e) => setPosition(e.target.value)}
														required
													/>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Country</Form.Label>
													<Form.Select
														name="countries"
														id="countries"
														required
														onChange={(e) => setCountry(e.target.value)}
														defaultValue={workExperience[selectedWorkExperienceIndex]?.country}>
														{countryList.map((country, index) => (
															<option key={index} value={country}>
																{country}
															</option>
														))}
													</Form.Select>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Month from</Form.Label>
													<Form.Select defaultValue={workExperience[selectedWorkExperienceIndex]?.from_month} required onChange={(e) => setStartMonth(e.target.value)}>
														{allMonths.map((month, index) => (
															<option key={index} value={month}>
																{month}
															</option>
														))}
													</Form.Select>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Year from</Form.Label>
													<Form.Select
														name="startYear"
														id="startYear"
														required
														onChange={(e) => setStartYear(e.target.value)}
														defaultValue={workExperience[selectedWorkExperienceIndex]?.from_year}>
														{years.map((year, index) => (
															<option key={index} value={year}>
																{year}
															</option>
														))}
													</Form.Select>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Month to</Form.Label>
													<Form.Select required defaultValue={workExperience[selectedWorkExperienceIndex]?.to_month} onChange={(e) => setEndMonth(e.target.value)}>
														{allMonths.map((month, index) => (
															<option key={index} value={month}>
																{month}
															</option>
														))}
													</Form.Select>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Year to</Form.Label>
													<Form.Select
														name="endYear"
														id="endYear"
														required
														onChange={(e) => setEndYear(e.target.value)}
														defaultValue={workExperience[selectedWorkExperienceIndex]?.to_year}>
														{years.map((year, index) => (
															<option key={index} value={year}>
																{year}
															</option>
														))}
													</Form.Select>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Work description</Form.Label>
													<Form.Control
														as="textarea"
														rows={6}
														required
														placeholder="Work description"
														defaultValue={workExperience[selectedWorkExperienceIndex]?.description}
														onChange={(e) => setWorkDescription(e.target.value)}
													/>
												</Form.Group>

												<div className="buttons">
													<button type="submit">Save</button>
													<button
														className="buttonRedSmall"
														onClick={() => {
															setEdit2(false);
															setSelectedWorkExperienceIndex(null);
														}}>
														Cancel
													</button>
												</div>
											</form>
										</div>
									)}
								</div>
							)}
						</div>

						{/* education--------------------------- ---------- -------- */}

						<div className="container">
							<h3 className="flex items-center gap-1">
								Education
								<Add className="plusIcon" onClick={() => setNewEducationForm(true)} />
							</h3>
							{newEducationForm && (
								<div className="edit-container">
									<form onSubmit={addEducation}>
										<Form.Group className="mb-2">
											<Form.Label>Area of study</Form.Label>
											<Form.Control type="text" placeholder="Area of study" onChange={(e) => setNewAreaOfStudy(e.target.value)} required />
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Education level</Form.Label>
											<Form.Select name="educationLevel" id="educationLevel" onChange={(e) => setNewEducationQualification(e.target.value)} required>
												<option value="">Select education level</option>
												<option value="High school">High school</option>
												<option value="Bachelor">Bachelor</option>
												<option value="Master">Master</option>
												<option value="PhD">PhD</option>
											</Form.Select>
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>School name</Form.Label>
											<Form.Control type="text" placeholder="School name" onChange={(e) => setNewSchoolName(e.target.value)} required />
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Year from</Form.Label>
											<Form.Select name="startYear" id="startYear" onChange={(e) => setNewEducationYearFrom(e.target.value)} required>
												<option value="">Select year</option>
												{years.map((year, index) => (
													<option key={index} value={year}>
														{year}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Year to</Form.Label>
											<Form.Select name="endYear" id="endYear" onChange={(e) => setNewEducationYearTo(e.target.value)} required>
												<option value="">Select year</option>
												{years.map((year, index) => (
													<option key={index} value={year}>
														{year}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group className="mb-2">
											<Form.Label>Education description</Form.Label>
											<Form.Control as="textarea" rows={6} placeholder="Education description" onChange={(e) => setNewEducationDescription(e.target.value)} required />
										</Form.Group>

										<div className="buttons">
											<button type="submit">Save</button>
											<button className="buttonRedSmall" onClick={() => setNewEducationForm(false)}>
												Cancel
											</button>
										</div>
									</form>
								</div>
							)}

							{!edit3 && (
								<div>
									{education?.map((item, index) => {
										return (
											<div className="workExperience" key={index}>
												<h4>
													<strong>Area of study - </strong>
													{item?.area_of_study && capitalizeFirstLetter(item?.area_of_study)}
												</h4>
												<h5>
													{' '}
													<strong>Institution - </strong>
													{item?.school_name && capitalizeFirstLetter(item?.school_name)}
												</h5>
												<h5>{item?.education_qualification}</h5>
												<h5>
													{item?.year_from} - {item?.year_to}
												</h5>
												<p
													className="description"
													dangerouslySetInnerHTML={{
														__html: textFormatter(item?.description),
													}}
												/>
												<div className="buttons">
													<Edit
														className="edit-icon"
														onClick={() => {
															setSelectedEducationIndex(index);
															setEdit3(true);
														}}
													/>
													<Delete className="edit-icon" onClick={() => deleteEducation(item?.id)} />
												</div>
											</div>
										);
									})}
								</div>
							)}
							{edit3 && (
								<div>
									{selectedEducationIndex !== null && (
										<div className="edit-container" key={selectedEducationIndex}>
											<form onSubmit={patchEducation}>
												<Form.Group className="mb-2">
													<Form.Label>Area of study / Course name</Form.Label>
													<Form.Control
														type="text"
														placeholder="Area of study / Course name"
														defaultValue={education[selectedEducationIndex]?.area_of_study}
														onChange={(e) => setAreaOfStudy(e.target.value)}
														required
													/>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Education level</Form.Label>
													<Form.Select
														defaultValue={education[selectedEducationIndex]?.education_qualification}
														onChange={(e) => setEducationQualification(e.target.value)}
														required>
														<option value="">Select education level</option>
														<option value="High school">High school</option>
														<option value="Bachelor">Bachelor</option>
														<option value="Master">Master</option>
														<option value="PhD">PhD</option>
													</Form.Select>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>School name</Form.Label>
													<Form.Control
														type="text"
														placeholder="School name"
														defaultValue={education[selectedEducationIndex]?.school_name}
														onChange={(e) => setSchoolName(e.target.value)}
														required
													/>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Year from</Form.Label>
													<Form.Select
														name="startYear"
														id="startYear"
														defaultValue={education[selectedEducationIndex]?.year_from}
														onChange={(e) => setEducationYearFrom(e.target.value)}
														required>
														<option value="">Select year</option>
														{years.map((year, index) => (
															<option key={index} value={year}>
																{year}
															</option>
														))}
													</Form.Select>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Year to</Form.Label>
													<Form.Select
														name="endYear"
														id="endYear"
														defaultValue={education[selectedEducationIndex]?.year_to}
														onChange={(e) => setEducationYearTo(e.target.value)}
														required>
														<option value="">Select year</option>
														{years.map((year, index) => (
															<option key={index} value={year}>
																{year}
															</option>
														))}
													</Form.Select>
												</Form.Group>

												<Form.Group className="mb-2">
													<Form.Label>Education description</Form.Label>
													<Form.Control
														as="textarea"
														rows={6}
														placeholder="Education description"
														defaultValue={education[selectedEducationIndex]?.description}
														onChange={(e) => setEducationDescription(e.target.value)}
														required
													/>
												</Form.Group>

												<div className="buttons">
													<button type="submit">Save</button>
													<button
														className="buttonRedSmall"
														onClick={() => {
															setEdit3(false);
															setSelectedEducationIndex(null);
														}}>
														Cancel
													</button>
												</div>
											</form>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<NewPasswordPopup showPopup={popup} closePopup={closePopup} />

			{loading && <Loading />}
			<Footer />
		</div>
	);
}
