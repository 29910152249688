import React, {useContext, useState, useEffect} from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import {Helmet} from 'react-helmet';
import {useNavigate} from 'react-router-dom';
import {Card, CardHeader, CardActionArea, CardMedia, CardContent, Typography, Avatar} from '@mui/material';
import './BlogIndex.scss';
import {BlogContext, getBlogInfoApi} from '../../utils/arrayData/blogData';
import {formatDate2} from '../../utils/DateFunctions';
import Loading from '../../components/Loaders/Loading';
import {BlogDataContext} from '../../utils/blog/BlogDataProvider';

export default function BlogIndex() {
	const navigate = useNavigate();
	const [blogInfo, setBlogInfo] = useContext(BlogDataContext);

	return (
		<div className="blog-container">
			<Helmet>
				<title>The Permworks Blog</title>
				<meta name="description" content="Learn about all things relating to hiring talent from the Philippines. Discover insights, tips, and more on the Permworks Blog." />
			</Helmet>
			<Navbar />

			<div className="blogIndex">
				<div className="cardBackground">
					<div className="cardContainer">
						<h1 className="h1">The Permworks Blog</h1>
						<h2 className="h2">Learn about all things relating to hiring talent from the Philippines.</h2>

						<div className="cardHolder">
							{blogInfo?.map((blog) => (
								<Card
									onClick={() => navigate(`/${blog.url}`)}
									sx={{maxWidth: 300, width: 300, textAlign: 'left', minWidth: '300px', flexShrink: '0'}}
									className="blogIndexCard"
									key={blog.id}>
									<CardActionArea style={{}}>
										<CardHeader
											avatar={
												<Avatar sx={{bgcolor: '#ef4565'}} aria-label="recipe">
													{blog?.created_by?.charAt(0) ?? 'N'}
												</Avatar>
											}
											title={blog.created_by ?? 'No name'}
											subheader={formatDate2(blog.created_at) ?? 'No date'}
										/>
										<CardMedia component="img" height="194" alt={blog.alt ?? 'noImage'} image={blog?.image} />
										<CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: '1'}}>
											<Typography gutterBottom variant="h5" component="h2">
												{blog.title ?? 'No title'}
											</Typography>
											<Typography variant="body2" color="textSecondary" component="p">
												{blog.description ?? 'No description'}
											</Typography>
										</CardContent>
									</CardActionArea>
								</Card>
							))}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
