import React, {useState, useEffect, useContext} from 'react';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import './ContractPage.scss';
import {useParams} from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Card from 'react-bootstrap/Card';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Form from 'react-bootstrap/Form';
import {entityType, seniorityLevels} from '../../utils/arrayData/ContractData';
import {countries} from '../../utils/arrayData/CountryArray';
import ListGroup from 'react-bootstrap/ListGroup';
import {FiX} from 'react-icons/fi';
import {Alert} from '@mui/material';
import {createContractAndAcceptJobOfferApi} from '../../api/contractApi';
import {MuiTelInput} from 'mui-tel-input';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';

export default function ContractPage() {
	const [activeStep, setActiveStep] = React.useState(0);
	const entities = entityType;
	const countryList = countries;
	const senorities = seniorityLevels;
	const {applicationId} = useParams('applicationId');
	const [open, setOpen] = React.useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [phone, setPhone] = React.useState('');
	const navigate = useNavigate();
	const [formState, setFormState] = useState({
		legalEntityName: '',
		entityType: '',
		legalRegistrationNumber: '',
		vatId: '',
		phoneNumber: '',
		businessAddress: '',

		employeeFirstName: '',
		employeeLastName: '',
		employeeEmail: '',
		employeeNationality: '',
		employeeCountry: '',

		jobTitle: '',
		seniorityLevel: '',
		scopeOfWork: '',

		employeeStartDate: '',
		annualBaseSalary: '',
		addSigningBonus: false,
		grossSigningBonus: '',
		addAnnualVariableCompensation: false,
		variableComponsationType: '',
		compensationAmount: '',

		addFixedAllowance: false,
		allowancePaymentType: '',
		allowanceAmount: '',
		allowanceDescription: '',

		allowanceArray: [],

		employmentType: '',
		partTimeHours: '',
		timeOffType: '',
		timeOffDays: 5,

		contractTermType: '',
		contractEndDate: null,
		probationPeriod: 5,
		addHealthInsurance: false,
	});

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const handleChange = (event) => {
		const {name, type, value, checked} = event.target;
		const inputValue = type === 'checkbox' ? checked : value;
		console.log(`Input "${name}" updated:`, inputValue);
		setFormState((prevState) => ({...prevState, [name]: inputValue}));
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSubmit = async () => {
		if (validateForm()) {
			const contractData = {
				legal_entity_name: formState.legalEntityName,
				entity_type: formState.entityType,
				legal_registration_number: formState.legalRegistrationNumber,
				vat_id: formState.vatId,
				phone_number: phone,
				business_address: formState.businessAddress,
				employee_first_name: formState.employeeFirstName,
				employee_last_name: formState.employeeLastName,
				employee_email: formState.employeeEmail,
				employee_natinality: formState.employeeNationality,
				employee_country: formState.employeeCountry,
				job_title: formState.jobTitle,
				seniority_level: formState.seniorityLevel,
				scope_of_work: formState.scopeOfWork,
				start_date: formState.employeeStartDate,
				annual_base_salary: formState.annualBaseSalary,
				add_signing_bonus: formState.addSigningBonus,
				gross_signing_bonus: formState.grossSigningBonus === '' ? null : formState.grossSigningBonus,
				add_annual_variable_compensation: formState.addAnnualVariableCompensation,
				variable_componsation_type: formState.variableComponsationType,
				variable_compensation_amount: formState.compensationAmount === '' ? null : formState.compensationAmount,
				add_fixed_allowance: formState.addFixedAllowance,
				employment_type: formState.employmentType,
				part_time_hours: formState.partTimeHours,
				time_off_type: formState.timeOffType,
				time_off_days: formState.timeOffDays,
				contract_term_type: formState.contractTermType,
				contract_end_date: formState.contractEndDate,
				probation_period_days: formState.probationPeriod,
				add_health_insurance: formState.addHealthInsurance,
			};
			const allowanceArray = formState.allowanceArray;
			const jobOfferId = applicationId;

			try {
				const responseData = await createContractAndAcceptJobOfferApi(contractData, allowanceArray, jobOfferId);
				console.log(responseData);
				setOpen(true);
				navigate('/contract-success');
				// Handle the response data as needed
			} catch (error) {
				console.error(error);
				// Handle the error as needed
			}
		}
	};

	const hanldePhone = (newPhone) => {
		setPhone(newPhone);
	};

	const addAllowance = () => {
		const allowance = {
			type: formState.allowancePaymentType,
			amount: formState.allowanceAmount,
			description: formState.allowanceDescription,
		};
		setFormState((prevState) => ({
			...prevState,
			allowanceArray: [...prevState.allowanceArray, allowance],
			allowancePaymentType: '',
			allowanceAmount: '',
			allowanceDescription: '',
		}));
	};

	const removeAllowance = (index) => {
		setFormState((prevState) => {
			const updatedAllowances = prevState.allowanceArray.filter((_, i) => i !== index);
			return {
				...prevState,
				allowanceArray: updatedAllowances,
			};
		});
	};

	function handleBlur(event) {
		const min = 5;
		if (parseInt(event.target.value) < min) {
			const newValue = min;
			handleChange({target: {name: event.target.name, value: newValue}});
		}
	}

	const validateForm = () => {
		let missingFields = [];

		if (!formState.legalEntityName) missingFields.push(' legal entity name');
		if (!formState.entityType) missingFields.push(' entity type');
		if (!formState.legalRegistrationNumber) missingFields.push(' legal registration number');
		if (!formState.vatId) missingFields.push(' vat id');
		if (!phone) missingFields.push(' phone number');
		if (!formState.businessAddress) missingFields.push(' business address');
		if (!formState.employeeFirstName) missingFields.push(' employee first name');
		if (!formState.employeeLastName) missingFields.push(' employee last name');
		if (!formState.employeeEmail) missingFields.push(' employee email');
		if (!formState.employeeNationality) missingFields.push(' employee nationality');
		if (!formState.employeeCountry) missingFields.push(' employee country');
		if (!formState.jobTitle) missingFields.push(' job title');
		if (!formState.seniorityLevel) missingFields.push(' seniority level');
		if (!formState.scopeOfWork) missingFields.push(' scope of work');
		if (!formState.employeeStartDate) missingFields.push(' employee start date');
		if (!formState.annualBaseSalary) missingFields.push(' annual base salary');
		if (!formState.employmentType) missingFields.push(' employment type');
		if (!formState.timeOffType) missingFields.push(' time off type');
		if (!formState.timeOffDays) missingFields.push(' time off days');
		if (!formState.contractTermType) missingFields.push(' contract term type');
		if (!formState.probationPeriod) missingFields.push(' probation period');

		if (missingFields.length > 0) {
			setErrorMsg(`Please fill in the following required fields:${missingFields.join(', ')}.`);
			return false;
		} else {
			setErrorMsg('');
			return true;
		}
	};

	// Clear allowance values when addFixedAllowance is false
	useEffect(() => {
		if (!formState.addFixedAllowance) {
			setFormState((prevState) => ({
				...prevState,
				allowanceArray: [],
				type: '',
				amount: '',
				description: '',
			}));
		}
	}, [formState.addFixedAllowance]);

	// Clear signing bonus values when addSigningBonus is false
	useEffect(() => {
		if (!formState.addSigningBonus) {
			setFormState((prevState) => ({
				...prevState,
				grossSigningBonus: '',
			}));
		}
	}, [formState.addSigningBonus]);

	// Clear annual variable compensation values when addAnnualVariableCompensation is false
	useEffect(() => {
		if (!formState.addAnnualVariableCompensation) {
			setFormState((prevState) => ({
				...prevState,
				variableComponsationType: '',
				compensationAmount: '',
			}));
		}
	}, [formState.addAnnualVariableCompensation]);

	const steps = [
		{
			label: 'Business information',
			description: (
				<>
					<Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
						<Form.Control className="mb-3" type="text" name="legalEntityName" placeholder="Legal entity name" onChange={handleChange} value={formState.legalEntityName} />
						<Form.Select className={`mb-3 ${formState.entityType === '' ? 'select-placeholder' : ''}`} as="select" onChange={handleChange} value={formState.entityType} name="entityType">
							<option value="">Select Entity Type</option>
							{entities.map((entity, index) => (
								<option key={index} value={entity}>
									{entity}
								</option>
							))}
						</Form.Select>
						<Form.Control
							name="legalRegistrationNumber"
							className="mb-3"
							type="number"
							placeholder="Legal registration number"
							onChange={handleChange}
							value={formState.legalRegistrationNumber}
						/>
						<Form.Control className="mb-3" type="text" placeholder="VAT ID" onChange={handleChange} value={formState.vatId} name="vatId" />
						{/* <Form.Control className="mb-3" type="text" placeholder="Phone number" onChange={handleChange} value={formState.phoneNumber} name="phoneNumber" /> */}
						<MuiTelInput value={phone} onChange={hanldePhone} name="phoneNumber" style={{width: '100%'}} className="mb-3" placeholder="Phone number with country code" />

						<Form.Control className="mb-3" type="text" placeholder="Business address" onChange={handleChange} value={formState.businessAddress} name="businessAddress" />

						{/* <AddressAutocompleteInput name="businessAddress" value={formState.businessAddress} onChange={handleChange} /> */}
						{/* <Autocomplete
							apiKey="AIzaSyAt2TfVC7otdcqnYbC2G4yBqZHBVrAux7o"
							onPlaceSelected={(place) => {
								console.log(place);
							}}
						/> */}
					</Form.Group>
				</>
			),
		},
		{
			label: 'Employee information',
			description: (
				<>
					<Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
						<Form.Control className="mb-3" type="text" placeholder="Employee first name" onChange={handleChange} value={formState.employeeFirstName} name="employeeFirstName" />
						<Form.Control className="mb-3" type="text" placeholder="Employee last name" onChange={handleChange} value={formState.employeeLastName} name="employeeLastName" />
						<Form.Control className="mb-3" type="text" placeholder="Employee email" onChange={handleChange} value={formState.employeeEmail} name="employeeEmail" />

						<Form.Select
							className={`mb-3 ${formState.employeeNationality ? '' : 'select-placeholder'}`}
							as="select"
							onChange={handleChange}
							value={formState.employeeNationality}
							name="employeeNationality">
							<option value="">Employee nationality</option>
							{countryList.map((country, index) => (
								<option key={index} value={country}>
									{country}
								</option>
							))}
						</Form.Select>
						<Form.Select
							className={`mb-3 ${formState.employeeCountry ? '' : 'select-placeholder'}`}
							as="select"
							onChange={handleChange}
							value={formState.employeeCountry}
							name="employeeCountry">
							<option value="">Country employee will be working from</option>
							{countryList.map((country, index) => (
								<option key={index} value={country}>
									{country}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</>
			),
		},
		{
			label: 'Job information',
			description: (
				<>
					<Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
						<Form.Control className="mb-3" type="text" placeholder="Job title" onChange={handleChange} value={formState.jobTitle} name="jobTitle" />
						<Form.Select
							className={`mb-3 ${formState.seniorityLevel ? '' : 'select-placeholder'}`}
							as="select"
							onChange={handleChange}
							value={formState.seniorityLevel}
							name="seniorityLevel">
							<option value="">Select seniority level</option>
							{senorities.map((senority, index) => (
								<option key={index} value={senority}>
									{senority}
								</option>
							))}
						</Form.Select>
						<Form.Control className="mb-3" as="textarea" type="text" placeholder="Scope of work" onChange={handleChange} value={formState.scopeOfWork} name="scopeOfWork" />
					</Form.Group>
				</>
			),
		},
		{
			label: 'Compensation',
			description: (
				<>
					<Form.Group className="mb-3">
						<Form.Label className="d-flex">Employee start date</Form.Label>
						<Form.Control className="mb-3" type="date" placeholder="Employee start date" onChange={handleChange} value={formState.employeeStartDate} name="employeeStartDate" />
						<Form.Control className="mb-3" type="number" placeholder="Annual base salary (USD)" onChange={handleChange} value={formState.annualBaseSalary} name="annualBaseSalary" />
						<Form.Check
							className="custom-checkbox-label"
							type="checkbox"
							id="addSigningBonus"
							label="Add signing bonus"
							onChange={handleChange}
							name="addSigningBonus"
							checked={formState.addSigningBonus}
						/>
						{formState.addSigningBonus === true && (
							<Form.Control className="mb-3" type="number" placeholder="Gross signing bonus" onChange={handleChange} value={formState.grossSigningBonus} name="grossSigningBonus" />
						)}

						<Form.Check
							type="checkbox"
							id="addAnnualVariableCompensation"
							label="Add annual variable compensation"
							onChange={handleChange}
							name="addAnnualVariableCompensation"
							className="custom-checkbox-label"
							checked={formState.addAnnualVariableCompensation}
						/>
						{formState.addAnnualVariableCompensation === true && (
							<>
								{' '}
								<Form.Select
									className={`mb-3 ${formState.variableComponsationType ? '' : 'select-placeholder'}`}
									as="select"
									onChange={handleChange}
									value={formState.variableComponsationType}
									name="variableComponsationType">
									<option value="">Select variable compensation type</option>
									<option value="Flat rate">Flat rate</option>
									<option value="Percentage">Percentage</option>
								</Form.Select>
								<Form.Control
									className="mb-3"
									type="number"
									placeholder="Compensation amount"
									onChange={handleChange}
									value={formState.compensationAmount || ''}
									name="compensationAmount"
								/>
							</>
						)}
					</Form.Group>
				</>
			),
		},

		{
			label: 'Allowances',
			description: (
				<>
					<Form.Group className="mb-3">
						<Form.Check
							className="custom-checkbox-label"
							type="checkbox"
							id="addFixedAllowance"
							label="Add fixed allowance"
							onChange={handleChange}
							name="addFixedAllowance"
							checked={formState.addFixedAllowance}
						/>
						{formState.addFixedAllowance && (
							<>
								<Form.Select
									className={`mb-3 ${formState.allowancePaymentType ? '' : 'select-placeholder'}`}
									as="select"
									onChange={handleChange}
									value={formState.allowancePaymentType}
									name="allowancePaymentType">
									<option value="">Select allowance payment type</option>
									<option value="one-time">One-time</option>
									<option value="monthly">Monthly</option>
								</Form.Select>
								<Form.Control className="mb-3" type="number" placeholder="Allowance amount" onChange={handleChange} name="allowanceAmount" />
								<Form.Control
									className="mb-3"
									type="text"
									placeholder="Allowance description"
									onChange={handleChange}
									value={formState.allowanceDescription}
									name="allowanceDescription"
								/>
								<Button className="mb-3" onClick={addAllowance} disabled={!formState.allowancePaymentType || !formState.allowanceAmount || !formState.allowanceDescription}>
									Add
								</Button>{' '}
								<ListGroup>
									{formState.allowanceArray.map((allowance, idx) => (
										<ListGroup.Item style={{textAlign: 'left'}} as="li" key={idx}>
											<div className="fw-bold d-flex justify-content-between">
												<span>Allowance {idx + 1}</span>
												<FiX className="icon" onClick={() => removeAllowance(idx)} />
											</div>

											{allowance.type && <div>Payment type: {allowance.type}</div>}
											{allowance.amount && <div>Amount: {allowance.amount}</div>}
											{allowance.description && <div>Description: {allowance.description}</div>}
										</ListGroup.Item>
									))}
								</ListGroup>
							</>
						)}
					</Form.Group>
				</>
			),
		},
		{
			label: 'Employment terms',
			description: (
				<>
					<Form.Group className="mb-3">
						<Form.Select
							placeholder="Select employment type"
							className={`mb-3 ${formState.employmentType ? '' : 'select-placeholder'}`}
							as="select"
							onChange={handleChange}
							value={formState.employmentType}
							name="employmentType">
							<option value="" disabled className="text-muted">
								Select employment type
							</option>
							<option value="Full-Time">Full-time</option>
							<option value="Part-Time">Part-time</option>
						</Form.Select>

						{formState.employmentType === 'Part-Time' && (
							<Form.Control className="mb-3" type="number" placeholder="Part-time hours" onChange={handleChange} value={formState.partTimeHours} name="partTimeHours" />
						)}

						<Form.Select
							name="contractTermType"
							className={`mb-3 ${formState.contractTermType ? '' : 'select-placeholder'}`}
							as="select"
							onChange={handleChange}
							value={formState.contractTermType}>
							<option value="">Select contract term type</option>
							<option value="Definitie">Definitie</option>
							<option value="Indefinite">Indefinite</option>
						</Form.Select>

						{formState.contractTermType === 'Definitie' && (
							<Form.Control type="date" className="mb-3" placeholder="Contract end date" onChange={handleChange} value={formState.contractEndDate} name="contractEndDate" />
						)}

						<Form.Select className={`mb-3 ${formState.timeOffType ? '' : 'select-placeholder'}`} as="select" onChange={handleChange} value={formState.timeOffType} name="timeOffType">
							<option value="">Select time off type</option>
							<option value="Standard">Standard</option>
							<option value="Specific">Specific</option>
						</Form.Select>

						<Form.Control className="mb-3" type="number" placeholder="Time off days" onChange={handleChange} value={formState.timeOffDays} name="timeOffDays" min={5} onBlur={handleBlur} />
						<Alert severity="info">Due to labour laws, a minimum of 5 paid vacation days is required.</Alert>
						<Form.Check
							checked={formState.addHealthInsurance}
							className="custom-checkbox-label"
							type="checkbox"
							id="addHealthInsurance"
							label="Add health insurance"
							onChange={handleChange}
							name="addHealthInsurance"
						/>
						{formState.addHealthInsurance && <Alert severity="info">Monthly ₱800 healthcare platform fee on the final price.</Alert>}
					</Form.Group>
				</>
			),
		},
		{
			label: 'Submit',
			description: (
				<>
					<Alert severity="info">Please go through the form and double check all your details before submitting.</Alert>
				</>
			),
		},
	];
	return (
		<div className="contractPage">
			<Helmet>
				<title>Confirm Email | Permworks</title>
				<meta name="description" content="Confirm your email address to complete your registration. Please check your inbox for the confirmation link." />
			</Helmet>
			<Navbar />
			<div className="contractContainer">
				<h1>Create Contract</h1>
				<br />
				<Card>
					<Card.Header>Contract details form</Card.Header>
					<Card.Body>
						<Box sx={{maxWidth: 600}}>
							<Stepper activeStep={activeStep} orientation="vertical">
								{steps.map((step, index) => (
									<Step key={step.label}>
										<StepLabel style={{cursor: 'pointer', fontFamily: 'Plus Jakarta Sans, sans-serif'}} onClick={() => setActiveStep(index)}>
											{step.label}
										</StepLabel>
										<StepContent>
											<Typography>{step.description}</Typography>
											<Box sx={{mb: 2}}>
												<div>
													<Button variant="contained" onClick={index === steps.length - 1 ? handleSubmit : handleNext} sx={{mt: 1, mr: 1}}>
														{index === steps.length - 1 ? 'Submit' : 'Continue'}
													</Button>
													<Button disabled={index === 0} onClick={handleBack} sx={{mt: 1, mr: 1}}>
														Back
													</Button>
												</div>
											</Box>
											{errorMsg && index === steps.length - 1 && <Alert severity="error">{errorMsg}</Alert>}
										</StepContent>
									</Step>
								))}
							</Stepper>
						</Box>
					</Card.Body>
				</Card>
				<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
						This is a success message!
					</Alert>
				</Snackbar>
			</div>
			<Footer />
		</div>
	);
}
