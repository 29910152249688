import {EXTERNAL_ROUTES, ROUTES} from '../ROUTES';

export const pricingData = [
	{
		title: 'End-to-End Recruitment',
		price: '25% of the yearly full-time salary.',
		priceDescription: 'Minimum 2.5k USD charge.',
		descriptions: ['Job Ad description generation', 'Applicant screening', 'Rigorous testing', 'Thorough background checks', 'Dedicated interview process', 'Expert recommendations'],
		buttonText: 'Find Out More',
		buttonLink: EXTERNAL_ROUTES.PERMHUNT,
	},
	{
		title: 'Compliance Facilitation',
		price: '$40-$300',
		priceDescription: 'USD per month',
		descriptions: ['Contractor agreement and payment faciliation', 'Employer of record (EOR) faciliation (Employees)'],
		buttonText: 'Find Out More',
		buttonLink: EXTERNAL_ROUTES.CALENDLY_PERMHUNT,
	},
];

export const subscriptionOptionsPricing = [
	{
		title: 'Lite Job Ad',
		price: 'Free',
		priceDescription: '',
		descriptions: ['Capped at 20 applicants - once you hit 20 applicants the job is closed', 'Max 1 free posting at a time', 'Max 1 concurrent Lite Job Ad'],
		buttonText: 'Sign Up',
		buttonLink: ROUTES.AUTHENTICATE,
	},
	{
		title: 'Basic',
		price: '$15 USD per week',
		priceDescription: '',
		descriptions: ['Unlimited applicants', 'Priority job ad placement in feed', 'Basic email blast to suitable job applicants in our database'],
		buttonText: 'Sign Up',
		buttonLink: ROUTES.AUTHENTICATE,
	},
	{
		title: 'Premium',
		price: '$17 USD per week',
		priceDescription: '',
		descriptions: ['Unlimited applicants', 'Higher priority Job ad placement in feed', 'Moderate email blast to suitable job applicants in our database'],
		buttonText: 'Sign Up',
		buttonLink: ROUTES.AUTHENTICATE,
		recommended: true,
	},
	{
		title: 'Premium Plus',
		price: '$20 USD per week',
		priceDescription: '',
		descriptions: ['Unlimited applicants', 'Highest priority job ad placement in feed', 'Maximum email blast to all suitable job applicants in our database', 'Priority support'],
		buttonText: 'Sign Up',
		buttonLink: ROUTES.AUTHENTICATE,
	},
];
