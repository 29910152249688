import React, {useEffect, useRef} from 'react';
import {formatDateAndTime} from '../../utils/DateFunctions';
import {ChatBoxProps} from '../../types/types-main';
import {AvatarWithLoading} from './Inbox';

const ChatBox: React.FC<ChatBoxProps> = ({messagesContent, currentUser}) => {
	const messageOverviewRightRef = useRef<HTMLDivElement>(null);
	const lastMessageRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (lastMessageRef.current) {
			lastMessageRef.current.scrollIntoView({behavior: 'smooth'});
		}
	}, [messagesContent]);

	return (
		<div className="flex flex-column justify-end overflow-y-visible gap-2 p-2 " ref={messageOverviewRightRef}>
			{messagesContent?.map((text, index) => (
				<div
					className={`header m-0  ${currentUser.email === text.sender.email ? 'currentUserSender' : ''}`}
					key={text.id}
					ref={index === messagesContent.length - 1 ? lastMessageRef : null}
					style={{margin: '10px 5px'}}>
					<AvatarWithLoading
						alt={`${text.sender?.profile?.first_name ?? 'Unknown'} ${text.sender?.profile?.last_name ?? 'User'}`}
						src={text.sender?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
						className="profile-image items-bottom"
					/>
					<div className={`innerHeader ${currentUser.email === text.sender.email ? 'currentUserSender currentUserSenderHeader' : ''}`}>
						<div className="mainContent">
							<p className="textContent">{text.message}</p>
							<p className="textContent time">{formatDateAndTime(text.sent_at)}</p>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default ChatBox;
