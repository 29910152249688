export const categoryOptionsArray = [
	'Admin (Virtual Assistant)',
	'Customer Service & Support',
	'Data Science & Analytics',
	'Design & Creative',
	'Engineering & Architecture',
	'Finance & Accounting',
	'Human Resources',
	'IT & Networking',
	'Legal',
	'Marketing',
	'Professional Services',
	'Project Management',
	'Sales',
	'Translation',
	'Web, Mobile & Software Dev',
	'Writing',
	'Other',
];

export const jobTypeOptionsArray = ['Part-Time', 'Full-Time', 'One-Time Project'];
export const monthlySalaryOptions = ['Less than $1000', '$1000 - $1500', '$1500 - $2000', '$2000 - $2500', '$2500 - $4000', '$4000 +'];
export const datePostedOptions = ['Newest', 'Oldest'];
