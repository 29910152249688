import React from 'react';
import Footer from '../../components/Footer/Footer';
import {Helmet} from 'react-helmet';
import './NotFound.scss';
import Navbar from '../../components/Navbars/Navbar2';

export default function NotFound() {
	return (
		<div className="flex flex-col justify-between" style={{minHeight: '100vh'}}>
			<Navbar />
			<div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col lg:flex-row-reverse md:gap-2 gap-16 my-auto">
				<div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
					<div className="relative">
						<div className="absolute">
							<div>
								<h1 className="my-2 font-bold text-2xl">Looks like you've found the doorway to the great nothing</h1>
								<p className="my-2 mb-3">Sorry about that! Please visit our hompage to get where you need to go.</p>
								<a href="/" className="linkButtonLinkBlue">
									Go to Homepage
								</a>
							</div>
						</div>
						<div>
							<img src="/404.png" alt="404" />
						</div>
					</div>
				</div>
				<div>
					<img src="/plug.png" alt="plug" />
				</div>
			</div>
			<Footer />
		</div>
	);
}
