// create a react functioncomponent
import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import './ConfirmEmail.scss';
import Navbar from '../../components/Navbars/Navbar2';
import {useLocation} from 'react-router-dom';
import {mainUrl} from '../../api/Urls';
import {Helmet} from 'react-helmet';
import {Input} from 'antd';

export default function ConfirmEmail() {
	const [isTimedOut, setIsTimedOut] = useState(false);
	const location = useLocation();
	const [email, setEmail] = useState(location?.state?.email ? location.state.email : '');
	const [emailInput, setEmailInput] = useState('');
	const [emailError, setEmailError] = useState('');

	useEffect(() => {
		if (isTimedOut) {
			const timeoutId = setTimeout(() => {
				setIsTimedOut(false);
			}, 120000);
			return () => clearTimeout(timeoutId);
		}
	}, [isTimedOut]);

	function requestConfirmation() {
		axios
			.post(mainUrl + '/auth/resend-email-verify/', {
				email: email ? email : '',
			})
			.then(({data}) => {
				console.log(data);
				setIsTimedOut(true);
			})
			.catch((error) => {
				console.error(error.response.data);
				setEmailError(error.response.data.error);
			});
	}

	function customerConfirm() {
		axios
			.post(mainUrl + '/auth/resend-email-verify/', {
				email: emailInput,
			})
			.then(({data}) => {
				console.log(data);
				setIsTimedOut(true);
			})
			.catch((error) => {
				console.error(error.response.data);
				setEmailError(error.response.data.error);
			});
	}

	return (
		<div>
			<Helmet>
				<title>Confirm Email | Permworks</title>
				<meta name="description" content="Confirm your email address to complete your registration. Please check your inbox for the confirmation link." />
			</Helmet>
			<Navbar />
			<div className="confirmEmail">
				<div className="confirmCard">
					<h1>Confirm Email</h1>

					<h2>Thank you for signing up!</h2>
					<p>Please confirm your email address to complete your account registration.</p>
					{email === '' ? (
						<>
							<div className="cardText">
								<p>Please type in your email address to receive another confirmation email.</p>{' '}
								<Input type="email" placeholder="Email Address" required onChange={(e) => setEmailInput(e.target.value)} />
								<button className="buttonRed" onClick={customerConfirm} disabled={isTimedOut}>
									Send Email
								</button>
							</div>
						</>
					) : (
						<>
							<div className="cardText">
								<p>Once you confirm your email, you will be able to access all the features of our site.</p>{' '}
								<span className="email">
									A confirmation email has been sent to <strong>{email && email}</strong>
								</span>
								<button className="buttonNavy" onClick={requestConfirmation} disabled={isTimedOut}>
									Resend Confirmation
								</button>
							</div>
						</>
					)}

					{/* if they click timeout again when its true then set a mmessage saying you must wait a minute */}
					{emailError && <p className="error">{emailError}</p>}
					{isTimedOut && <p className="timeout">A new confirmation email has been sent</p>}
					{isTimedOut && <p className="timeout">Please check your spam folder</p>}
				</div>
			</div>
		</div>
	);
}
