import React from 'react';
import {useState} from 'react';
import Login from '../../components/AuthComponents/Login';
import Signup from '../../components/AuthComponents/Signup';
import './AuthPage.scss';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useMediaQuery} from '@material-ui/core';
import {permColors, tailwindPermColors} from '../../utils/colors';
import {Carousel} from 'react-bootstrap';
import {FullLogoSmall, LightLogo} from '../../components/Logos/Logo';
export default function AuthPage() {
	const [isSignup, setIsSignup] = useState(false);

	const urlParams = useParams('hello');
	console.log(urlParams.hello);
	const matches = useMediaQuery('(max-width: 720px)');
	const contentStyle = {
		color: permColors.darkCharcoal,
		textAlign: 'left',
		background: '#364d79',
	};
	return (
		<div className="auth-page-container">
			<Helmet>
				<title>Log in or Sign up | Permworks</title>
				<meta name="description" content="Log in or Sign up to Permworks to get started on your journey to find a job or a candidate for your job posting." />
			</Helmet>
			<div className="left-side">
				<FullLogoSmall fontWeight={600} className="w-full max-w-[500px] px-3 mt-4" />
				<div className="middleContainer">
					{isSignup ? <Signup /> : <Login />}
					<p className="other-link text-sm">
						{isSignup ? 'Have an account already?' : "Don't have an account?"}{' '}
						<a onClick={() => setIsSignup(!isSignup)} href="#" className="text-sm">
							{isSignup ? 'Sign in' : 'Sign up'}
						</a>
					</p>
					{!isSignup && (
						<Link to="/reset-password" className="text-sm">
							Forgot Password?
						</Link>
					)}
				</div>
			</div>
			{!matches && (
				<div className="right-side">
					<Carousel interval={4000} className="w-full h-full px-2" controls={false} pause={false} indicators={true}>
						<Carousel.Item className="w-full">
							<div className="w-full flex justify-center text-center">
								<div className="max-w-[500px]">
									<div className="flex justify-center">
										<LightLogo textVisibility={false} />
									</div>
									<h3 className={tailwindPermColors.white + ' font-semibold text-3xl mt-10'}>Discover Your Next Career Opportunity</h3>
									<p>
										Jumpstart your career with top companies in around the world. Explore diverse job openings and find your perfect match in a workplace that values talent and
										innovation.
									</p>
								</div>
							</div>
						</Carousel.Item>
						<Carousel.Item className="w-full">
							<div className="w-full flex justify-center text-center">
								<div className="max-w-[500px]">
									<div className="flex justify-center">
										<LightLogo textVisibility={false} />
									</div>
									<h3 className={tailwindPermColors.white + ' font-semibold text-3xl mt-10'}>Hire Top Filipino Talent</h3>
									<p>
										Connect with skilled professionals from the Philippines. Our platform offers access to a vast pool of candidates with a high proficiency in English and strong
										work ethic, ready to contribute to your success.
									</p>
								</div>
							</div>
						</Carousel.Item>
						<Carousel.Item className="w-full">
							<div className="w-full flex justify-center text-center">
								<div className="max-w-[500px]">
									<div className="flex justify-center">
										<LightLogo textVisibility={false} />
									</div>
									<h3 className={tailwindPermColors.white + ' font-semibold text-3xl mt-10'}>Expand Your Professional Network</h3>
									<p>
										Engage with the vibrant community of professionals and industry leaders in the Philippines. Enhance your career or business by leveraging connections and
										opportunities within our extensive network.
									</p>
								</div>
							</div>
						</Carousel.Item>
					</Carousel>
				</div>
			)}
		</div>
	);
}
