import React from 'react';
import './EmployerOfRecord.scss';
import NavbarDark from '../../components/Navbars/NavbarDark';
import eorImage from '../../assets/images/eorImage2.png';
import workForce from '../../assets/images/workForce.png';
import {useMediaQuery} from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Footer from '../../components/Footer/Footer';
import {Accordion} from 'react-bootstrap';
import HubSpotChat from '../../utils/HubSpotChat';

const faqs = [
	{
		question: 'Is it possible to hire Filipino workers without an Employer of Record (EOR)?',
		answer: "Yes, it's possible, but it requires either establishing a local legal entity or engaging workers as contractors. Both options have advantages and drawbacks. Creating a local legal entity involves taking on the responsibility of managing the entire local operation, including hiring lawyers, payroll specialists, benefits administrators, and other specialists. This option may be suitable if you plan to hire a large number of workers in a foreign country. But, if you only plan to hire a small number of employees, using an EOR is a more cost-effective solution. Hiring workers as contractors needs careful consideration, as international contractor misclassification is a significant issue and can result in hefty fines. Engaging an EOR protects your business from potential compliance risks by treating workers as full-fledged employees.",
	},
	{
		question: 'What distinguishes an EOR from a PEO?',
		answer: "An Employer of Record (EOR) can legally hire employees on your behalf in foreign countries, while a Professional Employer Organization (PEO) may not. Some firms refer to themselves as 'international PEOs' and provide EOR services. Others, using the term 'international PEO,' only offer services to companies that already have legal entities abroad. To simplify, if you aim to hire an individual in a country where you do not have a legal presence, an EOR is required. But, if you need assistance managing HR functions such as payroll and benefits administration in a region where you have a local legal entity, a PEO is what you need. A PEO provides similar services to an EOR, but does not manage the legal employment of employees in foreign countries.",
	},
	{
		question: 'What is the cost of an Employer of Record (EOR)?',
		answer: 'An EOR costs $299 p/month per employee. This fee is significantly lower compared to competitors in the market.',
	},
	{
		question: 'What does an Employer of Record (EOR) handle?',
		answer: "An EOR is primarily responsible for acting as the legal employer for your employees in foreign countries, providing protection from liability and compliance risks. They handle the complex HR tasks that come with hiring employees in other countries, such as complying with different pay frequencies, currencies, and paid time off policies. The EOR does not manage the employee's job performance, as they are employed by your company in the same way as any other employee. The EOR's role is limited to handling the paperwork and legal requirements, while the day-to-day management remains in the hands of the company.",
	},
];

export default function EmployerOfRecord() {
	const isDesktop = useMediaQuery('(min-width: 870px)');
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="eor-container">
			<HubSpotChat />
			<div className="eor-first">
				<NavbarDark />
				<div className="eor-first-content">
					<div className="left-side">
						<h1>
							Simplify International Hiring and Compliance with our <span style={{color: '#3da9fc'}}>Employer of Record Services</span>
						</h1>
						<h3>
							Permworks is your trusted facilitator for Employer of Record (EOR) services in the Philippines, providing comprehensive assistance and guidance through key aspects
							including onboarding, payroll management, tax compliance, benefits administration, and more.
						</h3>
						<button className="buttonLightBlue">Get in touch</button>
					</div>
					{isDesktop && (
						<div className="right-side">
							<img src={eorImage} alt="EOR" />
						</div>
					)}
				</div>
			</div>
			<div className="eor-second">
				<div className="first-segment">
					<h2>Unlock Global Hiring with Employer of Record (EOR) Services</h2>
					<p>
						Permworks, your trusted facilitator for global employment, simplifies the hiring process in the Philippines through our EOR service. We handle all aspects of payroll, benefits,
						tax compliance, and local regulations, freeing you to focus solely on team management. Partner with us for your Philippines expansion - avoid the hassle, time, and high costs
						associated with setting up a local entity. Our EOR service allows businesses of all sizes to hire and compensate overseas employees in an efficient and cost-effective manner.
					</p>
				</div>
				<div className="second-segment">
					<div className="left-side">
						<h2>Exploring the Role of an Employer of Record (EOR)</h2>
						<p>
							An Employer of Record (EOR) serves as a strategic facilitator, legally employing individuals in a foreign country on a company's behalf. For instance, if an Australia-based
							company aims to build a workforce in the Philippines, an EOR becomes an invaluable ally.
						</p>
					</div>
					{isDesktop && <img src={workForce} alt="workForce" />}
				</div>
				<div className="third-segment">
					<h4>The EOR's scope of responsibility encompasses a variety of HR and legal tasks, including:</h4>
					<div className="third-segment-cards">
						<div className="segment-card">
							<GavelIcon className="icon" />
							<p>Legal employment of full-time personnel abroad</p>
						</div>
						<div className="segment-card">
							<CurrencyExchangeIcon className="icon" />
							<p>Payroll management for international staff</p>
						</div>
						<div className="segment-card">
							<SupervisorAccountIcon className="icon" />
							<p>Administration of benefits for the Philippines workforce</p>
						</div>
					</div>
				</div>
			</div>
			<div className="eor-third">
				<div className="eor-third-content">
					<div>
						<h2>Frequently asked questions</h2>
						<p>Here are some of the most common questions we receive about our EOR services in the Philippines.</p>
						<Accordion className="questions">
							{faqs.map((faq, index) => (
								<Accordion.Item eventKey={index} key={index} className="question">
									<Accordion.Header>
										<span style={{color: '#5f6c7b', fontWeight: '600', padding: '10px 0'}}>{faq.question}</span>
									</Accordion.Header>
									<Accordion.Body>
										{' '}
										<span style={{fontWeight: '400'}}>{faq.answer}</span>
									</Accordion.Body>
								</Accordion.Item>
							))}
						</Accordion>
					</div>

					<br />
					<div>
						<h2>Want to find out more?</h2>
						<p>Get in touch with our team to learn more about our EOR services in the Philippines.</p>
						<a href="https://calendly.com/tim-permworks/30min" target="_blank">
							<button className="buttonLinkBlue" style={{padding: '14px, 24px'}}>
								Get in touch
							</button>
						</a>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
