import React from 'react';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import Card from 'react-bootstrap/Card';
import './ContractPage.scss';
import {Alert} from '@mui/material';

export default function ContractSuccess() {
	return (
		<div>
			<Navbar />
			<div className="contractSuccess">
				<Alert severity="success" style={{width: '100%'}}>
					You have successfully created a contract!
				</Alert>
				<br />
				<Card className="text-center">
					<Card.Header>Next steps...</Card.Header>
					<Card.Body>
						<Card.Title>Contract created!</Card.Title>
						<Card.Text>A representitive will reach out to you within 5-10 business days with the next steps.</Card.Text>
					</Card.Body>
					<Card.Footer className="text-muted">Thank you</Card.Footer>
				</Card>
			</div>
			<Footer />
		</div>
	);
}
