import axios from 'axios';
import {mainUrl} from './Urls';

export const createContractAndAcceptJobOfferApi = async (contractData, allowanceArray, jobOfferId) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		const response = await axios.post(
			mainUrl + '/auth/contracts/create_and_accept_job_offer/',
			{
				...contractData,
				fixed_allowances: allowanceArray,
				job_offer_id: jobOfferId,
			},
			config
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
