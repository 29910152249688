import {createTheme} from '@mui/material/styles';

const theme = createTheme({
	typography: {
		fontFamily: 'Plus Jakarta Sans, sans-serif',
	},
	palette: {
		primary: {
			main: '#094067',
		},
		secondary: {
			main: '#094067',
			contrastText: '#094067',
		},
		text: {
			primary: '#5f6c7b', // Change this value to your desired default text color
			secondary: '#094067', // Change this value to your desired secondary text color
		},
		custom: {
			primaryContrastText: '#fffffe',
			buttonBgColor: '#3da9fc',
			errorColor: '#ef4565',
			lightText: '#fffffe',
			lightBlue: '#d8eefe',
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
	components: {
		// list items and secondary text too
		MuiList: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					color: '#094067',
					'& .MuiListItemText-secondary': {
						color: '#5f6c7b', // replace this with your desired color
					},
					'&:hover': {
						backgroundColor: '#d8eefe',
						'& .MuiListItemText-secondary': {
							color: '#5f6c7b', // replace this with your desired color
						},
					},
					'&:selected': {
						backgroundColor: '#094067',
						color: '#fffffe',
						'& .MuiListItemText-secondary': {
							color: '#fffffe', // replace this with your desired color
						},
					},
				},
			},
		},

		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: '#d8eefe',
					height: '2px',
					border: 'none', // Change this value to your desired divider color
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					backgroundColor: '#3da9fc',
					border: '1px solid #3da9fc',
					boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',

					'&:hover': {
						backgroundColor: '#fffffe',
						color: '#3da9fc',
						borderColor: '#3da9fc',
						boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
					},
					'&:disabled': {
						backgroundColor: '#5f6c7b',
						color: '#fffffe',
						borderColor: '#5f6c7b',
						opacity: 0.5,
					},
				},
				outlined: {
					backgroundColor: 'transparent',
					'&:hover': {
						backgroundColor: '#ef4565',
						color: '#fffffe',
					},
				},
				contained: {
					backgroundColor: '#5f6c7b',
					color: '#fffffe',
					border: '1px solid #5f6c7b',
					'&:hover': {
						backgroundColor: '#fffffe',
						color: '#5f6c7b',
						border: '1px solid #5f6c7b',
					},
				},
				text: {
					backgroundColor: '#ef4565',
					border: '1px solid #ef4565',
					color: '#fffffe',

					'&:hover': {
						backgroundColor: '#fffffe',
						color: '#ef4565',
						border: '1px solid #ef4565',
					},
				},
			},
		},
	},
});

export default theme;
