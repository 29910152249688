import {Alert, Snackbar} from '@mui/material';
import React from 'react';

export default function GoogleSnackbar({snackbarOpen, snackbarMessage, snackbarSeverity, handleClose}) {
	return (
		<Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
			<Alert onClose={handleClose} severity={snackbarSeverity} sx={{width: '100%'}} variant="filled">
				{snackbarMessage}
			</Alert>
		</Snackbar>
	);
}
