import React, {useEffect, useState, useContext} from 'react';
import {useParams} from 'react-router-dom';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import {UserContext} from '../../utils/UserContext';
import './JobPage.scss';
import Loading from '../../components/Loaders/Loading';
import {getJobById} from '../../api/jobsApi';
import {textFormatter} from '../../utils/TextAlterations';
import Accordion from 'react-bootstrap/Accordion';
import {Helmet} from 'react-helmet';
import {capitalize} from '@material-ui/core';
import {formatDate2} from '../../utils/DateFunctions';
import Alert from '@mui/material/Alert';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import ApplyJobButton from '../../components/ApplyJob/ApplyJobButton';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import {permColors} from '../../utils/colors';

export default function JobPage() {
	const [job, setJob] = useState({});
	const {jobId} = useParams();
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchJob() {
			const jobData = await getJobById(jobId);
			setJob(jobData);
			setLoading(false);
		}
		fetchJob();
	}, [currentUser]);

	if (loading) {
		return <Loading />;
	}

	return (
		<div>
			<Helmet>
				<title>{`${job?.title ? job?.title : 'Job'} | Permworks`}</title>
				<meta name="description" content={`Apply for ${job?.title ? job?.title : 'Job'} at Permworks. Join our global talent network.`} />
			</Helmet>
			<Navbar />
			<div className="singleJobPage">
				<div className="jobContainer shadow-sm rounded-md">
					<div className="header items-center">
						<div className="flex items-center gap-3">
							{!job?.created_by_image ? <WorkOutlineOutlinedIcon className="working-icon" /> : <img src={job?.created_by_image} alt="profile" className="working-icon p-0" />}
							<h1 className="mb-0 md:text-2xl text-xl" style={{color: permColors.darkCharcoal}}>
								{job?.title && capitalize(job.title)}
							</h1>
						</div>
						<div className="flex items-center gap-2 flex-wrap">
							{job?.job_type && <p className="itemTagRed text-sm py-[5px] mb-0">{capitalize(job.job_type)}</p>}
							<ApplyJobButton job={job} />
						</div>
					</div>

					<hr style={{color: '#90b4ce'}} />
					{job?.description && <div dangerouslySetInnerHTML={{__html: textFormatter(job.description)}} style={{color: permColors.charcoal}} />}
					<hr style={{color: '#90b4ce'}} />

					{job?.company_name && (
						<p style={{color: permColors.charcoal}}>
							Company name: <span className="bold">{capitalize(job.company_name)}</span>
						</p>
					)}
					{job?.engagement_type && (
						<p style={{color: permColors.charcoal}}>
							Engagement type: <span className="bold">{capitalize(job.engagement_type)}</span>
						</p>
					)}
					{job?.created_at && (
						<p style={{color: permColors.charcoal}}>
							Posted on the <span className="bold">{formatDate2(job?.created_at)}</span>
						</p>
					)}

					{job?.category && (
						<p className="bold" style={{color: permColors.charcoal}}>
							Category: <span className="itemTagBlue">{capitalize(job.category)}</span>
						</p>
					)}
					{job?.country && (
						<p className="" style={{display: 'flex', width: 'fit-content', marginLeft: '0', color: permColors.charcoal}}>
							<span className="bold" style={{paddingRight: '5px'}}>
								{capitalize(job.country)}
							</span>
							<LocationOnRoundedIcon />
						</p>
					)}
					{job?.questions?.length > 0 ? (
						<Accordion defaultActiveKey="null">
							<Accordion.Item eventKey="0">
								<Accordion.Header>{job?.questions.length > 1 ? 'Application Questions' : 'Application question'}</Accordion.Header>
								{job.questions?.map((q) => (
									<Accordion.Body>{capitalize(q?.question)}</Accordion.Body>
								))}
							</Accordion.Item>
						</Accordion>
					) : (
						<Alert severity="info"> No required questions to answer for this job</Alert>
					)}
				</div>
			</div>

			<Footer />
		</div>
	);
}
