import React, {useEffect, useState} from 'react';
import {Form, Input, Button, DatePicker, Select} from 'antd';
import {createTimeOffRequest, fetchEmployees} from '../../../api/eor-api/teams-api';
import {useAtom} from 'jotai';
import {teamsAtom} from '../../../utils/atoms';
import moment from 'moment';
import {openNotification} from './Team';

const {RangePicker} = DatePicker;
const {Option} = Select;

const TimeOff = () => {
	const [form] = Form.useForm();
	const [teams, setTeams] = useAtom(teamsAtom);
	const [disabledRanges, setDisabledRanges] = useState([]);
	const [buttonLoading, setButtonLoading] = useState(false);

	const onEmployeeChange = (employeeId) => {
		const selectedEmployee = teams?.find((employee) => employee.id === employeeId);
		const timeOffSets = selectedEmployee?.timeoff_set || [];
		const disabledDateRanges = timeOffSets.map((timeOff) => ({
			startDate: moment(timeOff.start_date),
			endDate: moment(timeOff.end_date),
		}));
		setDisabledRanges(disabledDateRanges);
	};

	const publicHolidays = ['2024-04-29', '2024-12-25'];

	const onFinish = async (values) => {
		const timeOffDetails = {
			startDate: values.dateRange[0].format('YYYY-MM-DD'),
			endDate: values.dateRange[1].format('YYYY-MM-DD'),
			reason: values.reason,
			leaveType: values.leaveType,
			employee: values.employee,
		};

		try {
			setButtonLoading(true);
			await createTimeOffRequest(timeOffDetails);
			const res = await fetchEmployees();
			setTeams(res);
			form.resetFields();
			openNotification('Time Off Request Created', 'Time off request has been created successfully', 'success');
		} catch (error) {
			console.error(error);
			openNotification('Error', 'An error occurred while creating the time off request', 'error');
		}
		setButtonLoading(false);
		setDisabledRanges([]);
	};

	const isDateDisabled = (current) => {
		if (publicHolidays.includes(current.format('YYYY-MM-DD'))) {
			return true;
		}
		return disabledRanges.some((range) => current >= range.startDate && current <= range.endDate);
	};
	return (
		<div className="dashboardComponent">
			<div className="header">
				<h2>Time off request</h2>
			</div>

			<div className="outerContainer">
				<div className="innerContainer pb-2">
					<Form form={form} onFinish={onFinish} className="flex flex-col max-w-[500px] shadow-sm p-4 rounded-lg bg-white">
						<Form.Item name="employee" label="Employee" rules={[{required: true, message: 'Please enter the employee ID'}]} labelCol={{span: 24}} wrapperCol={{span: 24}}>
							<Select
								showSearch
								className="w-full"
								optionFilterProp="children"
								onChange={onEmployeeChange}
								filterOption={(input, option) => {
									const childrenString = option.children.toString();
									return childrenString.toLowerCase().indexOf(input.toLowerCase()) >= 0;
								}}>
								{teams.length > 0 &&
									teams?.map((employee) => (
										<Option key={employee.id} value={employee.id}>
											{employee.first_name} {employee.last_name}
										</Option>
									))}
							</Select>
						</Form.Item>
						<Form.Item name="dateRange" label="Date Range" rules={[{required: true, message: 'Please select a date range'}]} labelCol={{span: 24}} wrapperCol={{span: 24}}>
							<RangePicker disabledDate={isDateDisabled} className="w-full" disabled={form.getFieldValue('employee') === undefined} />
						</Form.Item>
						<Form.Item name="reason" label="Reason" rules={[{required: true, message: 'Please enter a reason'}]} labelCol={{span: 24}} wrapperCol={{span: 24}}>
							<Input className="w-full" />
						</Form.Item>
						<Form.Item name="leaveType" label="Leave Type" rules={[{required: true, message: 'Please select a leave type'}]} labelCol={{span: 24}} wrapperCol={{span: 24}}>
							<Select className="w-full">
								<Option value="vacation">Vacation</Option>
								<Option value="sick">Sick Leave</Option>
								<Option value="personal">Personal Day</Option>
							</Select>
						</Form.Item>

						<Button htmlType="submit" className="w-fit ml-auto" loading={buttonLoading} disabled={buttonLoading}>
							Submit
						</Button>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default TimeOff;
