import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {setNewPasswordApi} from '../../api/profileApi';
import {permColors} from '../../utils/colors';

export default function NewPasswordPopup(props) {
	const {showPopup, closePopup} = props;
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState('');
	const [complete, setComplete] = useState(false);

	const resetPassword = async (e) => {
		e.preventDefault();
		const res = await setNewPasswordApi(oldPassword, newPassword);
		if (res === true) {
			setMessage('Password changed successfully');
			setComplete(true);
		} else {
			setMessage(res);
			setComplete(true);
		}
	};

	return (
		<Modal show={showPopup} onHide={closePopup} style={{color: permColors.darkCharcoal}}>
			<Modal.Header closeButton className="p-4">
				<Modal.Title style={{color: null}}>Reset password</Modal.Title>
			</Modal.Header>
			<form onSubmit={resetPassword}>
				<Modal.Body className="flex flex-column gap-3 p-4">
					{!complete ? (
						<>
							<p className="pb-1 px-0 m-0 font-semibold">Please fill in the details to reset your password</p>
							<input className="p-2" type="password" placeholder="Old password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} required />
							<input className="p-2" type="password" placeholder="New password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
							<input className="p-2" type="password" placeholder="Confirm password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
						</>
					) : (
						<p className="m-0">{message}</p>
					)}
				</Modal.Body>
				<Modal.Footer className="p-4">
					<button className="buttonRedSmall" onClick={closePopup}>
						Close
					</button>
					{!complete && (
						<button className="buttonLinkBlue ml-2" type="submit">
							Reset
						</button>
					)}
				</Modal.Footer>
			</form>
		</Modal>
	);
}
