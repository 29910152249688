import React, {useEffect, useMemo, useState} from 'react';
import {Button, notification, Space} from 'antd';
import {InfoCircleFilled} from '@ant-design/icons';
import {Info} from '@mui/icons-material';
import {permColors} from '../../utils/colors';
import {COOKIES, COOKIE_CONSENT_OUTCOME, COOKIE_EXPIRY_TIME} from '../../utils/cookie-names';
import Cookies from 'js-cookie';

export default function CookieConsent() {
	const [api, contextHolder] = notification.useNotification();

	const openNotification = (placement: any) => {
		api.info({
			style: {},
			className: 'justify-center items-center text-left',
			description: (
				<div>
					<p>
						We use cookies to ensure that we give you the best experience on our website. Please select either to allow all cookies or only necessary cookies (enabling core functionality).
					</p>
					<div className="flex flex-wrap gap-2">
						<Button type="primary" onClick={handleAccept} className="grow">
							Allow all cookies
						</Button>
						<Button type="default" onClick={handleDecline} className="grow">
							Allow necessary cookies only
						</Button>
					</div>
				</div>
			),
			message: 'Cookie Consent',
			placement,
			closeIcon: false,
			duration: 0,
		});
	};

	useEffect(() => {
		const cookieConsent = Cookies.get(COOKIES.cookie_consent);
		if (!cookieConsent) {
			openNotification('bottomLeft');
		}
	}, []);

	const handleAccept = () => {
		Cookies.set(COOKIES.cookie_consent, COOKIE_CONSENT_OUTCOME.ACCEPTED, COOKIE_EXPIRY_TIME.ONE_YEAR);
		api.destroy();
	};

	const handleDecline = () => {
		Cookies.set(COOKIES.cookie_consent, COOKIE_CONSENT_OUTCOME.REJECTED, COOKIE_EXPIRY_TIME.ONE_YEAR);
		api.destroy();
	};

	return <div>{contextHolder}</div>;
}
