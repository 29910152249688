import {CheckCircleOutlined} from '@ant-design/icons';
import {Badge, Tag} from 'antd';
import {AiOutlineCheck} from 'react-icons/ai';
import {EXTERNAL_ROUTES, ROUTES} from '../../utils/ROUTES';
import {permColors} from '../../utils/colors';

export function pricingCard(pricingData: any) {
	return (
		<div className="pricing-cards">
			{pricingData.map((pricing: any, index: number) => {
				// If the pricing card is recommended, wrap it with a ribbon.
				if (pricing?.recommended) {
					return (
						<Badge.Ribbon key={index} text="Recommended" color={permColors.blue}>
							<div className={'pricing-card'}>
								{/* Content of the pricing card */}
								{renderPricingCard(pricing)}
							</div>
						</Badge.Ribbon>
					);
				}

				// If not recommended, just return the card without a ribbon.
				return (
					<div key={index} className={'pricing-card'}>
						{/* Content of the pricing card */}
						{renderPricingCard(pricing)}
					</div>
				);
			})}
		</div>
	);
}

export function otherSpecializationCards(pricingData: any) {
	return (
		<div className="pricing-cards">
			{pricingData.map((pricing: any, index: number) => (
				<div className={'pricing-card ' + (pricing?.recommended ? '' : '')}>
					<div className="pricing-header">
						<h2 className="flex gap-2">
							{pricing.title}
							{pricing?.recommended && (
								<Tag color="blue" icon={<CheckCircleOutlined />}>
									Recommended
								</Tag>
							)}
						</h2>
						<h3 style={{fontSize: '1.1em'}}>{pricing.price}</h3>
						<p>{pricing.priceDescription}</p>
					</div>
					<hr />
					<div className="pricing-card-bottom">
						<div>
							{pricing.descriptions.map((description: string, index: number) => (
								<div className="individual-description">
									<AiOutlineCheck className="icon" />
									<p className="text-sm">{description}</p>
								</div>
							))}
						</div>
						<a className="linkButtonDarkBlue text-sm" href={EXTERNAL_ROUTES.CALENDLY_PERMHUNT} rel="noreferrer" target="_blank">
							Contact sales
						</a>
					</div>
				</div>
			))}
		</div>
	);
}

function renderPricingCard(pricing: any) {
	return (
		<>
			<div className="pricing-header">
				<h2 className="flex gap-2">{pricing.title}</h2>
				<h3 style={{fontSize: '1.1em'}}>{pricing.price}</h3>
				<p>{pricing.priceDescription}</p>
			</div>
			<hr />
			<div className="pricing-card-bottom">
				<div>
					{pricing.descriptions.map((description: string, index: number) => (
						<div key={index} className="individual-description">
							<AiOutlineCheck className="icon" />
							<p className="text-sm">{description}</p>
						</div>
					))}
				</div>
				<a className="linkButtonLinkBlue text-sm" href={ROUTES.POST_JOB} rel="noreferrer" target="_blank">
					Post a job
				</a>
			</div>
		</>
	);
}
