import React from 'react';
import {ThreeCircles} from 'react-loader-spinner';
import './loaders.scss';
import {Spin} from 'antd';
export default function LoadingPartial() {
	return (
		<div className="loaderPartial">
			<div className="loaderInner">
				{/* <ThreeCircles height="100" width="100" color="#094067" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel="three-circles-rotating" outerCircleColor="" /> */}
				<Spin size="large" className="mx-auto" />
			</div>
		</div>
	);
}
