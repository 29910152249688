import React, {useContext, useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {UserContext} from '../UserContext';

const AuthenticatedCheck = ({children}) => {
	const [currentUser] = useContext(UserContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (currentUser.profile) {
			setLoading(false);
		}
	}, [currentUser.profile]);

	if (loading) {
		return; // Use your loading component or a placeholder here
	}
	return currentUser?.profile ? children : <Navigate to="/authenticate" />;
};

export default AuthenticatedCheck;
