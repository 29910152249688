import {mainUrl} from './Urls';
import axios from 'axios';
import api from './interceptor';

// fetch all conversations for the authenticated user
export const getConversationsApi = async () => {
	try {
		const {data} = await api.get(`${mainUrl}/auth/conversations/`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return data;
	} catch (error) {
		console.error(error);
		return 'error';
	}
};

// fetch a single conversation by id
export const getConversationApi = async (conversationId) => {
	try {
		const data = await api.get(`${mainUrl}/auth/conversations/${conversationId}/`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		console.log(data);

		return data;
	} catch (error) {
		console.error(error);
	}
};

// fetch all messages for a single conversation
export const getMessagesApi = async (conversationId) => {
	try {
		const {data} = await api.get(`${mainUrl}/auth/conversations/${conversationId}/messages/`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return data;
	} catch (error) {
		console.error(error);
	}
};

// send a message to a conversation
export const sendMessageApi = async (conversationId, message) => {
	try {
		const response = await api.post(
			`${mainUrl}/auth/conversations/${conversationId}/messages/`,
			{
				message: message,
				conversation_id: conversationId,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);

		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const sendNewMessageApi = async (message, recipientId) => {
	try {
		const response = await api.post(
			`${mainUrl}/auth/messages/new/`,
			{
				recipient_id: recipientId,
				message: message,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);

		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
};
