import React, {useEffect, useState} from 'react';
import {getAppliedJobsApi} from '../../api/jobsApi';
import {Link} from 'react-router-dom';
import {Alert, capitalize} from '@mui/material';
import {textFormatter} from '../../utils/TextAlterations';
import LoadingPartial from '../Loaders/LoadingPartial';
import {Table, Input, Select} from 'antd';
import {filterByJobType, filterBySearchTerm} from '../../utils/filters';

const {Option} = Select;
const {Search} = Input;

const AppliedJobs = () => {
	const [appliedJobs, setAppliedJobs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	const [jobTypeFilter, setJobTypeFilter] = useState('all');

	async function getLiked() {
		const res = await getAppliedJobsApi();
		console.log(res);
		setAppliedJobs(res);
		setLoading(false);
	}

	useEffect(() => {
		getLiked();
	}, []);

	function applicationStatus(jobOffer) {
		switch (true) {
			case jobOffer?.accepted:
				return <Alert severity="success">Accepted</Alert>;
			case jobOffer?.declined:
				return <Alert severity="error">Declined</Alert>;
			case !jobOffer?.accepted && !jobOffer?.declined:
				return <Alert severity="info">Pending</Alert>;
			default:
				return <Alert severity="info">Pending State</Alert>;
		}
	}

	if (loading) {
		return <LoadingPartial />;
	}

	const filteredAppliedJobs = appliedJobs.filter(filterBySearchTerm(searchTerm)).filter(filterByJobType(jobTypeFilter));

	const columns = [
		{
			title: 'Job Title',
			dataIndex: 'title',
			key: 'title',
			render: (text, record) => (
				<Link to={`/jobpage/${record.id}`} style={{textDecoration: 'none'}}>
					{capitalize(text)}
				</Link>
			),
		},
		{
			title: 'Company',
			dataIndex: 'company_name',
			key: 'company_name',
			render: (text) => capitalize(text),
		},
		{
			title: 'Application Status',
			dataIndex: 'job_offers',
			key: 'status',
			render: (jobOffers) => applicationStatus(jobOffers[0]),
		},
		{
			title: 'Job Type',
			dataIndex: 'job_type',
			key: 'job_type',
		},
		{
			title: 'Engagement',
			dataIndex: 'engagement_type',
			key: 'engagement_type',
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			render: (text) => (
				<div
					className="border rounded p-2 min-w-[300px]"
					dangerouslySetInnerHTML={{
						__html: textFormatter(text),
					}}
					style={{maxHeight: '120px', overflowY: 'auto'}}
				/>
			),
		},
	];

	return (
		<div>
			<div className="dashboardComponent">
				<div className="header">
					<h2>Applied Jobs</h2>
				</div>
				<div className="outerContainer">
					<div className="flex flex-wrap my-2 gap-2">
						<Search
							placeholder="Search by title or company name"
							onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
							className="p-2 min-w-[200px] w-full md:w-80 flex-1 grow-1"
						/>
						<Select id="jobTypeFilter" value={jobTypeFilter} onChange={(value) => setJobTypeFilter(value)} className="p-2 min-w-[200px] w-full md:w-80 flex-1 grow-1">
							<Option value="all">Employment Type</Option>
							<Option value="full-time">Full-Time</Option>
							<Option value="part-time">Part-Time</Option>
						</Select>
					</div>
					<div className="innerContainer">
						<Table dataSource={filteredAppliedJobs} columns={columns} rowKey="id" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppliedJobs;
