import React from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar2 from '../../components/Navbars/Navbar2';
import './HiringPhilippines.scss';
import {SlCalender} from 'react-icons/sl';
import {IoIosSend, IoIosBusiness} from 'react-icons/io';
import {GrCurrency, GrCompliance} from 'react-icons/gr';
import {AiOutlineDollarCircle, AiOutlineCheckSquare, AiFillDollarCircle} from 'react-icons/ai';
import {Helmet} from 'react-helmet';

export default function HiringPhilippines() {
	return (
		<div>
			<Helmet>
				<title>Hiring in the Philippines | Permworks</title>
				<meta name="description" content="Learn about hiring in the Philippines. Understand the culture, work ethic, and benefits of hiring Filipino talent." />
			</Helmet>
			<Navbar2 />
			<div className="hiring">
				<h1>Hiring employees in the Philippines</h1>

				<div className="section2">
					<h3>Find and hire emplpoyees directly from The Philippines without the headache.</h3>
					<p>Recruit your next team member with confidence and security.</p>
					<div className="outerBox">
						<div className="innerBox">
							<AiOutlineDollarCircle className="icon" />
							<h3>Complex tax systems taken care of</h3>
						</div>
						<div className="innerBox">
							<AiOutlineCheckSquare className="icon" />

							<h3>Compliant Contracts for both Jurisdictions</h3>
						</div>
						<div className="innerBox">
							<AiFillDollarCircle className="icon" />
							<h3>Payroll done for you</h3>
						</div>
					</div>
				</div>
				<div className="pricingContainerEmployees">
					<h3>General Information</h3>

					<div className="bigContainer">
						<div className="smallContainer">
							<GrCurrency className="iconic" />
							<div className="box">
								<p>
									<strong>Currency:</strong>
								</p>
								<p>Philippine Peso - PHP (₱)</p>
							</div>
						</div>
						<div className="smallContainer">
							<IoIosBusiness className="iconic" />
							<div className="box">
								<p>
									<strong>Employer cost:</strong>
								</p>
								<p>Approximately 11%</p>
							</div>
						</div>
						<div className="smallContainer">
							<SlCalender className="iconic" />
							<div className="box">
								<p>
									<strong>Typical payroll cycle:</strong>
								</p>
								<p>Monthly</p>
							</div>
						</div>
					</div>
					<div className="bigContainer">
						<div className="smallContainer">
							<div className="box">
								<img src="freeLancing.svg" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
