import {permColors} from '../../utils/colors';
import {Tab, Tabs} from '@mui/material';
import {Book, QuestionAnswer, Work} from '@material-ui/icons';

export default function TabSelector({value, handleChange}) {
	return (
		<Tabs
			value={value}
			onChange={handleChange}
			variant="fullWidth"
			sx={{
				'& .MuiTabs-flexContainer': {
					borderBottom: `1px solid ${permColors.backgroundGrey}`,
				},
				'& .MuiTab-root': {
					textTransform: 'none', // Override default text styling
					color: permColors.charcoal, // Default non-selected tab color
					minWidth: 'auto', // Override the minimum width
					whiteSpace: 'normal', // Allow label text to wrap
					minHeight: 'auto', // You might also need to adjust the minimum height to accommodate wrapped text
					padding: '15px 12px', // Default non-selected tab color
				},
				'& .Mui-selected': {
					color: permColors.darkCharcoal, // Selected tab color
					backgroundColor: permColors.backgroundLightCream, // Selected tab background color
					borderRadius: '5px 5px 0 0',
				},
				// You might also want to set the indicator color if needed
				'& .MuiTabs-indicator': {
					backgroundColor: permColors.blue,
				},
				overflowX: 'auto',
			}}
			className="overflow-x-auto max-w-[500px] px-4">
			<Tab label="CV & questions" className="flex flex-row items-center" />
			<Tab label="Work experience" />
			<Tab label="Education" />
		</Tabs>
	);
}
