import './LandingPage.scss';
import {capitalizeFirstLetter, textFormatter} from '../../utils/TextAlterations';
import {daysAgo} from '../../utils/DateFunctions';
import Card from 'react-bootstrap/Card';
import {Modal} from 'react-bootstrap';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import BusinessIcon from '@mui/icons-material/Business';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import {Place} from '@material-ui/icons';
import ApplyJobButton from '../../components/ApplyJob/ApplyJobButton';

interface SelectedJobCardProps {
	job: any;
	onNavigate: (path: string) => void;
	authenticatedButtons: any;
}

export function SelectedJobCard({job, onNavigate, authenticatedButtons}: SelectedJobCardProps) {
	return (
		<div className="selectedFeed">
			<Card className="cardDesign card-shadow" style={{height: '95vh'}}>
				<Card.Header className="cardHeaderDesign">
					<span className="iconed-header items-center underline cursor-pointer hover:opacity-70" onClick={() => onNavigate(`/jobpage/${job.id}`)}>
						{!job?.created_by_image ? <WorkOutlineOutlinedIcon className="working-icon" /> : <img src={job?.created_by_image} alt="profile" className="working-icon p-0" />}
						{capitalizeFirstLetter(job.title)}
					</span>
				</Card.Header>
				<Card.Body className="card-body-design">
					<div className="flex flex-column gap-1">
						<div className="heading flex justify-between items-center">
							<p className="flex items-center gap-2 font-semibold text-lg">
								<BusinessIcon />
								<span>{capitalizeFirstLetter(job.company_name)}</span>
							</p>
							{authenticatedButtons(job)}
						</div>
						<span className="itemTagGrey">
							<WorkHistoryIcon /> {job.job_type} - {job.engagement_type}
						</span>{' '}
						{job?.country && (
							<p className="itemTagGrey">
								<Place />
								Remote - {capitalizeFirstLetter(job.country)}
							</p>
						)}
						{job?.monthly_salary && (
							<p className="itemTagLinkBlue">
								${job.monthly_salary}
								{job?.max_monthly_salary !== null && <span> - ${job.max_monthly_salary}</span>} USD Monthly
							</p>
						)}
						{job?.hourly_rate && (
							<p className="itemTagLinkBlue">
								${job.hourly_rate}
								{job?.max_hourly_rate !== null && <span> - ${job.max_hourly_rate}</span>} USD Hourly
							</p>
						)}
						<ApplyJobButton job={job} />
					</div>
					<div
						className="description grow"
						dangerouslySetInnerHTML={{
							__html: textFormatter(job.description),
						}}
					/>
					<p className="body-created">
						<QueryBuilderIcon className="icon" />
						{job.created_at !== null ? `Posted ${daysAgo(job.created_at)} days ago` : ''}
					</p>
				</Card.Body>
			</Card>
		</div>
	);
}

interface JobModalProps {
	job: any;
	show: boolean;
	onHide: any;
	onNavigate: (path: string) => void;
	authenticatedButtons: any;
}

export function JobModal({job, show, onHide, onNavigate, authenticatedButtons}: JobModalProps) {
	return (
		<Modal className="modal cardDesign" size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={onHide}>
			<Modal.Header closeButton className="cardHeaderDesign flex items-start flex-nowrap justify-start">
				<span
					className="iconed-header flex items-center justify-start text-left"
					onClick={() => {
						onNavigate(`/jobpage/${job.id}`);
					}}>
					{!job?.created_by_image ? <WorkOutlineOutlinedIcon className="working-icon" /> : <img src={job?.created_by_image} alt="profile" className="working-icon p-0" />}
					{capitalizeFirstLetter(job.title)}
				</span>
			</Modal.Header>
			<Card.Body className="card-body-design modal-body">
				<div className="heading" style={{cursor: 'pointer'}}>
					<p onClick={() => onNavigate(`/jobpage/${job.id}`)} className="body-job-opened grow" style={{margin: '0px', padding: '0px'}}>
						<BusinessIcon />
						{capitalizeFirstLetter(job.company_name)}
					</p>
					{authenticatedButtons(job)}
				</div>
				<span className="itemTagGrey">
					<WorkHistoryIcon /> {job.job_type} - {job.engagement_type}
				</span>{' '}
				{job?.country && (
					<p className="itemTagGrey mb-0">
						<MyLocationIcon />
						{capitalizeFirstLetter(job.country)}
					</p>
				)}
				{job?.monthly_salary && (
					<p className="itemTagLinkBlue mb-0">
						${job.monthly_salary}
						{job?.max_monthly_salary !== null && <span> - ${job.max_monthly_salary}</span>} USD Monthly
					</p>
				)}
				{job?.hourly_rate && (
					<p className="itemTagLinkBlue mb-0">
						${job.hourly_rate}
						{job?.max_hourly_rate !== null && <span> - ${job.max_hourly_rate}</span>} USD Hourly
					</p>
				)}
				<ApplyJobButton job={job} />
				<div
					className="description mt-0"
					dangerouslySetInnerHTML={{
						__html: textFormatter(job.description),
					}}
				/>
				<p className="body-created">
					<QueryBuilderIcon className="icon" />
					{job.created_at !== null ? `Posted ${daysAgo(job.created_at)} days ago` : ''}
				</p>{' '}
			</Card.Body>
		</Modal>
	);
}
