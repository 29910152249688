import axios from 'axios';
import Cookies from 'js-cookie';

const getCountryApi = async () => {
	try {
		const response = await axios.get('https://api.country.is');
		return response.data.country;
	} catch (error) {
		console.error('Error fetching country code:', error);
		return 'USD';
	}
};

export const getCountryCode = async () => {
	const countryCode = Cookies.get('countryCode');
	if (countryCode) {
		return countryCode;
	} else {
		const country = await getCountryApi();
		Cookies.set('countryCode', country, {expires: 1});
		return country;
	}
};

export const COUNTRY_CODE = {
	CHINA: 'CN',
	INDIA: 'IN',
	UNITED_STATES: 'US',
	INDONESIA: 'ID',
	PAKISTAN: 'PK',
	BRAZIL: 'BR',
	NIGERIA: 'NG',
	BANGLADESH: 'BD',
	RUSSIA: 'RU',
	MEXICO: 'MX',
	JAPAN: 'JP',
	ETHIOPIA: 'ET',
	PHILIPPINES: 'PH',
	EGYPT: 'EG',
	VIETNAM: 'VN',
	DEMOCRATIC_REPUBLIC_OF_THE_CONGO: 'CD',
	TURKEY: 'TR',
	IRAN: 'IR',
	GERMANY: 'DE',
	FRANCE: 'FR',
	UNITED_KINGDOM: 'GB',
	THAILAND: 'TH',
	SOUTH_AFRICA: 'ZA',
	TANZANIA: 'TZ',
	ITALY: 'IT',
	MYANMAR: 'MM',
	KENYA: 'KE',
	SOUTH_KOREA: 'KR',
	COLOMBIA: 'CO',
	SPAIN: 'ES',
	AUSTRALIA: 'AU',
	CANADA: 'CA',
};
