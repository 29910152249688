import React, {useEffect, useContext} from 'react';
import {Alert, Divider, List, ListItem, ListItemText} from '@mui/material';
import {NotificationContext} from '../../../utils/UserContext';
import {formatDate2} from '../../../utils/DateFunctions';
import './Notifications.scss';
import QueueAnim from 'rc-queue-anim';
export default function Notifications() {
	const [notifications, setNotifications] = useContext(NotificationContext);

	const textStyles = {
		read: {},
		notRead: {
			backgroundColor: '#d8eefe',
		},
	};

	const dividerStyles = {
		backgroundColor: '#d8eefe00',
		height: '5px',
		border: 'none',
	};

	useEffect(() => {
		document.title = 'Notifications | JobFinder';
	}, []);

	return (
		<div className="dashboardComponent">
			<div className="header">
				<h2>Notifications</h2>
			</div>

			<div className="outerContainer">
				<div className="innerContainer">
					<div className="notifications">
						<List className="list">
							<Divider style={dividerStyles} />
							<QueueAnim delay={200} interval={130}>
								{notifications.length > 0 ? (
									notifications?.map((notif) => (
										<div key={notif?.id}>
											<ListItem sx={notif?.read ? textStyles.read : textStyles.notRead} className="listItem">
												<ListItemText primary={notif?.message} secondary={formatDate2(notif?.created_at)} />
											</ListItem>
											<Divider style={dividerStyles} />
										</div>
									))
								) : (
									<>
										<Alert severity="info" variant="filled">
											You have no notifications yet. Check back later.
										</Alert>
									</>
								)}
							</QueueAnim>
						</List>
					</div>
				</div>
			</div>
		</div>
	);
}
