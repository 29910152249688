import React, {useContext} from 'react';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import {Helmet} from 'react-helmet';
import './SalaryGuide.scss';
import philpng from '../../assets/images/philippines.png';
import {useMediaQuery} from '@mui/material';
import QueueAnim from 'rc-queue-anim';
import {UserContext} from '../../utils/UserContext';

export default function SalaryGuide() {
	const matches = useMediaQuery('(max-width:1220px)');
	const [currentUser, setCurrentUser] = useContext(UserContext);

	return (
		<div className="salary-guide">
			<Helmet>
				<title>Salary Guide | Permworks</title>
				<meta name="description" content="Learn about all things relating to hiring talent from the Philippines. Discover insights, tips, and more on the Permworks Blog." />
			</Helmet>
			<Navbar />
			<div className="salary-container">
				<div className="salary-header">
					<QueueAnim delay={200} interval={200}>
						<h1 key="1">
							<strong>Complete guide</strong> <br /> Salaries in the Philippines
						</h1>
						<h3 key="2">Welcome to our comprehensive salary guide, providing detailed insight into the salary ranges for over 1400+ roles across various industries.</h3>
						<p key="3">
							This meticulously curated guide serves as a reliable reference for understanding salary norms and reflects current economic trends and talent value in the job market.
						</p>
						<p key="4">
							Whether you're an employer planning your budget or an individual assessing your earning potential, our guide offers invaluable insights. Additionally, we've prepared a
							tailored salary guide specifically for the Philippines, a growing market with unique economic dynamics.
						</p>
						<a
							key="5"
							href={currentUser?.profile ? 'https://docs.google.com/spreadsheets/d/1GRCAlOruTh59XDFLDqtSZCiHourZq1H2XrDp-5cFqaU/edit#gid=0' : '/authenticate'}
							target={currentUser?.profile ? '_blank' : '_self'}
							className="linkButtonLight">
							View Salary Guide
						</a>
					</QueueAnim>
				</div>
				{!matches && (
					<QueueAnim delay={1100} interval={200}>
						<div key="1" className="salary-content">
							<img src={philpng} alt="philippines" />
						</div>
					</QueueAnim>
				)}
			</div>

			<Footer />
		</div>
	);
}
