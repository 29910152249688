import {atom} from 'jotai';

export type menuItems =
	| 'inbox'
	| 'jobs'
	| 'closedJobs'
	| 'payments'
	| 'subscriptions'
	| 'billingHistory'
	| 'notifications'
	| 'appliedJobs'
	| 'savedJobs'
	| 'onboarding'
	| 'team'
	| 'timeOff'
	| 'payroll';

function getStoredSelected() {
	return sessionStorage.getItem('selected' as menuItems) || ('inbox' as menuItems);
}

export const teamsAtom = atom<any[]>([]);
export const selectedAtom = atom<menuItems>(getStoredSelected() as menuItems);
export const conversationsAtom = atom<any[]>([]);
export const appliedJobRetriggerAtom = atom<boolean>(false);
