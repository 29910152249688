import React from 'react';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import './EmailVerify.scss';
import {Link, useParams} from 'react-router-dom';
import {ROUTES} from '../../utils/ROUTES';

export default function EmailVerify() {
	const params = useParams();
	const email = params.email;

	return (
		<div className="flex flex-column justify-between min-h-screen email-verified-page">
			<Navbar />
			<div className="outer-email-box">
				<div className="email-box">
					<h1>Email verified</h1>
					<p class="message">
						Thank you <strong>{email}</strong> for verifying your email address. Your account is now activated.
					</p>
					<p class="message">Please press the button below to login.</p>
					<Link className="linkButtonLinkBlue" to={ROUTES.AUTHENTICATE}>
						Login
					</Link>
				</div>
			</div>
			<Footer />
		</div>
	);
}
