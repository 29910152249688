import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {NotificationContext, UserContext} from '../../utils/UserContext';
import {GoogleLogin} from '@react-oauth/google';
import {firebaseGoogleAuthApi, getUser, googleAuthApi, loginApi, loginApi2} from '../../api/authApi';
import {useMediaQuery} from '@mui/material';
import {getNotificationsApi} from '../../api/notificationsApi';
import {Alert, Button, Input, Spin} from 'antd';
import {GoogleCircleFilled, LoadingOutlined, LockOutlined, MailOutlined} from '@ant-design/icons';
import {permColors} from '../../utils/colors';
import Cookies from 'js-cookie';
import {COOKIES} from '../../utils/cookie-names';
import {GoogleAuthProvider, getAuth, signInWithPopup} from 'firebase/auth';
import axios from 'axios';
import {mainUrl} from '../../api/Urls';

export default function Login() {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [googleEmailError, setGoogleEmailError] = useState('');
	const [, setCurrentUser] = useContext(UserContext);
	const [disabledButton, setDisabledButton] = useState(true);
	const smallMatches = useMediaQuery('(max-width: 525px)');
	const [, setNotifications] = useContext(NotificationContext);

	// function to clear all errors
	function clearErrors() {
		setError(false);
		setGoogleEmailError('');
	}

	useEffect(() => {
		if (email && password) {
			setDisabledButton(false);
		} else {
			setDisabledButton(true);
		}
	}, [email, password]);

	async function getNotifications() {
		if (localStorage.getItem('accessToken')) {
			const res = await getNotificationsApi();
			console.log(res);
			setNotifications(res);
			console.log('Successfully fetched notifications');
		}
	}

	const loginRedirect = () => {
		if (Cookies.get(COOKIES.unauthorized_redirect)) {
			navigate(Cookies.get(COOKIES.unauthorized_redirect));
			Cookies.remove(COOKIES.unauthorized_redirect);
		} else {
			navigate('/');
		}
	};

	async function googleLogin(response) {
		try {
			setLoading(true);
			const googleAuthApiResponse = await googleAuthApi(response);
			const {data} = googleAuthApiResponse;
			console.log(data);
			clearErrors();
			localStorage.setItem('token', data.tokens.refresh);
			localStorage.setItem('accessToken', data.tokens.access);
			const userResponse = await getUser();
			setCurrentUser(userResponse.data);
			await getNotifications();
			setLoading(false);
			loginRedirect();
		} catch (error) {
			if (error.response.data) {
				clearErrors();
				setGoogleEmailError(error.response.data.detail);
				setLoading(false);
			}
		}
	}

	const firebaseGoogleAuthApiLocal = async () => {
		const auth = getAuth();
		const provider = new GoogleAuthProvider();

		try {
			const result = await signInWithPopup(auth, provider);
			setLoading(true);
			const user = result.user;
			const idToken = await user.getIdToken();
			const response = await axios.post(mainUrl + '/auth/firebase-google-auth/', {
				idToken: idToken,
			});
			console.log(response.data);
			return response.data;
		} catch (error) {
			console.error('Error during Google sign-in:', error);
			setLoading(false);
		}
	};

	async function firebaseGoogleLogin() {
		try {
			const response = await firebaseGoogleAuthApiLocal();
			console.log(response);
			console.log('refresh: ', response.refresh);
			localStorage.setItem('token', response.refresh);
			localStorage.setItem('accessToken', response.access);
			const userResponse = await getUser();
			setCurrentUser(userResponse.data);
			await getNotifications();
			setLoading(false);
			loginRedirect();
		} catch (error) {
			console.error(error);
			clearErrors();
			setGoogleEmailError(error.response.data.detail);
			setLoading(false);
		}
	}

	async function login(e) {
		e.preventDefault();
		try {
			setLoading(true);
			const {data} = await loginApi(email, password);
			clearErrors();
			localStorage.setItem('token', data.tokens.refresh);
			localStorage.setItem('accessToken', data.tokens.access);
			const userData = await getUser();
			await setCurrentUser(userData.data);
			await getNotifications();
			setLoading(false);
			loginRedirect();
		} catch (error) {
			clearErrors();
			setError(error.response.data.detail);
			setLoading(false);
		}
	}

	async function login2(e) {
		e.preventDefault();
		try {
			setLoading(true);
			const {data} = await loginApi2(email, password);
			clearErrors();
			localStorage.setItem('token', data.refresh);
			localStorage.setItem('accessToken', data.access);
			const userData = await getUser();
			await setCurrentUser(userData.data);
			await getNotifications();
			setLoading(false);
			loginRedirect();
		} catch (error) {
			clearErrors();
			setError(error.response.data.detail);
			setLoading(false);
		}
	}

	return (
		<div>
			<form onSubmit={login}>
				<div className="text">
					<h1>Hello again!</h1>
					<h2 className="text-lg">Sign into your account.</h2>
				</div>
				<div>
					<p className="mb-1 font-semibold text-sm" style={{color: permColors.darkCharcoal}}>
						Email
					</p>
					<Input
						type="email"
						placeholder="Enter your email"
						required
						onChange={(e) => setEmail(e.target.value)}
						className="mb-3"
						allowClear
						prefix={<MailOutlined className="site-form-item-icon mr-1 opacity-20" />}
					/>
					<p className="mb-1 font-semibold text-sm" style={{color: permColors.darkCharcoal}}>
						Password
					</p>
					<Input
						type="password"
						placeholder="Enter your password"
						required
						onChange={(e) => setPassword(e.target.value)}
						className="mb-3"
						allowClear
						prefix={<LockOutlined className="site-form-item-icon mr-1 opacity-20" />}
					/>
				</div>
				{error && <Alert message={error} type="error" showIcon className="mb-3 mt-2" />}
				{googleEmailError && <Alert message={googleEmailError} type="error" showIcon className="mb-3 mt-2" />}

				<Button
					htmlType="submit" // This specifies that the button is a submit button
					className="ButtonLinkBlue min-h-fit w-full m-0"
					disabled={disabledButton || loading}>
					Log in
				</Button>
				{loading && (
					<Alert
						message={<span className="ml-2">Signing in</span>}
						type="info"
						icon={
							<Spin
								indicator={
									<LoadingOutlined
										style={{
											fontSize: 16,
										}}
										spin
									/>
								}
								size="small"
							/>
						}
						banner
						className="mb-3 mt-2 rounded-md"
						style={{color: permColors.deepBlue}}
					/>
				)}
				<div className="divider-container">
					<div className="divider">
						<hr />
						<span className="text-xs">OR</span>
						<hr />
					</div>
				</div>

				{/* <div className="google-button">
					<GoogleLogin
						onSuccess={(credentialResponse) => {
							googleLogin(credentialResponse);
						}}
						onError={() => {
							console.log('Login Failed');
						}}
						size="large"
						theme="filled"
						shape="square"
						width={smallMatches ? '310px' : '1100px'}
						className="google-button"
					/>
				</div> */}

				<Button onClick={firebaseGoogleLogin} icon={<GoogleCircleFilled className="text-2xl py-1" style={{color: '#4285F4'}} />} className="w-full h-fit flex items-center justify-center">
					Continue with Google
				</Button>
			</form>
		</div>
	);
}
