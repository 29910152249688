export const ROUTES = {
	HOME: '/',
	EOR_DASHBOARD: '/eor-dashboard',
	EDIT_PROFILE: '/editprofile',
	AUTHENTICATE: '/authenticate',
	RESET_PASSWORD: '/reset-password',
	RESET_PASSWORD_CONFIRM: '/reset-password-confirm/:uidb64/:token',
	CONFIRM_EMAIL: '/confirmemail',
	CREATE_CONTRACT: '/create-contract/:applicationId',
	CONTRACT_SUCCESS: '/contract-success',
	APPLICANTS: '/applicants/:jobId',
	CONTACT_US: '/contact_us',
	USER_PROFILE: '/userprofile/:userId',
	USER_PROFILE_DYNAMIC: '/userprofile/',
	DASHBOARD: '/dashboard',
	PRIVACY: '/privacy',
	TERMS_AND_CONDITIONS: '/terms_of_service',
	HIRING_IN_THE_PHILIPPINES: '/hiring_in_the_philippines',
	END_TO_END_RECRUITMENT: '/end-to-end-recruitment',
	FAQ: '/faq',
	ABOUT_US: '/aboutus',
	HOW_IT_WORKS: '/how_it_works',
	SALARY_GUIDE: '/salary',
	EMPLOYER_OF_RECORD: '/employer-of-record',
	PRICING: '/pricing',
	POST_JOB: '/postjob',
	BROWSE_JOBS: '/find-jobs',
	JOB_PAGE: '/jobpage/:jobId',
	EDIT_JOB: '/edit-job/:jobId',
	NOT_FOUND: '/not-found',
	BLOG: '/blog',
	BLOG_POST: '/:slug',
	EMAIL_VERIFY: '/email-verify/:email',
};

export const EXTERNAL_ROUTES = {
	FACEBOOK: 'https://www.facebook.com/profile.php?id=100085243007121',
	INSTAGRAM: 'https://www.instagram.com/permworks/',
	LINKEDIN: 'https://www.linkedin.com/company/permworks/',
	TIKTOK: 'https://www.tiktok.com/@permworks',
	PERMHUNT: 'https://permhunt.com/',
	CALENDLY_PERMHUNT: 'https://calendly.com/d/3dy-kf9-3x3/discovery-call',
};
