import React from 'react';
import {Table, Alert, Radio, Space} from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons';
import {hasActiveFreeSubscription} from '../../utils/variables/userVariables';

export default function PricingSelect({setSubscriptionType, subscriptionType, currentUser}: {setSubscriptionType: any; subscriptionType: string; currentUser: any}) {
	const subscriptionOptions = [
		{
			type: 'free',
			title: 'Lite Job Ad',
			pricing: '$0/week',
			features: ['Capped at 20 applicants - once you hit 20 applicants the job is closed', 'Max 1 free posting at a time', 'Max 1 concurrent Lite Job Ad'],
			disabled: hasActiveFreeSubscription(currentUser), // Directly use the function to determine if disabled
		},
		{
			type: 'basic',
			title: 'Basic',
			pricing: '$15/week',
			features: ['Unlimited applicants', 'Priority job ad placement in feed', 'Basic email blast to suitable job applicants in our database'],
		},
		{
			type: 'premium',
			title: 'Premium',
			pricing: '$17/week',
			features: ['Unlimited applicants', 'Higher priority Job ad placement in feed', 'Moderate email blast to suitable job applicants in our database'],
		},
		{
			type: 'premium_plus_2024',
			title: 'Premium Plus',
			pricing: '$20/week',
			features: ['Unlimited applicants', 'Highest priority job ad placement in feed', 'Maximum email blast to all suitable job applicants in our database', 'Priority support'],
		},
	];

	const columns = [
		{
			title: 'Subscription',
			dataIndex: 'title',
			key: 'title',
			// make opacity 0.5 if disabled
			render: (title: string, record: any) => (
				<>
					<p style={{opacity: record.disabled ? 0.5 : 1}} className="text-sm font-semibold">
						{title}
					</p>
					<p style={{opacity: record.disabled ? 0.5 : 1}} className="text-xs text-gray-600">
						{record.pricing}
					</p>
				</>
			),
		},
		{
			title: 'Features',
			dataIndex: 'features',
			key: 'features',
			render: (features: any[], record: any) => (
				<ul className="pl-0">
					{features.map((feature, index) => (
						<p key={index} className="flex gap-2 items-start text-sm my-1" style={{opacity: record.disabled ? 0.5 : 1}}>
							<CheckCircleOutlined style={{color: 'green', marginTop: '4px'}} /> {feature}
						</p>
					))}
				</ul>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: any) => (
				<Radio.Group onChange={(e) => setSubscriptionType(e.target.value)} value={subscriptionType}>
					<Radio value={record.type} disabled={record.disabled}>
						Select
					</Radio>
				</Radio.Group>
			),
		},
	];

	return (
		<div className="pricing-select-container">
			<Alert message="Select a subscription plan to continue" type="info" showIcon className="mb-2" />
			{hasActiveFreeSubscription(currentUser) && (
				<Alert message="You already have an active free subscription and cannot select another free subscription." type="warning" showIcon className="mb-2" />
			)}
			<Table dataSource={subscriptionOptions} columns={columns} pagination={false} className="shadow-sm" scroll={{x: 500}} />
		</div>
	);
}
