export const jobSeekerQuestions = [
	{
		question: `How do I create an account and set up my profile on the jobs board?`,
		answer: (
			<div>
				To create an account and set up your profile on our jobs board, follow these simple steps:
				<ol>
					<li>Visit our website and click on the "Login" button.</li>
					<li>Whilst on the login page, press “Sign up” and fill in the required information, including your name, email address, and a secure password.</li>
					<li>Log in using your credentials and proceed to complete your profile.</li>
					<li>Provide essential details such as your work experience, education, skills, and a well-crafted resume to showcase your qualifications effectively.</li>
				</ol>
				Now you're all set to explore and apply for exciting job opportunities through our platform!
			</div>
		),
	},
	{
		question: 'Can I apply to jobs directly through the jobs board, or will I be redirected to other websites?',
		answer: `Yes, you can apply to jobs directly through our jobs board! We aim to streamline the job application process, making it convenient for job seekers like you. When you find a job listing that interests you, simply click on the "Apply" button within the listing. You'll be guided through the application steps, and in most cases, you can submit your application right here on our platform without being redirected to external websites unless otherwise directed by the employer.`,
	},
	{
		question: 'Is there a way to receive email notifications when new job openings that match my preferences are posted?',
		answer: 'Absolutely! We understand the importance of staying updated on relevant job opportunities. Our system will then automatically send you email alerts whenever new jobs that match your criteria are posted, ensuring you never miss a potential opportunity.',
	},
	{
		question: 'Do I need to pay any fees to access and apply for jobs on the platform?',
		answer: 'No, accessing and applying for jobs on our platform is entirely free for job seekers.',
	},
	{
		question: 'How do I know if an employer has viewed my application?',
		answer: 'Once an employer views your application, you will be notified via email.',
	},
	{
		question: 'How do I know if a job I applied for no longer is open?',
		answer: 'When a job opportunity is no longer available that you have applied for, you will be notified via email.',
	},
	{
		question: 'What measures are in place to protect my personal information and privacy while using the jobs board?',
		answer: 'Protecting your personal information and ensuring your privacy are of utmost importance to us. Our jobs board employs robust security measures to safeguard all user data. We only share your data with prospective employers when you apply for specific job openings. For a comprehensive overview of our privacy policy and data handling practices, please refer to the "Privacy" section on our website. If you have any specific concerns about your privacy, please feel free to reach out to our support team, and we\'ll be happy to assist you.',
	},
	{
		question: 'How do I get paid?',
		answer: `Once you have found a job and signed the contract, you’ll get paid directly into your nominated bank account as per the pay periods set.`,
	},
	{
		question: 'When do I get paid?',
		answer: 'You get paid depending on the agreed-upon dates or intervals mentioned in your contract which your employer will provide.',
	},
	{
		question: 'What if I don’t get paid?',
		answer: 'If you do not get paid by your employer, please contact us at hello@permworks.com immediately for us to help facilitate payment.',
	},
	{
		question: 'Will there be a job interview?',
		answer: 'Yes, in most cases there will be an interview to be scheduled as a next step before being officially hired.',
	},
];

export const employersQuestions = [
	{
		question: `What sets Permworks apart from other platforms for posting job opportunities?`,
		answer: (
			<div>
				What sets Permworks apart from other platforms for posting job opportunities is our specialized approach to advertising available jobs to the best talent in the Philippines. Our
				platform focuses on connecting employers globally directly with top candidates from the Philippines for direct hires. By leveraging our extensive candidate matching algorithm and
				user-friendly interface, we enable employers to access a diverse pool of skilled professionals and streamline the hiring process, making Permworks the preferred choice for direct
				hiring needs in the Philippines.
			</div>
		),
	},
	{
		question: `Upon clicking on my job posting, where do job seekers get redirected to?`,
		answer: (
			<div>
				Upon clicking on your job posting, job seekers will remain on the Permworks platform, where they can view the complete job description and apply directly to the position through our
				application system which is viewable via your employer dashboard.
			</div>
		),
	},
	{
		question: `Is there any cost associated with posting a job on Permworks?`,
		answer: <div>Posting a job costs $7 USD/week per job ad.</div>,
	},
	{
		question: `Can I expect my job listings to appear in search results?`,
		answer: <div>Yes, your job listings will appear in relevant search results based on job seekers' preferences and qualifications, maximizing visibility to potential candidates.</div>,
	},
	{
		question: `Is there a limit to the number of job openings I can post on the platform?`,
		answer: <div>There is no limit to the number of job openings you can post on Permworks, allowing you to post as many positions as needed to fulfill your recruitment needs.</div>,
	},
	{
		question: `Is there a way to search for and view potential candidates who have applied for my job openings?`,
		answer: <div>Yes, as an employer, you can search and view potential candidates who have applied for your job openings through your employer dashboard.</div>,
	},
	{
		question: `How do I cancel a job posting?`,
		answer: <div>Go to your employer dashboard &gt; My Jobs &gt; Click the options for the job ad “...” &gt; Cancel Subscription.</div>,
	},
	{
		question: `Does your team help facilitate compliance for contractors and employees?`,
		answer: (
			<div>
				Yes, our team is dedicated to facilitating compliance for contractors and employees, ensuring adherence to relevant labor laws and regulations. For a free consultation on how we can
				help, feel free to book in a meeting here: <a href="https://calendly.com/tim-permworks/30min">https://calendly.com/tim-permworks/30min</a>.
			</div>
		),
	},
	{
		question: `Do your team provide recruitment services?`,
		answer: (
			<div>
				We provide complete End-to-End Recruitment for employers who don’t have time to go through the recruiting process themselves. This involves us drafting the job description, posting the
				job advertisement, screening candidates, interviewing candidates, thorough testing and background checks. Once we have 3 finalist candidates, we will then schedule joint interviews
				with Permworks and your team. For a free consultation on how we can help, feel free to book in a meeting here:{' '}
				<a href="https://calendly.com/tim-permworks/30min">https://calendly.com/tim-permworks/30min</a>.
			</div>
		),
	},
];

export const endToEndFaqData = [
	{
		question: 'What roles do you recruit for?',
		answer: 'We recruit for any remote role in the Philippines and are able to cater for 1000+ different roles.',
	},
	{
		question: 'How do I pay candidates?',
		answer: 'Dependant on your engagement (contractor or employee), your payment to your staff member will be different. If you engage as a contractor, you will pay them directly. If you engage as an employee, you will pay through one monthly invoice (Employer of Record Service).',
	},
	{
		question: 'How does the pricing work?',
		answer: 'We charge one flat fee for the recruitment and no ongoing fees at all. We also have no salary markup on the candidate.',
	},
	{
		question: 'What salary range should I budget for a role?',
		answer: 'This is entirely dependant on which role you are recruiting for. For more information of salary ranges, check out our “Complete Salary Guide” page.',
	},
	{
		question: 'How long does it take to fill a role?',
		answer: 'Between 3-30 days dependant on the role seniority and candidate availability. We try to fill roles as soon as we can.',
	},
	{
		question: 'Do you help with the job description?',
		answer: 'We help shape your job description to ensure you capture the best candidates possible.',
	},
	{
		question: 'Are there discounts if I want to hire multiple staff?',
		answer: 'Certainly. Speak to our team about discounted rates.',
	},
];
