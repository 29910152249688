import styled from '@emotion/styled';
import Pagination from '@mui/material/Pagination';
import {permColors} from './colors';

export const CustomPagination = styled(Pagination)`
	.MuiPaginationItem-previousNext {
		border-radius: 5px;
	}
	.MuiPaginationItem-page {
		color: ${permColors.charcoal};
		background-color: ${permColors.backgroundGrey};
		border-radius: 5px;
		font-weight: 500;
	}
	.MuiPaginationItem-page.Mui-selected {
		color: ${permColors.white};
		background-color: ${permColors.grey};
		border-radius: 5px;
	}
	.MuiPaginationItem-page.Mui-selected:hover {
		border-radius: 5px;
	}
	.MuiPaginationItem-page:hover {
		color: ${permColors.charcoal};
		border-radius: 5px;
	}
`;
