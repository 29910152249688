import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {Skeleton} from 'antd';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {permColors} from '../../utils/colors';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewer({fileName}: {fileName: string}) {
	const [numPages, setNumPages] = useState(null);
	const [open, setOpen] = useState(false);

	const onDocumentLoadSuccess = ({numPages}) => {
		setNumPages(numPages);
	};

	const Loading = () => <Skeleton active className="py-20 px-8" />;

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div
				onClick={handleClickOpen}
				className="cursor-pointer max-h-[100vh] overflow-y-auto p-2 fun-border scroll-smooth w-full sticky top-0 hover:opacity-70 transition-opacity duration-300 ease-in-out"
				style={{backgroundColor: permColors.backgroundGrey}}>
				<Document file={fileName} onLoadSuccess={onDocumentLoadSuccess} loading={<Loading />}>
					{Array.from(new Array(numPages), (el, index) => (
						<Page key={`page_${index + 1}`} pageNumber={index + 1} className="mb-2 shadow-sm w-full rounded" />
					))}
				</Document>
			</div>
			<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
				<IconButton
					onClick={handleClose}
					aria-label="close"
					style={{
						position: 'fixed',
						right: 4,
						top: 4,
						color: '#fff', // Adjust the color to match your theme or make it stand out on your PDF background
						zIndex: 1201, // Ensure it is above the dialog content
						// backgroundColor: permColors.backgroundGrey,
					}}>
					<CloseIcon />
				</IconButton>
				<Document file={fileName} onLoadSuccess={onDocumentLoadSuccess} className="p-2">
					{Array.from(new Array(numPages), (el, index) => (
						<Page key={`page_${index + 1}`} pageNumber={index + 1} className="mb-2 shadow-sm w-full rounded" />
					))}
				</Document>
			</Dialog>
		</>
	);
}
