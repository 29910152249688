import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {validatePasswordResetToken} from '../../api/profileApi';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import Loading from '../../components/Loaders/Loading';
import {Helmet} from 'react-helmet';

const ResetPasswordConfirm = () => {
	const [newPassword, setNewPassword] = useState('');
	const [reNewPassword, setReNewPassword] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const {uidb64, token} = useParams();

	useEffect(() => {
		console.log(message);
	}, [message]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		clearErrors();
		if (newPassword !== reNewPassword) {
			setError('Passwords do not match');
		} else if (newPassword.length < 6) {
			setError('Password should be at least 6 characters long.');
		} else {
			setLoading(true);
			const res = await validatePasswordResetToken(uidb64, token, newPassword);
			setLoading(false);
			console.log(res);
			setMessage(res);
		}
	};

	function clearErrors() {
		setMessage('');
		setError('');
	}

	return (
		<div>
			<Helmet>
				<title>Reset Password | Permworks</title>
				<meta name="description" content="Reset your Permworks account password. If you've forgotten your password or having trouble logging in, you can reset it here." />
			</Helmet>
			<Navbar />
			<div className="resetPassword">
				<h1>Reset Password</h1>
				<form onSubmit={handleSubmit}>
					<input placeholder="password" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
					<br />
					<input placeholder="confirm password" type="password" value={reNewPassword} onChange={(e) => setReNewPassword(e.target.value)} required />
					<br />
					{message && <p>{message}</p>}
					{error && <p className="error">{error}</p>}
					<button type="submit">Reset Password</button>
				</form>
			</div>
			<Footer />
			{loading && <Loading />}
		</div>
	);
};

export default ResetPasswordConfirm;
