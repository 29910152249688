import React from 'react';
import {Button} from 'antd';
import {Tag} from 'antd';

export const SUBSCRIPTION_TYPES = {
	free: 'free',
	basic: 'basic',
	premium: 'premium',
	premium_plus: 'premium_plus',
};

export function price_for_plan(record: any): string {
	const subscription_type = record?.subscription_type;

	switch (subscription_type) {
		case SUBSCRIPTION_TYPES.free:
			return 'Free';
		case SUBSCRIPTION_TYPES.basic:
			return '$15 USD/month';
		case SUBSCRIPTION_TYPES.premium:
			return '$17 USD/month';
		case SUBSCRIPTION_TYPES.premium_plus:
			return '$20 USD/month';
		default:
			return 'Unknown';
	}
}

export function subscription_text(record: any): string {
	const subscription_type = record?.subscription_type;

	switch (subscription_type) {
		case SUBSCRIPTION_TYPES.free:
			return 'Free';
		case SUBSCRIPTION_TYPES.basic:
			return 'Basic';
		case SUBSCRIPTION_TYPES.premium:
			return 'Premium';
		case SUBSCRIPTION_TYPES.premium_plus:
			return 'Premium Plus';
		default:
			return 'Unknown';
	}
}

interface Record {
	id: string;
	stripe_subscription: string | null;
	stripe_in_cancellation_state: boolean;
	subscription_type: string;
}

export const renderSubscriptionButton = (record: Record, showCase: any) => {
	if (record.subscription_type === 'free') {
		return null;
	}

	if (record.stripe_subscription !== null) {
		return (
			<Button onClick={() => showCase(record.id, false)} disabled={record.stripe_in_cancellation_state} danger>
				Cancel subscription
			</Button>
		);
	} else {
		return (
			<Button onClick={() => showCase(record.id, true)} type="primary">
				Activate subscription
			</Button>
		);
	}
};

export const renderSubscriptionStatusTag = (record: any) => {
	if (record.subscription_type === 'free') {
		return <Tag color="blue">Free</Tag>;
	}

	return record.stripe_subscription !== null ? <Tag color="green">Active</Tag> : <Tag color="volcano">Inactive</Tag>;
};
