export const COOKIES = {
	apply_job_redirect: 'apply-job-redirect',
	unauthorized_redirect: 'unauthorized-redirect',
	current_location: 'current-location',
	cookie_consent: 'cookie-consent',
};

export const COOKIE_CONSENT_OUTCOME = {
	ACCEPTED: 'accepted',
	REJECTED: 'rejected',
};

export const COOKIE_EXPIRY_TIME = {
	FIFTEEN_MINS: {expires: 0.4 / 24},
	THIRTY_MINS: {expires: 0.5 / 24},
	ONE_HOUR: {expires: 1 / 24},
	ONE_YEAR: {expires: 365},
};
