import React, {useContext, useEffect, useState} from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import {countries} from '../../utils/arrayData/CountryArray';
import {categoryOptions} from '../../utils/arrayData/PortfolioArrays';
import './PostJobForm.scss';
import {FiX} from 'react-icons/fi';
import {postJobApi} from '../../api/jobsApi';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Alert, CircularProgress} from '@mui/material';
import {Helmet} from 'react-helmet';
import {useNavigate} from 'react-router-dom';
import Payments from '../../components/Dashboard/Payments';
import {permColors} from '../../utils/colors';
import {textFormatter} from '../../utils/TextAlterations';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Radio} from 'antd';
import PricingSelect from './PricingSelect';
import {UserContext} from '../../utils/UserContext';
import {isStaffMember} from '../../utils/variables/userVariables';

export default function PostJobForm() {
	const navigate = useNavigate();

	const [jobTitle, setJobTitle] = useState('');
	const [description, setDescription] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [country, setCountry] = useState('');
	const [jobType, setJobType] = useState('');
	const [category, setCategory] = useState('');
	const [engagementType, setEngagementType] = useState('');
	const [monthlySalary, setMonthlySalary] = useState(null);
	const [maxMonthlySalary, setMaxMonthlySalary] = useState(null);
	const [hourlyRate, setHourlyRate] = useState(null);
	const [maxHourlyRate, setMaxHourlyRate] = useState(null);
	const [questions, setQuestions] = useState([]);
	const [coverLetterRequired, setCoverLetterRequired] = useState(true);
	const categories = categoryOptions;
	const countriesList = countries;
	const [currentQuestion, setCurrentQuestion] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [paymentConfirmed, setPaymentConfirmed] = useState(false);
	const [activeStep, setActiveStep] = React.useState(0);
	const [subscriptionType, setSubscriptionType] = useState('');
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [isApplying, setIsApplying] = useState(false);
	const [isStaff, setIsStaff] = useState(false);

	const customFont = {
		fontFamily: 'Plus Jakarta Sans, sans-serif',
	};

	const handleNextStep = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBackStep = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleJobTypeChange = (e) => {
		setJobType(e.target.value);
	};

	const handleEngagementTypeChange = (e) => {
		setEngagementType(e.target.value);
	};

	const addQuestion = () => {
		if (currentQuestion.trim()) {
			setQuestions([...questions, {question: currentQuestion}]);
			setCurrentQuestion('');
		}
	};

	useEffect(() => {
		if (currentUser) {
			if (isStaffMember(currentUser)) {
				setPaymentConfirmed(true);
				setIsStaff(true);
				setSubscriptionType('premium');
			}
		}
	}, [currentUser, paymentConfirmed]);

	const validateForm = () => {
		let missingFields = [];

		if (!jobTitle) missingFields.push(' job title');
		if (!description) missingFields.push(' description');
		if (!companyName) missingFields.push(' company name');
		if (!country) missingFields.push(' country');
		if (!jobType) missingFields.push(' job type');
		if (!category) missingFields.push(' category');
		if (!engagementType) missingFields.push(' engagement type');
		if (!paymentMethod) missingFields.push(' payment method');
		if (!subscriptionType) missingFields.push(' subscription type');

		const isSalaryProvided = monthlySalary !== null && monthlySalary > 0;
		const isHourlyRateProvided = hourlyRate !== null && hourlyRate > 0;

		if (!isSalaryProvided && !isHourlyRateProvided) {
			missingFields.push(' monthly salary or hourly rate');
		}

		if (missingFields.length > 0) {
			setErrorMsg(`Please fill in the following required fields:${missingFields.join(', ')}.`);
			return false;
		} else {
			setErrorMsg('');
			return true;
		}
	};

	const removeQuestion = (index) => {
		const newQuestions = [...questions];
		newQuestions.splice(index, 1);
		setQuestions(newQuestions);
	};

	const handlePublish = async () => {
		if (validateForm()) {
			setIsApplying(true);
			const res = await postJobApi(
				jobTitle,
				description,
				companyName,
				country,
				jobType,
				category,
				engagementType,
				monthlySalary || null,
				maxMonthlySalary || null,
				hourlyRate || null,
				maxHourlyRate || null,
				questions,
				coverLetterRequired,
				subscriptionType
			);
			setIsApplying(false);

			if (res) {
				navigate(`/jobpage/${res.id}`);
			}
		}
	};

	const jobDetails = [
		{label: 'Title', value: jobTitle},
		{label: 'Description', value: <div dangerouslySetInnerHTML={{__html: textFormatter(description)}} />},
		{label: 'Company Name', value: companyName},
		{label: 'Country', value: country},
		{label: 'Category', value: category},
		{label: 'Job Type', value: jobType},
		{label: 'Engagement Type', value: engagementType},
		{
			label: 'Payment Method',
			value: paymentMethod && (paymentMethod === 'hourly' ? 'Hourly' : 'Fixed Price'),
		},
		{
			label: paymentMethod === 'hourly' ? 'Hourly Rate' : 'Monthly Salary',
			value: (hourlyRate || monthlySalary) && (paymentMethod === 'hourly' ? `$${hourlyRate} USD per hour` : `$${monthlySalary} USD per month`),
		},
	];

	const steps = [
		{
			label: 'Job Details',
			description: (
				<>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label className="mb-1 text-sm">Title</Form.Label>
						<Form.Control type="text" placeholder="Virtual assistant" onChange={(e) => setJobTitle(e.target.value)} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label className="mb-1 text-sm">Company name</Form.Label>
						<Form.Control type="email" placeholder="Permworks" onChange={(e) => setCompanyName(e.target.value)} />
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label className="mb-1 text-sm">Company location</Form.Label>
						<Form.Select aria-label="Default select example" value={country} onChange={(e) => setCountry(e.target.value)}>
							<option value="">Country</option>
							{countriesList.map((country, index) => (
								<option key={index} value={country}>
									{country}
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label className="mb-1 text-sm">Category</Form.Label>
						<Form.Select value={category} as="select" onChange={(e) => setCategory(e.target.value)}>
							<option value="">Select Category</option>
							{categories.map((category, index) => (
								<option key={index} value={category}>
									{category}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</>
			),
		},
		{
			label: 'Job Description',
			description: (
				<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
					<Form.Label className="text-sm">Description</Form.Label>
					<CKEditor
						editor={ClassicEditor}
						config={{
							toolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList', 'blockQuote'],
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							setDescription(data);
						}}
						onBlur={(event, editor) => {
							console.log('Blur.', editor);
						}}
						onFocus={(event, editor) => {
							console.log('Focus.', editor);
						}}
					/>
				</Form.Group>
			),
		},
		{
			label: 'Hiring Details',
			description: (
				<>
					<p className="mb-2 mt-3 text-sm">Are you hiring for a full-time, part-time or a one-off team member?</p>
					<Radio.Group onChange={handleJobTypeChange} value={jobType} optionType="button" buttonStyle="solid">
						<Radio value="Full-Time">Full-Time</Radio>
						<Radio value="Part-Time">Part-Time</Radio>
						<Radio value="One-time project">One-time project</Radio>
					</Radio.Group>

					<p className="mb-2 mt-3 text-sm">Will the successful candidate be engaged as a contractor or employee?</p>
					<Radio.Group onChange={handleEngagementTypeChange} value={engagementType} optionType="button" buttonStyle="solid" className="mb-4">
						<Radio value="Employee">Employee</Radio>
						<Radio value="Contractor">Contractor</Radio>
					</Radio.Group>
				</>
			),
		},
		{
			label: 'Salary Offer (USD)',
			description: (
				<div>
					<Form.Select value={paymentMethod} aria-label="Default select example" className="mb-3 customSelect" onChange={(e) => setPaymentMethod(e.target.value)}>
						<option value="">Select Payment Type</option>
						<option value="fixed">Pay a monthly fixed price</option>
						<option value="hourly">Pay by the hour</option>
					</Form.Select>
					{paymentMethod === 'fixed' && (
						<InputGroup className="mb-3">
							<InputGroup.Text id="basic-addon3">Fixed price (USD)</InputGroup.Text>
							<Form.Control type="number" id="basic-url" aria-describedby="basic-addon3" value={monthlySalary} onChange={(e) => setMonthlySalary(e.target.value)} />
						</InputGroup>
					)}
					{paymentMethod === 'hourly' && (
						<InputGroup className="mb-3">
							<InputGroup.Text id="basic-addon3">Hourly rate (USD)</InputGroup.Text>
							<Form.Control type="number" id="basic-url" aria-describedby="basic-addon3" value={hourlyRate} onChange={(e) => setHourlyRate(e.target.value)} />
						</InputGroup>
					)}
				</div>
			),
		},

		{
			label: 'Questions',
			description: (
				<div className="questionsTab">
					<p className="mb-1 mt-2 text-sm">What questions would you like your candidates to answer during the application?</p>

					<InputGroup className="mb-3">
						<Form.Control
							value={currentQuestion}
							onChange={(e) => setCurrentQuestion(e.target.value)}
							placeholder="Question"
							aria-label="Recipient's username"
							aria-describedby="basic-addon2"
						/>
						<Button onClick={addQuestion} variant="outline-secondary" id="button-addon2">
							Add
						</Button>
					</InputGroup>
					<div className="questionsList">
						<ListGroup className="mb-2">
							{questions.map((questionObj, idx) => (
								<ListGroup.Item key={idx} className="d-flex justify-content-between items-center">
									<div style={{width: '80%'}} className="text-sm ">
										{questionObj.question}
									</div>
									<FiX className="icon" onClick={() => removeQuestion(idx)} />
								</ListGroup.Item>
							))}
						</ListGroup>
					</div>
				</div>
			),
		},
		{
			label: 'Review',
			description: (
				<>
					<p className="font-semibold">Review your information here:</p>
					<ListGroup as="ul" className="text-sm">
						{jobDetails.map(({label, value}, index) => (
							<ListGroup.Item
								as="li"
								key={index}
								style={{
									borderRadius: index === 0 ? '5px 5px 0 0' : index === jobDetails.length - 1 ? '0 0 5px 5px' : undefined,
								}}
								// last index bottom margin 3
								className={index === jobDetails.length - 1 ? 'mb-2' : ''}>
								<div className="fw-bold">{label}</div>
								{value}
							</ListGroup.Item>
						))}
						{questions.length > 0 && <p className="mb-1 font-semibold text-sm">Questions</p>}
						{questions.length > 0 && (
							<div className="mb-3">
								{questions.map((questionObj, idx) => (
									<ListGroup.Item as="li" key={idx}>
										<div className="fw-bold">Question {idx + 1}</div>
										{questionObj.question}
									</ListGroup.Item>
								))}
							</div>
						)}
					</ListGroup>
				</>
			),
		},
		{
			label: 'Publish',
			description: (
				<div className="mb-2">
					<p className="font-semibold mb-0" style={{color: permColors.darkCharcoal}}>
						Ready to publish your job
					</p>
					<p style={{color: permColors.charcoal}} className="text-sm">
						Please review your payment method below and click publish to post your job.
					</p>
					{!isStaff ? (
						<Payments setPaymentConfirmed={setPaymentConfirmed} headerVisible={false} noPadding={true} />
					) : (
						<Alert className="mb-2" severity="success">
							You are a staff member, no payment required. By default your job will be selected as a <span className="font-semibold">premium</span> job.
						</Alert>
					)}
					<PricingSelect setSubscriptionType={setSubscriptionType} subscriptionType={subscriptionType} currentUser={currentUser} />
				</div>
			),
		},
	];

	return (
		<div style={{backgroundColor: permColors.backgroundLightCream}}>
			<Helmet>
				<title>Post a Job | Permworks</title>
				<meta name="description" content="Advertise your job openings to a global audience. Reach out to talented individuals around the world." />
			</Helmet>
			<Navbar />
			<div className="postJobForm">
				<h1>Post a job </h1>
			</div>
			<div className="materialJob shadow-none" style={{backgroundColor: permColors.white}}>
				<Box style={{maxWidth: '100%'}}>
					<Stepper activeStep={activeStep} orientation="vertical">
						{steps.map((step, index) => (
							<Step key={step.label} style={{width: '100%'}}>
								<StepLabel
									onClick={() => setActiveStep(index)}
									optional={
										index === steps.length - 1 ? (
											<Typography variant="caption" style={{color: permColors.charcoal}}>
												Last step
											</Typography>
										) : null
									}
									sx={{
										...customFont,
										'& .MuiStepIcon-root': {
											// This targets the step icon
											color: index === activeStep ? permColors.deepBlue : permColors.grey, // Conditionally change color
										},
										'& .MuiStepIcon-completed': {
											// This targets the completed step icon
											color: 'success.main', // Change color for completed steps
										},
										'& .MuiStepIcon-active': {
											// This targets the active step icon
											color: permColors.deepBlue, // Change color for the active step
										},
									}}>
									<p
										style={{
											margin: '0',
											fontWeight: index === activeStep ? '600' : 'normal',
											cursor: 'pointer',
											fontFamily: 'Plus Jakarta Sans, sans-serif',
											color: permColors.darkCharcoal, // This will make the active step bold
										}}>
										{step.label}
									</p>
								</StepLabel>
								<StepContent sx={{width: '100%', margin: '0px', padding: '0px', borderLeft: 'none', borderBottom: 'none'}}>
									<Typography style={{width: '100%', maxWidth: '100%'}} sx={customFont}>
										{step.description}
									</Typography>
									<Box sx={{mb: 2}}>
										<div>
											{index !== 0 && (
												<button className="buttonRed mr-2" disabled={index === 0} onClick={handleBackStep}>
													Back
												</button>
											)}
											{index !== steps.length - 1 && (
												<button className="buttonLinkBlue" onClick={handleNextStep}>
													Continue
												</button>
											)}
											{index === steps.length - 1 && (
												<button className="buttonLinkBlue" onClick={handlePublish} disabled={!paymentConfirmed || isApplying}>
													{isApplying ? <CircularProgress size={18} sx={{color: permColors.white}} /> : 'Publish'}
												</button>
											)}

											{errorMsg && (
												<Alert severity="error" className="mt-2">
													{errorMsg}
												</Alert>
											)}
										</div>
									</Box>
								</StepContent>
							</Step>
						))}
					</Stepper>
				</Box>
			</div>
			<Footer />
		</div>
	);
}
