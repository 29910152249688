import axios from 'axios';
import {mainUrl} from './Urls';
import {logout} from './authApi';
import {AiOutlineConsoleSql} from 'react-icons/ai';

export const getUserApi = async (accessToken) => {
	return axios.get(mainUrl + '/auth/me/', {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

export const refreshTokenApi = async (refreshToken) => {
	return axios.post(mainUrl + '/auth/token/refresh/', {
		refresh: refreshToken,
	});
};

export const createProfileApi = async (firstName, lastName, jobStatus, country, image) => {
	try {
		const formData = new FormData();
		formData.append('first_name', firstName);
		formData.append('last_name', lastName);
		formData.append('user_type', jobStatus);
		formData.append('account_status', true);
		formData.append('country', country);
		if (image) {
			formData.append('profile_image', image);
		}

		const response = await axios.patch(mainUrl + '/auth/updateProfile', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});

		if (response.status >= 400) {
			throw new Error(`Server responded with error code: ${response.status}`);
		}

		return {success: true, data: response.data};
	} catch (error) {
		console.error(error);
		return {success: false, error: error.toString()};
	}
};

export const editProfileApi = async (firstName, lastName, country, freelancerProfileTitle, freelancerProfileDescription) => {
	try {
		const formData = new FormData();
		formData.append('first_name', firstName);
		formData.append('last_name', lastName);
		formData.append('country', country);
		formData.append('freelancer_profile_title', freelancerProfileTitle);
		formData.append('freelancer_profile_description', freelancerProfileDescription);

		const response = await axios.patch(mainUrl + '/auth/updateProfile', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const editProfileImage = async (image) => {
	try {
		const formData = new FormData();
		formData.append('account_status', true);

		if (image) {
			formData.append('profile_image', image);
		}

		const response = await axios.patch(mainUrl + '/auth/updateProfile', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export async function setFCMApi(fcmToken) {
	try {
		const response = await axios.post(
			mainUrl + '/auth/api/save_fcm_token/',
			{
				fcm_token: fcmToken, // the FCM token you received from firebase in the previous step
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // replace with your method of storing/accessing saved JWT
				},
			}
		);
		return response;
	} catch (error) {
		return error;
	}
}

export async function setNewPasswordApi(oldPassword, password) {
	try {
		const response = await axios.post(
			mainUrl + '/auth/change_password/',
			{
				old_password: oldPassword,
				new_password: password,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);
		return true;
	} catch (error) {
		return false;
	}
}

export const requestPasswordResetEmail = async (email) => {
	try {
		const response = await axios.post(
			mainUrl + '/auth/request-reset-email/',
			{
				email,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

export const validatePasswordResetToken = async (uidb64, token, newPassword) => {
	try {
		const response = await axios.patch(`${mainUrl}/auth/password-reset-complete`, {
			uidb64: uidb64,
			token: token,
			password: newPassword,
		});
		return 'Password reset success';
	} catch (error) {
		return 'Invalid link or token';
	}
};

export const setNewPassword = async (uidb64, token, new_password, re_new_password) => {
	try {
		const response = await axios.patch(
			mainUrl + '/auth/request-reset-email',
			{
				uidb64,
				token,
				new_password,
				re_new_password,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

		console.log('Password reset success:', response.data);
	} catch (error) {
		console.error('Error resetting password:', error.response.data);
	}
};

// Work-expereince api ==========================================================
export const getWorkExperienceApi = async () => {
	try {
		const response = await axios.get(mainUrl + '/auth/work-experience/', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return response.data.results;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const deleteWorkExperienceApi = async (id) => {
	try {
		const response = await axios.delete(mainUrl + `/auth/work-experience/${id}/`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const addWorkExperienceApi = async (companyName, positionTitle, startMonth, startYear, endMonth, endYear, country, description) => {
	try {
		const response = await axios.post(
			mainUrl + '/auth/work-experience/',
			{
				company_name: companyName,
				position_title: positionTitle,
				from_month: startMonth,
				from_year: startYear,
				to_month: endMonth,
				to_year: endYear,
				country: country,
				description: description,
				city: 'null',
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const patchWorkExperienceApi = async (id, companyName, positionTitle, startMonth, startYear, endMonth, endYear, country, description) => {
	try {
		const response = await axios.patch(
			mainUrl + `/auth/work-experience/${id}/`,
			{
				company_name: companyName,
				position_title: positionTitle,
				from_month: startMonth,
				from_year: startYear,
				to_month: endMonth,
				to_year: endYear,
				country: country,
				description: description,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

// EDUCATION APIS ==========================================================
export const getEducationApi = async () => {
	try {
		const response = await axios.get(mainUrl + '/auth/education/', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return response.data.results;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const addEducationApi = async (areaOfStudy, educationQualification, schoolName, yearFrom, yearTo, description) => {
	try {
		const response = await axios.post(
			mainUrl + '/auth/education/',
			{
				area_of_study: areaOfStudy,
				education_qualification: educationQualification,
				school_name: schoolName,
				year_from: yearFrom,
				year_to: yearTo,
				description: description,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const patchEducationApi = async (id, areaOfStudy, educationQualification, schoolName, yearFrom, yearTo, description) => {
	try {
		const response = await axios.patch(
			mainUrl + `/auth/education/${id}/`,
			{
				area_of_study: areaOfStudy,
				education_qualification: educationQualification,
				school_name: schoolName,
				year_from: yearFrom,
				year_to: yearTo,
				description: description,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const deleteEducationApi = async (id) => {
	try {
		const response = await axios.delete(mainUrl + `/auth/education/${id}/`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

// PORTFOLIOS APIS ==========================================================

export const getPortfolioApi = async () => {
	try {
		const response = await axios.get(mainUrl + '/auth/portfolio/', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return response.data.results;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const addPortfolioApi = async (title, description, serviceType, completionDate, category) => {
	try {
		const response = await axios.post(
			mainUrl + '/auth/portfolio/',
			{
				title: title,
				description: description,
				service_type: serviceType,
				completion_date: completionDate,
				category: category,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const patchPortfolioApi = async (id, title, description, serviceType, completionDate, category) => {
	try {
		const response = await axios.patch(
			mainUrl + `/auth/portfolio/${id}/`,
			{
				title: title,
				description: description,
				service_type: serviceType,
				completion_date: completionDate,
				category: category,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const deletePortfolioApi = async (id) => {
	try {
		const response = await axios.delete(mainUrl + `/auth/portfolio/${id}/`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

// tags APIS ==========================================================
export const getSkillsApi = async () => {
	try {
		const response = await axios.get(mainUrl + '/auth/tags/', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return response.data.results;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const addSkillsApi = async (name) => {
	try {
		const response = await axios.post(
			mainUrl + '/auth/tags/',
			{
				name: name,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
			}
		);
		return response.data.results;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const deleteSkillsApi = async (id) => {
	try {
		const response = await axios.delete(mainUrl + `/auth/tags/${id}/`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
