import React, {Component} from 'react';

class HubSpotChat extends Component {
	componentDidMount() {
		const script = document.createElement('script');
		script.src = process.env.REACT_APP_HUBSPOT_SRC;
		script.async = true;
		script.defer = true;
		script.id = 'hs-script-loader';
		document.body.appendChild(script);
	}

	render() {
		return <div id="hubspot-chat"></div>;
	}
}

export default HubSpotChat;
