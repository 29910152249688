import React, {useEffect, useState} from 'react';
import {getLikedJobs} from '../../../api/jobsApi';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {Alert, capitalize, useMediaQuery} from '@mui/material';
import {textFormatter} from '../../../utils/TextAlterations';
import LoadingPartial from '../../Loaders/LoadingPartial';
import QueueAnim from 'rc-queue-anim';
import {permColors} from '../../../utils/colors';
import {filterByJobType, filterBySearchTerm} from '../../../utils/filters';
import {Input, Select, Table} from 'antd';

const SavedJobs = () => {
	const [jobs, setJobs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	const [jobTypeFilter, setJobTypeFilter] = useState('all');
	const isMobile = useMediaQuery('(max-width: 930px)');

	async function getLiked() {
		const res = await getLikedJobs();
		console.log(res);
		setJobs(res);
		setLoading(false);
	}

	useEffect(() => {
		getLiked();
	}, []);

	if (loading) {
		return <LoadingPartial />;
	}

	const columns = [
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			render: (text, record) => <Link href={`/jobpage/${record.id}`}>{capitalize(text)}</Link>,
		},
		{
			title: 'Company',
			dataIndex: 'company_name',
			key: 'company_name',
			render: (text) => capitalize(text),
		},
		{
			title: 'Type',
			dataIndex: 'job_type',
			key: 'job_type',
		},
		{
			title: 'Engagement',
			dataIndex: 'engagement_type',
			key: 'engagement_type',
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			render: (text) => <div style={{height: '120px', overflowY: 'auto'}} dangerouslySetInnerHTML={{__html: textFormatter(text)}} />,
		},
	];

	const filteredJobs = jobs.filter(filterBySearchTerm(searchTerm)).filter(filterByJobType(jobTypeFilter));

	const dataSource = filteredJobs.map((job) => ({
		key: job.id,
		title: job.title,
		company_name: job.company_name,
		job_type: job.job_type,
		engagement_type: job.engagement_type,
		description: job.description,
	}));

	return (
		<div className="dashboardComponent">
			<div className="header p-0">
				<h2>Saved Jobs</h2>
			</div>

			<div className="outerContainer">
				<div className="flex flex-wrap my-2 gap-2">
					<Input
						type="text"
						placeholder="Search by title or company name"
						onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
						className=" p-2 min-w-[200px] w-full md:w-80 flex-1 grow-1"
					/>
					<Select id="engagementTypeFilter" value={jobTypeFilter} onChange={(e) => setJobTypeFilter(e)} className="min-w-[200px] w-full md:w-80 flex-1 grow-1">
						<option value="all">Employment Type</option>
						<option value="full-time">Full-Time</option>
						<option value="part-time">Part-Time</option>
					</Select>
				</div>
				<div className="innerContainer">
					<Container fluid className="px-0">
						<QueueAnim delay={200} interval={200}>
							<Table dataSource={dataSource} columns={columns} pagination={false} bordered className="mb-4" scroll={{x: true}} />
						</QueueAnim>
					</Container>

					{filteredJobs.length === 0 && (
						<Alert severity="info" variant="filled">
							No saved jobs
						</Alert>
					)}
				</div>
			</div>
		</div>
	);
};

export default SavedJobs;
