import React, {useEffect, useState} from 'react';
import {AiFillCloseCircle} from 'react-icons/ai';
import {Link} from 'react-router-dom';
import './Popups.scss';
import {sendNewMessageApi} from '../../api/messagingApi';
import {Form, Modal} from 'react-bootstrap';
import {LinearProgress} from '@mui/material';

export default function MessagePopup(props) {
	const {showPopup, message, closePopup, link, linkName, title, textArea, buttonText, disabledButtonText, buttonFunction, user} = props;
	const [textAreaValue, setTextAreaValue] = useState('');
	const [responseMessage, setResponseMessage] = useState('');
	const [disabledButton, setDisabledButton] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleTextAreaChange = (e) => {
		setTextAreaValue(e.target.value);
	};
	if (!showPopup) {
		return null;
	}

	const submit = async () => {
		setLoading(true);
		const res = await sendNewMessageApi(textAreaValue, user);
		if (res === true) {
			setResponseMessage('Message sent');
			setDisabledButton(true);
			setLoading(false);
			setTextAreaValue('');
		}
		console.log(res);
		setLoading(false);
	};

	return (
		<Modal show={showPopup} onHide={closePopup}>
			<Modal.Header closeButton>
				<div className="header">
					<h3>{title}</h3>
				</div>
			</Modal.Header>
			<Modal.Body>
				<p>{message}</p>
				{textArea && (
					<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
						<Form.Label>Type your message below</Form.Label>
						<Form.Control as="textarea" rows={3} onChange={handleTextAreaChange} value={textAreaValue} />
					</Form.Group>
				)}
				{responseMessage && <p className="text-center">{responseMessage}</p>}
				{loading && <LinearProgress sx={{margin: '10px 0'}} />}

				{buttonText && (
					<button className="buttonLinkBlue w-full" onClick={submit} disabled={disabledButton}>
						{disabledButton ? disabledButtonText : buttonText}
					</button>
				)}

				{link && <Link to={link}></Link>}
			</Modal.Body>
		</Modal>
	);
}
