import axios from 'axios';
import {mainUrl} from './Urls';

export const contactUsApi = async (email, name, message) => {
	try {
		const data = {
			email: email,
			name: name,
			message: message,
		};
		await axios.post(mainUrl + '/auth/contact-us/', data);
		return true;
	} catch (error) {
		return false;
	}
};
