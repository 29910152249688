import React, {useState} from 'react';
import {createEmployee, fetchEmployees} from '../../../api/eor-api/teams-api';
import {Input, Button, notification} from 'antd';
import {useAtom} from 'jotai';
import {teamsAtom} from '../../../utils/atoms';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {ArrowBack, BackHand} from '@mui/icons-material';
import {permColors} from '../../../utils/colors';

export default function Onboarding({setMenuToTeam}) {
	const [creatingEmployeer, setCreatingEmployeer] = useState(false);
	const [api, contextHolder] = notification.useNotification();
	const [teams, setTeams] = useAtom(teamsAtom);
	const [openForm, setOpenForm] = useState(false);

	const [newEmployeeDetails, setNewEmployeeDetails] = useState({
		firstName: '',
		lastName: '',
		email: '',
		gender: '',
	});

	const openNotification = (message: string, description: string, type: 'success' | 'error' | 'info' | 'warning' = 'success') => {
		api.open({
			message: message,
			description: description,
			duration: 5,
			type: type,
			style: {
				textAlign: 'left',
			},
		});
	};

	const reset = () => {
		setNewEmployeeDetails({
			firstName: '',
			lastName: '',
			email: '',
			gender: '',
		});
	};

	const getTeams = async () => {
		const response = await fetchEmployees();
		setTeams(response);
	};

	const createE = async (event: any) => {
		event.preventDefault();
		setCreatingEmployeer(true);
		await createEmployee(newEmployeeDetails);
		await getTeams();
		openNotification('Employee Created', 'Employee has been created successfully', 'success');
		setCreatingEmployeer(false);
		reset();
	};

	return (
		<div className="dashboardComponent">
			{contextHolder}

			<div className="header">
				<h2>Onboarding</h2>
			</div>

			<div className="outerContainer">
				<div className="innerContainer pb-2">
					{!openForm ? (
						<div className="flex gap-3 flex-wrap">
							<Card sx={{maxWidth: 345}} className="p-2 shadow-sm">
								<img src="/images/people-landing3.png" className="fit object-contain h-[300px] w-full" alt="people-landing3" />
								<CardContent>
									<Typography gutterBottom variant="h5" component="div" sx={{color: permColors.darkCharcoal}}>
										Onboarding
									</Typography>
									<Typography variant="body2" color={permColors.charcoal}>
										Onboard new employees by creating their profiles.
									</Typography>
								</CardContent>
								<CardActions>
									<Button onClick={() => setOpenForm(true)}>Start onboarding</Button>
								</CardActions>
							</Card>
							<Card sx={{maxWidth: 345}} className="p-2 shadow-sm">
								<CardMedia sx={{height: 300}} image="/images/people.jpeg" title="green iguana" />
								<CardContent>
									<Typography gutterBottom variant="h5" component="div" sx={{color: permColors.darkCharcoal}}>
										Your team
									</Typography>
									<Typography variant="body2" color={permColors.charcoal}>
										View your team members and manage their profiles.
									</Typography>
								</CardContent>
								<CardActions>
									<Button onClick={() => setMenuToTeam('team')}>View your team</Button>
								</CardActions>
							</Card>
						</div>
					) : (
						<form onSubmit={createE} className="flex flex-col gap-3 py-2 text-left max-w-[500px]">
							<div className="flex gap-2 items-center cursor-pointer w-fit card-shadow p-1 px-2 rounded-md hover:opacity-60" onClick={() => setOpenForm(false)}>
								<ArrowBack style={{fontSize: '1.1em'}} />
								<span>Go back</span>
							</div>
							{Object.keys(newEmployeeDetails).map((key) => (
								<div>
									<p className="mb-1 text-sm">{key.charAt(0).toUpperCase() + key.slice(1)}</p>
									<Input
										key={key}
										className=""
										type={key === 'email' ? 'email' : 'text'}
										placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
										onChange={(e) => setNewEmployeeDetails({...newEmployeeDetails, [key]: e.target.value})}
										value={newEmployeeDetails[key]}
										allowClear
									/>
								</div>
							))}
							<Button htmlType="submit" className="" loading={creatingEmployeer} disabled={creatingEmployeer}>
								{creatingEmployeer ? 'Creating Employee' : 'Create Employee'}
							</Button>
						</form>
					)}
				</div>
			</div>
		</div>
	);
}
