import axios from 'axios';
import {mainUrl} from './Urls';
import {ROUTES} from '../utils/ROUTES';
import Cookies from 'js-cookie';
import {COOKIES, COOKIE_EXPIRY_TIME} from '../utils/cookie-names';

const api = axios.create({
	baseURL: mainUrl,
});

api.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response && error.response.status === 401) {
			Cookies.set(COOKIES.unauthorized_redirect, window.location.pathname, COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
			window.location.reload();
		}

		if (
			error.response &&
			error.response.data &&
			error.response.data.detail === 'Authorization header must contain two space-delimited values' &&
			error.response.data.code === 'bad_authorization_header'
		) {
			window.location.href = ROUTES.AUTHENTICATE;
		}

		return Promise.reject(error);
	}
);
export default api;
