import {Button, Form, Input, Drawer, Tabs, Table} from 'antd';
import React, {useState} from 'react';
import {permColors} from '../../../utils/colors';
import {EditFilled} from '@ant-design/icons';
import {Edit, EditOutlined, TimeToLeaveOutlined} from '@mui/icons-material';
import {useAtom} from 'jotai';
import {selectedAtom} from '../../../utils/atoms';

function formatDate(date) {
	const dateObj = new Date(date);
	const month = dateObj.toLocaleString('default', {month: 'long'});
	const day = dateObj.getDate();
	const year = dateObj.getFullYear();
	return `${day} ${month} ${year}`;
}

export default function TeamProfile({modalVisible, closeModal, selectedEmployee, setEmployeeDetails, employeeDetails, handleUpdateEmployee, updatingEmployee}) {
	const menuItemWrapperClass = 'flex flex-col max-w-[600px] p-4 mx-auto ';
	const [edit, setEdit] = useState<boolean>(false);
	const [, setMenuSelection] = useAtom(selectedAtom);

	const columns = [
		{
			title: 'Start Date',
			dataIndex: 'start_date',
			key: 'start_date',
			render: (text) => formatDate(text),
		},
		{
			title: 'End Date',
			dataIndex: 'end_date',
			key: 'end_date',
			render: (text) => formatDate(text),
		},
		{
			title: 'Reason',
			dataIndex: 'reason',
			key: 'reason',
		},
		{
			title: 'Leave Type',
			dataIndex: 'leave_type',
			key: 'leave_type',
		},
	];

	const menuItems = [
		{
			label: 'Leave Requests',
			key: '1',
			children: (
				<div className={menuItemWrapperClass}>
					<Table dataSource={selectedEmployee?.timeoff_set} columns={columns} pagination={false} />
					<Button onClick={() => setMenuSelection('timeOff')} className="self-end mt-2">
						Add Time Off <TimeToLeaveOutlined />
					</Button>
				</div>
			),
		},
		{
			label: 'Update Employee',
			key: '2',
			children: (
				<div className={menuItemWrapperClass + ' flex flex-col'}>
					<Button onClick={() => setEdit(!edit)} className="self-end">
						{edit ? 'Cancel' : <EditOutlined style={{fontSize: '1.3em'}} />}
					</Button>
					<Form onFinish={handleUpdateEmployee} initialValues={selectedEmployee} className="flex flex-col gap-4 text-left">
						{Object.keys(employeeDetails).map((key) => (
							<div key={key}>
								<p className="mb-1 text-sm">{key.charAt(0).toUpperCase() + key.slice(1)}</p>
								<Input
									type={key === 'email' ? 'email' : 'text'}
									placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
									onChange={(e) => setEmployeeDetails({...employeeDetails, [key]: e.target.value})}
									value={employeeDetails[key]}
									allowClear
									disabled={!edit}
									className={!edit ? 'border-white' : ''}
									style={!edit ? {color: permColors.charcoal} : {color: permColors.darkCharcoal}}
								/>
							</div>
						))}
						{edit && (
							<Button type="primary" htmlType="submit" loading={updatingEmployee} disabled={updatingEmployee}>
								{updatingEmployee ? 'Updating Employee' : 'Update Employee'}
							</Button>
						)}
					</Form>
				</div>
			),
		},
	];

	return (
		<Drawer title={selectedEmployee?.first_name + ' ' + selectedEmployee?.last_name} placement="right" onClose={closeModal} open={modalVisible} width="100%">
			{selectedEmployee && (
				<div>
					<Tabs defaultActiveKey="1" centered items={menuItems} />
				</div>
			)}
		</Drawer>
	);
}
