export const entityType = [
	'Branch of foreign company',
	'Company limited by guarantee',
	'Incorporation',
	'General partnership',
	'Joint stock companies',
	'Limited liability company',
	'Limited liability partnership',
	'Limited partnership',
	'Non profit corporation',
	'Private company limited by shares',
	'Public company limited by shares',
	'Private liability company',
	'Public liability company',
	'Sole proprietorship',
	'Sole trader',
	'Trust',
	'Unlimited company',
	'Registered individual',
	'Unregistered individual',
	'Other',
];

export const seniorityLevels = ['Intern', 'Junior', 'intermediate', 'Senior', 'lead', 'principal', 'director', 'head of department', 'vice president', 'senior vice president', 'c level executive'];
