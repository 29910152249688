import {createContext} from 'react';
import axios from 'axios';
import {mainUrl} from '../../api/Urls';

export async function getBlogInfoApi() {
	try {
		const response = await axios.get(`${mainUrl}/auth/blog`);
		console.log(response.data.results);
		return response.data.results;
	} catch (error) {
		console.log(error);
	}
}

export const BlogContext = createContext();
