import axios from 'axios';
import {mainUrl} from './Urls';

export const enquiryApi = async (firstName, lastName, companyName, email, phone, countryCode, country, howCanWeHelp, howManyStaff) => {
	try {
		const response = await axios.post(mainUrl + '/auth/enquire/', {
			first_name: firstName,
			last_name: lastName,
			company_name: companyName,
			email: email,
			phone_number: phone,
			country_code: countryCode,
			country: country,
			how_can_we_help_text: howCanWeHelp,
			how_many_staff_text: howManyStaff,
		});

		return true;
	} catch (error) {
		return false;
	}
};
