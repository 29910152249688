import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import {useState} from 'react';
import './EnquiryForm.scss';
import {Link} from 'react-router-dom';
import {EXTERNAL_ROUTES, ROUTES} from '../../utils/ROUTES';
import {enquiryApi} from '../../api/enquiryApi';
import {CloseButton} from 'react-bootstrap';
import {FaFacebook, FaInstagram, FaLinkedin} from 'react-icons/fa';
import {countries} from '../../utils/arrayData/CountryArray';
import LoadingPartial from '../Loaders/LoadingPartial';

const customStyle = {
	width: '600px',
	maxWidth: '100%',
	margin: '0 auto',
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function EnquiryForm({openGoogle, closeGoogle}) {
	const [firstName, setFirstName] = React.useState('');
	const [lastName, setLastName] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [countryCode, setCountryCode] = useState('');
	const [country, setCountry] = useState('');
	const [howCanWeHelp, setHowCanWeHelp] = useState('');
	const [howManyStaff, setHowManyStaff] = useState('');

	const [submitted, setSubmitted] = useState(false);
	const [loader, setLoader] = useState(false);

	const handleClose = () => {
		closeGoogle();
	};

	const enquiryCall = async (e) => {
		setLoader(true);
		e.preventDefault();
		const res = await enquiryApi(firstName, lastName, companyName, email, phone, countryCode, country, howCanWeHelp, howManyStaff);
		console.log(res);
		if (res) {
			setSubmitted(true);
		}
		setLoader(false);
	};

	if (loader) {
		return (
			<Dialog open={openGoogle} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" fullWidth={true} style={customStyle}>
				<DialogContent className="enquiry-form" sx={{borderRadius: '20px'}}>
					<LoadingPartial />
				</DialogContent>
			</Dialog>
		);
	}

	if (submitted) {
		return (
			<Dialog open={openGoogle} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" fullWidth={true} style={customStyle}>
				<DialogContent className="enquiry-form" sx={{borderRadius: '20px'}}>
					<DialogContentText id="alert-dialog-slide-description">
						<div className="header">
							<h2>Thank you for your enquiry</h2>
							<CloseButton onClick={handleClose} />
						</div>
						<div className="thankyou-text">
							<p>We will be in contact with you shortly. You can expect a response within 1-5 business days.</p>
							<p>
								In the meantime, feel free to check out our <Link to={ROUTES.FAQ}>FAQs</Link> or read our latest <Link to={ROUTES.BLOG}>blog posts</Link>.
							</p>
							<p>Stay connected with us on social media:</p>
							<div className="social-links">
								{/* Replace # with your social media URLs */}
								<a href={EXTERNAL_ROUTES.FACEBOOK} target="_blank">
									<FaFacebook className="enquiry-icon" /> Facebook
								</a>{' '}
								|{' '}
								<a href={EXTERNAL_ROUTES.LINKEDIN} target="_blank">
									<FaLinkedin className="enquiry-icon" /> LinkedIn
								</a>{' '}
								|{' '}
								<a href={EXTERNAL_ROUTES.INSTAGRAM} target="_blank">
									<FaInstagram className="enquiry-icon" /> Instagram
								</a>
							</div>
						</div>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<Dialog open={openGoogle} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" fullWidth={true} style={customStyle}>
			<DialogContent className="enquiry-form" sx={{borderRadius: '20px'}}>
				<DialogContentText id="alert-dialog-slide-description">
					<form onSubmit={enquiryCall}>
						<div className="header">
							<h2>Let us get in contact with you</h2>
							<CloseButton onClick={handleClose} />
						</div>
						<div className="names">
							<input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
							<input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
						</div>
						<input type="text" placeholder="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required />
						<input type="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} required />
						<div className="phone">
							<input type="text" className="code" placeholder="Country Code" value={countryCode} onChange={(e) => setCountryCode(e.target.value)} required />
							<input type="text" className="number" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} required />
						</div>
						<select name="country" id="country" value={country} onChange={(e) => setCountry(e.target.value)} required>
							<option value="" disabled>
								Select country
							</option>
							{countries.map((country, index) => (
								<option key={index} value={country}>
									{country}
								</option>
							))}
						</select>
						<input type="text" placeholder="How can we help?" value={howCanWeHelp} onChange={(e) => setHowCanWeHelp(e.target.value)} required />
						<input type="text" placeholder="How many staff do you need?" value={howManyStaff} onChange={(e) => setHowManyStaff(e.target.value)} required />
						<p>
							We respect your data. By submitting this form, you agree that we will contact you in relation to our products and services, in accordance with our{' '}
							<Link to={ROUTES.PRIVACY} target="_blank">
								privacy policy
							</Link>{' '}
							and Permworks{' '}
							<Link to={ROUTES.TERMS_AND_CONDITIONS} target="_blank">
								Terms of Service
							</Link>{' '}
							.
						</p>
						<button type="submit" className="buttonNavy">
							Submit
						</button>
					</form>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
}
