import React, {useContext, useEffect, useState} from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import './LandingPage.scss';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {AiOutlineHeart, AiFillHeart} from 'react-icons/ai';
import {UserContext} from '../../utils/UserContext';
import {getJobs, getLikedJobs, likeJobApi} from '../../api/jobsApi';
import LoginMessage from '../../components/Popups/LoginMessage';
import {Helmet} from 'react-helmet';
import GoogleModal from '../../components/Popups/GoogleModal';
import QueueAnim from 'rc-queue-anim';
import {JobModal, SelectedJobCard} from './LandingPageCards';
import {ROUTES} from '../../utils/ROUTES';
import {Alert, useMediaQuery} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import {permColors} from '../../utils/colors';
import {COUNTRY_CODE, getCountryCode} from '../../api/locationApi';
import {Input, Segmented, Spin} from 'antd';
import {HeartOutlined, UnorderedListOutlined} from '@ant-design/icons';
import {JobCard} from './JobCard';
import {jobsPerPage} from '../BrowseJobsPage/JobsHome';
import {CustomPagination} from '../../utils/MaterialUiThemes';
import {openNotification} from '../../components/Dashboard/EOR/Team';
import {COOKIES} from '../../utils/cookie-names';
import Cookies from 'js-cookie';
import {userExists} from '../../utils/variables/userVariables';
import {useAtom} from 'jotai';
import {appliedJobRetriggerAtom} from '../../utils/atoms';

export default function LandingPage() {
	const [selected, setSelected] = useState(true);
	const [jobs, setJobs] = useState([]);
	const [likedJobs, setLikedJobs] = useState([]);
	const [selectedJob, setSelectedJob] = useState({});
	const [selectedJob2, setSelectedJob2] = useState({});
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [localLikedJobs, setLocalLikedJobs] = useState([]);
	const [retrigger, setRetrigger] = useState(false);
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState(1);
	const [message, setMessage] = useState('');
	const [popup, setPopup] = useState(false);
	const [search, setSearch] = useState('');
	const [googleModal, setGoogleModal] = useState(false);
	const [totalJobs, setTotalJobs] = useState(0);
	const [totalPages, setTotalPages] = useState(1);

	const [modalShow1, setModalShow1] = React.useState(false);
	const [modalShow2, setModalShow2] = React.useState(false);
	const [jobLoading, setJobLoading] = useState(true);
	const smallMatches = useMediaQuery('(max-width: 1350px)');

	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [snackbarSeverity, setSnackbarSeverity] = React.useState('');
	const [jobApplied] = useAtom(appliedJobRetriggerAtom);

	const [countryCode, setCountryCode] = useState('');
	const onSearch = (value) => {
		const searchParams = new URLSearchParams();
		searchParams.append('search', value);
		navigate(`${ROUTES.BROWSE_JOBS}/?${searchParams.toString()}`);
	};

	const {Search} = Input;

	useEffect(() => {
		if (smallMatches) return;

		if (jobs?.length > 0) {
			setSelectedJob(jobs[0]);
			setModalShow1(true);
		}

		if (likedJobs?.length > 0) {
			setSelectedJob2(likedJobs[0]);
			setModalShow2(true);
		}
	}, [jobs, likedJobs]);

	useEffect(() => {
		setSelectedJob({});
		async function fetchJobs() {
			setJobLoading(true);
			const data = await getJobs(currentPage);
			console.log(data);
			setJobs(data.results);
			setTotalJobs(data.count);
			if (data.results.length > 0) {
				setSelectedJob(data.results[0]);

				const currentLocation = Cookies.get(COOKIES.current_location);
				if (currentLocation && currentLocation !== ROUTES.HOME) {
					console.log('Navigating to:', currentLocation);
					Cookies.remove(COOKIES.current_location);
					navigate(currentLocation);
				}

				if (Cookies.get(COOKIES.apply_job_redirect)) {
					const jobId = Cookies.get(COOKIES.apply_job_redirect);
					const selectedJob = data.results.find((job) => job.id === jobId);
					setSelectedJob(selectedJob);
					Cookies.remove(COOKIES.apply_job_redirect);
				}
			}
			if (data.count === 0) {
				setSelectedJob({});
			}
			setTotalPages(Math.ceil(data.count / jobsPerPage));
			setJobLoading(false);
			window.scrollTo({top: 0, behavior: 'smooth'});
		}

		fetchJobs();
	}, [currentUser, currentPage]);

	async function fetchLikedJobs() {
		if (!currentUser.id) return;

		const data = await getLikedJobs();
		setLikedJobs(data);
		const newLocalLikedJobs = [];

		data.forEach((job) => {
			job?.likes?.forEach((like) => {
				if (like === currentUser.id) {
					newLocalLikedJobs.push({userId: currentUser.id, jobId: job.id});
				}
			});
		});

		setLocalLikedJobs(newLocalLikedJobs);
	}

	useEffect(() => {
		if (currentUser.id) {
			fetchLikedJobs();
		}
	}, [retrigger, currentUser]);

	const fetchCountryCode = async () => {
		const code = await getCountryCode();
		setCountryCode(code);
	};
	const setH1Text = () => {
		if (countryCode === COUNTRY_CODE.PHILIPPINES) {
			return 'Find remote jobs with employers worldwide';
		} else {
			return 'Hire talent from the Philippines direct';
		}
	};

	useEffect(() => {
		fetchCountryCode();
	}, []);

	function selectJob(job) {
		setSelectedJob(job);
		setModalShow1(true);
	}
	function selectJob2(job) {
		setSelectedJob2(job);
		setModalShow2(true);
	}

	function authenticatedButtons(jobSelection) {
		if (currentUser.id === jobSelection.created_by) {
			return;
		} else {
			const isJobLiked = localLikedJobs.find((job) => job.jobId === jobSelection.id && job.userId === currentUser.id);
			return isJobLiked ? (
				<AiFillHeart className="heart fill" onClick={() => heartClicked(jobSelection.id)} />
			) : (
				<AiOutlineHeart className="heart" onClick={() => heartClicked(jobSelection.id)} />
			);
		}
	}
	const handleChange = (value) => {
		setSelected(value === 'List');
	};

	async function likeJob(id) {
		await likeJobApi(id);
		setRetrigger(!retrigger);
	}

	function closePopup() {
		setPopup(false);
	}

	function heartClicked(id) {
		if (!userExists(currentUser)) {
			setMessage('Please login to like a job');
			setGoogleModal(true);
			return;
		}
		const isJobLiked = localLikedJobs.find((job) => job.jobId === id && job.userId === currentUser.id);
		if (isJobLiked) {
			// Unlike the job
			let filtered = localLikedJobs.filter((job) => job.jobId !== id);
			setLocalLikedJobs(filtered, () => {
				console.log('updated localLikedJobs:', localLikedJobs);
			});
			setSelectedJob2({}); // Reset the selectedJob2 state
			setSnackbarUnliked();
		} else {
			let newLikedJob = {
				userId: currentUser.id,
				jobId: id,
			};
			setLocalLikedJobs([...localLikedJobs, newLikedJob], () => {
				console.log('updated localLikedJobs:', localLikedJobs);
			});
			setSnackbarLiked();
		}
		likeJob(id);
		console.log('heart clicked', likedJobs);
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackbarOpen(false);
	};

	const setSnackbarLiked = () => {
		setSnackbarMessage('Job liked');
		setSnackbarSeverity('success');
		setSnackbarOpen(true);
	};

	const setSnackbarUnliked = () => {
		setSnackbarMessage('Job unliked');
		setSnackbarSeverity('info');
		setSnackbarOpen(true);
	};

	return (
		<div className="landingPageContainer">
			<Helmet>
				<title>Permworks</title>
				<meta name="description" content="Explore new job opportunities on Permworks. Start your next career move with us." />
				<meta name="google-site-verification" content="2kKe4Wd2lwP2FcOIKXUuMI7dB-7bSF0tHDWZDzoLpew" />
			</Helmet>
			<Navbar boxShadow="none" backgroundColor={permColors.backgroundLightCream} />
			<div className="landingPage">
				<div className="w-full" style={{backgroundColor: permColors.backgroundLightCream}}>
					<div className="w-fit mx-auto py-10 md:py-12 px-3 text-left" key="r">
						<h1 key="b" className="text-xl md:text-3xl" style={{color: permColors.deepBlue}}>
							{setH1Text()}
						</h1>
						<h2 key="a" className="text-base md:text-lg font-normal my-1">
							Search for a company or role that suits you
						</h2>
						<div key="t" className="searchContainer">
							<Search placeholder="Search job title" onSearch={onSearch} onChange={(e) => setSearch(e.target.value)} enterButton className="mt-2 mb-1" />
						</div>
						<p key="d" className="text-sm" style={{color: permColors.charcoal}}>
							Are you an Employer?{' '}
							<Link to={ROUTES.POST_JOB} state={{from: 'employerLink'}} className="standard-link">
								Post a job{' '}
							</Link>
							and we'll take care of the rest.
						</p>
					</div>
				</div>
				<div key="e" className="secondContainer">
					<div className="title-container" key="z">
						<Segmented
							className="my-3 p-1"
							style={{color: permColors.grey}}
							size="large"
							options={[
								{label: 'Jobs Feed', value: 'List', icon: <UnorderedListOutlined />, onChange: () => setSelected(true)},
								{label: 'Saved Jobs', value: 'Kanban', icon: <HeartOutlined />, onChange: () => setSelected(false)},
							]}
							onChange={handleChange}
						/>
					</div>
					{selected && (
						<div className="feedContainer" key="g">
							{!jobLoading ? (
								<div className="allFeeds" key="l">
									{jobs.map((job) => (
										<JobCard key={job.id} job={job} isSelected={job.id === selectedJob.id} onJobSelect={selectJob} authenticatedButtons={authenticatedButtons} />
									))}
								</div>
							) : (
								<Spin className="mx-auto my-10" size="large" />
							)}

							{selectedJob?.title && !smallMatches && <SelectedJobCard job={selectedJob} onNavigate={navigate} authenticatedButtons={authenticatedButtons} />}
						</div>
					)}

					{selectedJob?.title && smallMatches && (
						<JobModal job={selectedJob} show={modalShow1} onHide={() => setModalShow1(false)} onNavigate={navigate} authenticatedButtons={authenticatedButtons} />
					)}

					{!selected && (
						<div className="feedContainer">
							<div className="allFeeds">
								{likedJobs.length > 0 ? (
									likedJobs.map((job) => (
										<JobCard key={job.id} job={job} isSelected={job.id === selectedJob2.id} onJobSelect={selectJob2} authenticatedButtons={authenticatedButtons} />
									))
								) : (
									<Alert severity="info" className="w-full">
										You have no liked jobs at this time -{' '}
										<a href={ROUTES.BROWSE_JOBS} className="standard-link">
											Browse jobs
										</a>
									</Alert>
								)}
							</div>

							{selectedJob2?.title && !smallMatches && <SelectedJobCard job={selectedJob2} onNavigate={navigate} authenticatedButtons={authenticatedButtons} />}

							{selectedJob2?.title && smallMatches && (
								<JobModal job={selectedJob2} show={modalShow2} onHide={() => setModalShow2(false)} onNavigate={navigate} authenticatedButtons={authenticatedButtons} />
							)}
						</div>
					)}
				</div>
			</div>
			{totalJobs > 20 && selected && (
				<div className="pages mx-auto my-3">
					<CustomPagination disabled={jobLoading} count={totalPages} page={currentPage} onChange={(event, value) => setCurrentPage(value)} />
				</div>
			)}
			<LoginMessage message={message} showPopup={popup} closePopup={closePopup} />
			<GoogleModal
				openGoogle={googleModal}
				dialogTitle="Login to Apply"
				dialogContent="You need to login to like to this job."
				button2="Login"
				closeGoogle={() => setGoogleModal(false)}
				button2function={() => navigate('/authenticate')}
				button1="Cancel"
				button1function={() => setGoogleModal(false)}
			/>
			<Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
				<Alert onClose={handleClose} severity={snackbarSeverity} sx={{width: '100%'}} variant="filled">
					{snackbarMessage}
				</Alert>
			</Snackbar>
			<Footer backgroundColor={permColors.backgroundGrey} />
		</div>
	);
}
