import React, {useEffect, useState} from 'react';
import './Profile.scss';
import 'react-datepicker/dist/react-datepicker.css';
import {createProfileApi} from '../../api/profileApi';
import {Helmet} from 'react-helmet';
import {countries} from '../../utils/arrayData/CountryArray';
import Loading from '../../components/Loaders/Loading';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import {COOKIES} from '../../utils/cookie-names';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../../utils/ROUTES';
import Cookies from 'js-cookie';

export default function CreateProfile() {
	const countryList = countries;
	const [jobSwitcher, setJobSwitcher] = useState(true);
	const [country, setCountry] = useState('Afghanistan');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [jobStatus, setJobStatus] = useState('Job-Seeker');
	const [image, setImage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const navigate = useNavigate();

	function setJobSeeker() {
		setJobSwitcher(!jobSwitcher);
		setJobStatus('Job-Seeker');
	}
	function setEmployer() {
		setJobSwitcher(!jobSwitcher);
		setJobStatus('Employer');
	}

	async function profileEdit() {
		try {
			setLoading(true);
			const result = await createProfileApi(firstName, lastName, jobStatus, country, image);
			setLoading(false);

			if (!result.success) {
				setError(result.error);
				return;
			}

			if (Cookies.get(COOKIES.apply_job_redirect)) {
				navigate(Cookies.get(COOKIES.current_location));
			}

			window.location.reload();
		} catch (error) {
			console.error(error);
			setError(error.toString());
		}
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="createProfile">
			<Navbar />
			<Helmet>
				<title>Create Profile | Permworks</title>
				<meta name="description" content="Create your Permworks profile. Showcase your skills and experience to potential employers." />
			</Helmet>
			<div className="container">
				<div className="profileCard">
					<h1>Create your profile</h1>
					<h3 className="sub-heading">Let's get started</h3>
					<p className="tag-line" style={{fontWeight: '600'}}>
						Create Your Profile, Open Doors to New Opportunities.
					</p>
					<div style={{padding: '5px'}} />
					<form onSubmit={profileEdit}>
						<p className="image-label">Are you a job seeker or employer?</p>
						<div style={{padding: '5px'}} />

						<div className="selector">
							<p className={jobSwitcher ? 'p1 selection' : 'p1'} onClick={setJobSeeker}>
								I am a Job seeker
							</p>
							<p className={!jobSwitcher ? 'p2 selection' : 'p2'} onClick={setEmployer}>
								I am an employer
							</p>
						</div>
						<div style={{padding: '5px'}} />

						<input type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} required />
						<input type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} required />

						<p className="image-label">Add a profile image</p>
						<input type="file" onChange={(e) => setImage(e.target.files[0])} accept="image/png, image/jpeg, image/gif, image/heic, image/heif" />

						<select name="countries" id="countries" placeholder="Select country" onChange={(e) => setCountry(e.target.value)} required>
							<option value="" disabled>
								Select Country
							</option>
							{countryList.map((country, index) => {
								return (
									<option key={index} value={country}>
										{country}
									</option>
								);
							})}
						</select>
						<button type="submit" className="buttonRed">
							Create
						</button>
						{error && <p className="error">Error: {error}</p>}
					</form>
				</div>
			</div>
			<Footer />
		</div>
	);
}
