import {useEffect} from 'react';

export function useDocumentHeight() {
	useEffect(() => {
		const setDocHeight = () => {
			document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);
		};

		setDocHeight();
		window.addEventListener('resize', setDocHeight);

		return () => {
			window.removeEventListener('resize', setDocHeight);
		};
	}, []);
}

export function useDocumentWidth() {
	useEffect(() => {
		const setDocWidth = () => {
			document.documentElement.style.setProperty('--doc-width', `${window.innerWidth}px`);
		};

		setDocWidth();
		window.addEventListener('resize', setDocWidth);

		return () => {
			window.removeEventListener('resize', setDocWidth);
		};
	}, []);
}
