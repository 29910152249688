import React, {useContext, useState} from 'react';
import {UserContext} from '../../utils/UserContext';
import ApplyJobPopup from '../Popups/ApplyJobPopup';
import GoogleModal from '../Popups/GoogleModal';
import {useLocation, useNavigate} from 'react-router-dom';
import {hasCreatedProfile, isJobSeeker, userExists, user_type} from '../../utils/variables/userVariables';
import {OpenInNew} from '@mui/icons-material';
import {ROUTES} from '../../utils/ROUTES';
import Cookies from 'js-cookie';
import {COOKIES, COOKIE_EXPIRY_TIME} from '../../utils/cookie-names';
import {Button} from 'antd';

export default function ApplyJobButton({job, flexDirection = ''}) {
	const [currentUser] = useContext(UserContext);
	const [applyPopUp, setApplyPopUp] = useState(false);
	const [popup, setPopup] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	function openPopup() {
		setPopup(true);
	}

	function closeApplyPopup() {
		setApplyPopUp(false);
	}

	function authenticateLoginAction() {
		Cookies.set(COOKIES.current_location, location.pathname, COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
		Cookies.set(COOKIES.apply_job_redirect, job.id, COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
		navigate(ROUTES.AUTHENTICATE);
	}

	async function applyJob() {
		if (!userExists(currentUser)) {
			openPopup();
			return;
		}

		if (!hasCreatedProfile(currentUser)) {
			Cookies.set(COOKIES.apply_job_redirect, job.id, COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
			Cookies.set(COOKIES.current_location, location.pathname, COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
			navigate(ROUTES.EDIT_PROFILE);
			return;
		}
		if (job?.applicants.includes(currentUser.id)) {
			openPopup();
			return;
		}
		setApplyPopUp(true);
	}

	function authenticatedButtons() {
		if (currentUser.id === job?.created_by) {
			return (
				<div className="">
					<div className={`flex flex-wrap gap-1 ${flexDirection}`}>
						{!job?.completed && (
							<Button className=" flex-1 grow" onClick={() => navigate(`/edit-job/${job.id}`)}>
								Edit
							</Button>
						)}
						<Button className=" flex-1 grow" onClick={() => navigate(`/applicants/${job.id}`)}>
							View all applications
						</Button>
					</div>
				</div>
			);
		} else if (isJobSeeker(currentUser)) {
			const hasApplied = job?.applicants.includes(currentUser.id);
			if (!hasApplied) {
				return (
					<div className="controller">
						<div className="user">
							{job?.is_indeed_job !== true ? (
								<Button className="ButtonLinkBlue min-h-fit" onClick={applyJob}>
									Apply
								</Button>
							) : (
								<a href={job?.indeed_job_url} target="_blank" className="linkButtonLinkBlue min-h-fit flex items-center gap-1 w-fit" rel="noreferrer">
									Apply <OpenInNew style={{fontSize: '1.2em'}} />
								</a>
							)}
						</div>
					</div>
				);
			} else {
				return (
					<div className="controller">
						<div className="user">
							<Button className="ButtonLinkBlue" disabled>
								Applied
							</Button>
						</div>
					</div>
				);
			}
		}
		if (!currentUser?.profile) {
			return (
				<div className="controller">
					<div className="user">
						<Button className="ButtonLinkBlue min-h-fit" onClick={applyJob}>
							Apply
						</Button>
					</div>
				</div>
			);
		}
	}

	return (
		<>
			{authenticatedButtons()}
			<ApplyJobPopup
				user={currentUser}
				job={job}
				showPopup={applyPopUp}
				closePopup={closeApplyPopup}
				title={'Apply for this job'}
				textArea={true}
				buttonText={'Apply'}
				disabledButtonText={'Sent'}
			/>
			<GoogleModal
				openGoogle={popup}
				dialogTitle="Login to Apply"
				dialogContent="You need to login to apply for this job"
				button1="Login"
				closeGoogle={() => setPopup(false)}
				button1function={authenticateLoginAction}
			/>{' '}
		</>
	);
}
