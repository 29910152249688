import {collection, getDocs} from 'firebase/firestore';
import {db} from '../firebase';
import api from './interceptor';
import {mainUrl} from './Urls';
import axios from 'axios';

export async function getAllBlogs() {
	const querySnapshot = await getDocs(collection(db, 'blogs'));
	const blogs = querySnapshot.docs.map((doc) => doc.data());
	return blogs;
}
