import React, {useEffect, useState} from 'react';
import {AiFillCloseCircle} from 'react-icons/ai';
import {Link} from 'react-router-dom';
import './Popups.scss';
export default function LoginMessage(props) {
	const {showPopup, message, closePopup, link, linkName, title} = props;

	if (!showPopup) {
		return null;
	}

	const handleOutsideClick = (e) => {
		if (!e.target.closest('.popupLoginMessage')) {
			closePopup();
		}
	};

	return (
		<div className="backgroundPop" onClick={handleOutsideClick}>
			<div className="popupLoginMessage">
				<div className="header">
					<h3>{title}</h3>
					<AiFillCloseCircle className="closeButton" onClick={closePopup} />
				</div>

				<p>{message}</p>
				{link && <Link to={link}></Link>}
			</div>
		</div>
	);
}
