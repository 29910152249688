import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import {Form, Input, Button, Spin, Alert, Card} from 'antd';
import Footer from '../../components/Footer/Footer';
import Navbar2 from '../../components/Navbars/Navbar2';
import {contactUsApi} from '../../api/contactUsApi';
import './ContactUs.scss';
import {permColors} from '../../utils/colors';

export default function ContactUs() {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const contactUs = async (values) => {
		setLoading(true);
		const {email, name, message} = values;
		const res = await contactUsApi(email, name, message);
		setLoading(false);
		if (res === true) {
			setSubmitted(true);
			form.resetFields();
		}
		console.log(res);
	};

	return (
		<div className="height-setter">
			<Helmet>
				<title>Contact Us | Permworks</title>
				<meta name="description" content="Get in touch with us. We'd love to hear from you. If you have any questions, feedback or suggestions, please let us know." />
			</Helmet>
			<Navbar2 />
			<div className="contact-us">
				<Card className="w-[500px] max-w-full mx-auto my-4 shadow-sm">
					<h1 className="mb-3">Contact Form</h1>

					<Form form={form} onFinish={contactUs}>
						<Form.Item name="email" rules={[{required: true, message: 'Please input your email address', type: 'email'}]}>
							<Input placeholder="Email Address*" />
						</Form.Item>
						<Form.Item name="name" rules={[{required: true, message: 'Please input your name'}]}>
							<Input placeholder="Your Name*" />
						</Form.Item>
						<Form.Item name="message" rules={[{required: true, message: 'Please input your message'}]}>
							<Input.TextArea placeholder="Your Message*" />
						</Form.Item>
						{loading ? (
							<div className="my-3">
								<Spin />
							</div>
						) : submitted ? (
							<Alert
								message="Thank you for contacting us, we will be in touch with you ASAP. If you'd like to contact us directly via email, ping us at hello@permworks.com."
								type="success"
								showIcon
								className="my-3"
							/>
						) : null}
						<Form.Item>
							<Button type="primary" htmlType="submit" disabled={submitted || loading} className="">
								{submitted ? 'Submitted' : 'Send'}
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</div>
			<Footer backgroundColor={permColors.backgroundGrey} />
		</div>
	);
}
