import {useEffect, useState} from 'react';
import {fetchEmployees, updateEmployeer} from '../../../api/eor-api/teams-api';
import {Table, Button, notification} from 'antd';
import {useAtom} from 'jotai';
import {teamsAtom} from '../../../utils/atoms';
import {SyncOutlined} from '@ant-design/icons';
import TeamProfile from './TeamProfile';

export const antDapi = notification;

export const openNotification = (message: string, description: any, type: 'success' | 'error' | 'info' | 'warning' = 'success', close: boolean = true, duration: number = 5) => {
	antDapi.open({
		message: message,
		description: description,
		duration: duration,
		type: type,
		style: {
			textAlign: 'left',
		},
	});
};

export default function Eor() {
	const [teams, setTeams] = useAtom(teamsAtom);
	const [fetchingTeams, setFetchingTeams] = useState(false);
	const [updatingEmployee, setUpdatingEmployee] = useState(false);
	const [employeeDetails, setEmployeeDetails] = useState({
		firstName: '',
		lastName: '',
		email: '',
		gender: '',
	});

	const [modalVisible, setModalVisible] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [, contextHolder] = notification.useNotification();

	const getTeams = async (isButton: boolean = false) => {
		setFetchingTeams(true);
		const response = await fetchEmployees();
		setTeams(response);
		if (isButton) {
			openNotification('Teams Refreshed', 'Teams have been refreshed successfully', 'success');
		}
		setFetchingTeams(false);
	};

	useEffect(() => {
		if (teams?.length === 0) {
			getTeams();
		}
	}, [teams]);

	const openModal = (employee: any) => {
		setSelectedEmployee(employee);
		setEmployeeDetails({
			firstName: employee.first_name,
			lastName: employee.last_name,
			email: employee.email,
			gender: employee.gender,
		});
		setModalVisible(true);
	};

	const closeModal = () => {
		setSelectedEmployee(null);
		setModalVisible(false);
		setEmployeeDetails({
			firstName: '',
			lastName: '',
			email: '',
			gender: '',
		});
	};

	const handleUpdateEmployee = async (values: any) => {
		setUpdatingEmployee(true);
		await updateEmployeer(selectedEmployee.id, employeeDetails);
		await getTeams();
		setUpdatingEmployee(false);
		closeModal();
		openNotification('Employee Updated', 'Employee has been updated successfully', 'success');
	};

	const columns = [
		{
			title: 'First Name',
			dataIndex: 'first_name',
			key: 'first_name',
		},
		{
			title: 'Last Name',
			dataIndex: 'last_name',
			key: 'last_name',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Gender',
			dataIndex: 'gender',
			key: 'gender',
		},
	];

	return (
		<div className="dashboardComponent">
			{contextHolder}

			<div className="header">
				<h2>Your teams</h2>
			</div>

			<div className="outerContainer">
				<div className="innerContainer">
					<Button className="mb-2" onClick={() => getTeams(true)} disabled={fetchingTeams} loading={fetchingTeams} icon={<SyncOutlined />}>
						{fetchingTeams ? 'Refreshing Teams' : 'Refresh'}
					</Button>

					<div>
						<Table dataSource={teams} columns={columns} rowKey="id" onRow={(record, rowIndex) => ({onClick: () => openModal(record)})} rowClassName="cursor-pointer" />
					</div>
				</div>
			</div>

			<TeamProfile
				modalVisible={modalVisible}
				closeModal={closeModal}
				selectedEmployee={selectedEmployee}
				setEmployeeDetails={setEmployeeDetails}
				employeeDetails={employeeDetails}
				handleUpdateEmployee={handleUpdateEmployee}
				updatingEmployee={updatingEmployee}
			/>
		</div>
	);
}
