import React from 'react';
import './LandingPage.scss';
import {capitalizeFirstLetter} from '../../utils/TextAlterations';
import {daysAgo} from '../../utils/DateFunctions';
import Card from 'react-bootstrap/Card';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import BusinessIcon from '@mui/icons-material/Business';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import {PersonOutline} from '@material-ui/icons';
import {BadgeOutlined, Description, Pin, Place, WorkHistory} from '@mui/icons-material';
import MyLocationIcon from '@mui/icons-material/MyLocation';

interface JobCardProps {
	job: any;
	isSelected: boolean;
	onJobSelect: any;
}

export function JobCard({job, isSelected, onJobSelect}: JobCardProps) {
	return (
		<React.Fragment>
			<Card onClick={() => onJobSelect(job)} className={`cardDesign cardStyle card-shadow ${isSelected ? 'selectedAllCards' : ''}`}>
				<Card.Header className="cardHeaderDesign">
					<span className="iconed-header items-center">
						{!job?.created_by_image ? <WorkOutlineOutlinedIcon className="working-icon" /> : <img src={job?.created_by_image} alt="profile" className="working-icon p-0" />}
						{capitalizeFirstLetter(job.title)}
					</span>
					<span className="itemTagGrey">
						<WorkHistory />
						{job.job_type}
					</span>
				</Card.Header>
				<Card.Body className="card-body-design">
					<p className="body-job">
						<BusinessIcon />
						{capitalizeFirstLetter(job.company_name)}
					</p>
					<p className="body-category">
						<Description />
						Engagement type - {job.engagement_type}
					</p>
					<p className="body-category">
						<Place />
						Remote - {job.country}
					</p>
					{(job?.monthly_salary || job?.hourly_rate) && (
						<p className="body-engagement">
							{job?.monthly_salary && (
								<span>
									${job.monthly_salary}
									{job?.max_monthly_salary !== null && <span> - ${job.max_monthly_salary}</span>} USD Monthly
								</span>
							)}
							{job?.hourly_rate && (
								<span>
									${job.hourly_rate}
									{job?.max_hourly_rate !== null && <span> - ${job.max_hourly_rate}</span>} USD Hourly
								</span>
							)}
						</p>
					)}

					<p className="body-created">
						<QueryBuilderIcon className="icon" />
						{job.created_at !== null ? `Posted ${daysAgo(job.created_at)} days ago` : ''}
					</p>
				</Card.Body>
			</Card>
			<br />
		</React.Fragment>
	);
}
