import Typography from '@mui/material/Typography';
import {List} from 'antd';
import {permColors} from '../../utils/colors';
import {Table} from 'antd';
import {Download} from '@mui/icons-material';
import PdfViewer from './PdfViewer';
import {textFormatter} from '../../utils/TextAlterations';
import {DownloadOutlined} from '@ant-design/icons';

const {Column} = Table;

export default function ApplicantComponent({value, selectedApplicant}) {
	return (
		<div className="p-4">
			{value === 1 && (
				<>
					<Typography variant="subtitle1" gutterBottom>
						<span style={{color: permColors.charcoal}} className="tag-app-cream font-semibold text-sm">
							Work Experiences
						</span>
					</Typography>
					{selectedApplicant.freelancer.work_experience.length > 0 ? (
						<Table dataSource={selectedApplicant.freelancer.work_experience} rowKey="id" pagination={false} scroll={{x: 500}}>
							<Column title="Position" dataIndex="position_title" key="position" />
							<Column title="Company" dataIndex="company_name" key="company" />
							<Column title="From" dataIndex="from" key="from" render={(_, record) => `${record.from_month || ''} ${record.from_year || ''}`} />
							<Column title="To" dataIndex="to" key="to" render={(_, record) => `${record.to_month || ''} ${record.to_year || 'Present'}`} />
						</Table>
					) : (
						<Typography>No work experiences provided.</Typography>
					)}
				</>
			)}
			{value === 2 && (
				<>
					<Typography variant="subtitle1" gutterBottom>
						<span style={{color: permColors.charcoal}} className="tag-app-cream font-semibold text-sm">
							Education
						</span>
					</Typography>
					{selectedApplicant.freelancer.education.length > 0 ? (
						<Table dataSource={selectedApplicant.freelancer.education} rowKey="id" pagination={false} scroll={{x: 500}}>
							<Column title="Degree" dataIndex="education_qualification" key="degree" />
							<Column title="Field of Study" dataIndex="area_of_study" key="field" />
							<Column title="School" dataIndex="school_name" key="school" />
							<Column title="From Year" dataIndex="year_from" key="from_year" />
							<Column title="To Year" dataIndex="year_to" key="to_year" render={(year_to) => (year_to ? year_to : 'Present')} />
						</Table>
					) : (
						<Typography>No education information provided.</Typography>
					)}
				</>
			)}
			{value === 0 && (
				<>
					{selectedApplicant?.answers?.length > 0 && (
						<Typography variant="subtitle1" gutterBottom>
							<span style={{color: permColors.charcoal}} className="tag-app-cream font-semibold text-sm">
								Screening questions
							</span>
						</Typography>
					)}
					{selectedApplicant?.answers?.length > 0 && (
						<List
							dataSource={selectedApplicant?.answers}
							renderItem={(answer, index) => (
								<List.Item key={index}>
									<div className="ms-2 me-auto">
										<div className="fw-bold">{answer.question.question}</div>
										{answer.answer}
									</div>
								</List.Item>
							)}
						/>
					)}
					{selectedApplicant?.attachment && (
						<div className="attachment mt-2">
							{selectedApplicant?.answers?.length > 0 && <hr className="mt-3" />}
							<a href={selectedApplicant.attachment} download target="_blank" rel="noopener noreferrer" className="download-link align-content-center text-sm font-normal">
								<DownloadOutlined className="text-base" /> Download CV
							</a>
							<p> </p>
							<PdfViewer fileName={selectedApplicant.attachment} />
						</div>
					)}
					{selectedApplicant?.cover_letter_or_message && (
						<div>
							<hr />
							<div className="mt-2">
								<strong className="color-darkCharcoal">Cover letter</strong>
							</div>
							<div className="coverLetter mt-1 color-charcoal" dangerouslySetInnerHTML={{__html: textFormatter(selectedApplicant.cover_letter_or_message)}} />
							<hr />
						</div>
					)}
				</>
			)}
		</div>
	);
}
